export function required(value) {
  if (value !== undefined && value !== null && value !== "") {
    if (value.constructor.name === "Array" && !value.length) {
      return "Zorunlu Alan";
    }

    return true;
  }

  return "Zorunlu alan";
}

export function email(value) {
  return (
    (value
      ? /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      : true) || "Geçerli bir e-posta girin"
  );
}

export function maxLength(value, length) {
  return (
    (value ? value.length <= length : true) ||
    `En fazla ${length} karakter yazabilirsiniz.`
  );
}

export function minLength(value, length) {
  return (
    (value ? value.length >= length : true) ||
    `En az ${length} karakter yazmalısınız.`
  );
}

export function exactLength(value, length) {
  return (
    (value ? value.length === length : true) ||
    `Tam ${length} karakter yazmalısınız.`
  );
}

export function iban(value) {
  return (
    (value ? value.replace(/ /g, "").length === 26 : true) ||
    "Tam 26 karakter yazmalısınız."
  );
}

export function max(value, max) {
  return (
    (value !== null && value !== undefined ? value <= max : true) ||
    `En fazla ${max} değerini girebilirsiniz.`
  );
}

export function maxDate(value, max) {
  if (
    value === null ||
    value === undefined ||
    max === null ||
    max === undefined
  ) {
    return true;
  }

  const maxDate = new Date(max);
  if (maxDate.toString() === "Invalid Date") {
    return true;
  }

  const valueDate = new Date(value);
  if (valueDate.toString() === "Invalid Date") {
    return true;
  }

  return (
    valueDate.getTime() <= maxDate.getTime() ||
    `${maxDate.toLocaleDateString()} tarihinden önce olmalıdır`
  );
}

export function maxOrEqualDate(value, max) {
  if (
    value === null ||
    value === undefined ||
    max === null ||
    max === undefined
  ) {
    return true;
  }

  const maxDate = new Date(max);
  if (maxDate.toString() === "Invalid Date") {
    return true;
  }

  const valueDate = new Date(value);
  if (valueDate.toString() === "Invalid Date") {
    return true;
  }

  maxDate.setHours(23);
  maxDate.setMinutes(59);
  maxDate.setSeconds(59);

  return (
    valueDate.getTime() <= maxDate.getTime() ||
    `${maxDate.toLocaleDateString()} tarihinden önce olmalıdır`
  );
}

export function maxMoney(value, max) {
  return (
    (value !== null && value !== undefined
      ? Number(value.toString().replace(".", "").replace(",", "")) <=
        Number(max.toString().replace(".", "").replace(",", ""))
      : true) ||
    `En fazla ${max.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })} değerini girebilirsiniz.`
  );
}

export function min(value, min) {
  return (
    (value !== null && value !== undefined ? value >= min : true) ||
    `En az ${min} değerini girebilirsiniz.`
  );
}

export function minDate(value, max) {
  if (
    value === null ||
    value === undefined ||
    max === null ||
    max === undefined
  ) {
    return true;
  }

  const maxDate = new Date(max);
  if (maxDate.toString() === "Invalid Date") {
    return true;
  }

  const valueDate = new Date(value);
  if (valueDate.toString() === "Invalid Date") {
    return true;
  }

  return (
    valueDate.getTime() >= maxDate.getTime() ||
    `${maxDate.toLocaleDateString()} tarihinden sonra olmalıdır`
  );
}

export function minOrEqualDate(value, max) {
  if (
    value === null ||
    value === undefined ||
    max === null ||
    max === undefined
  ) {
    return true;
  }

  const maxDate = new Date(max);
  if (maxDate.toString() === "Invalid Date") {
    return true;
  }

  const valueDate = new Date(value);
  if (valueDate.toString() === "Invalid Date") {
    return true;
  }

  maxDate.setHours(0);
  maxDate.setMinutes(0);
  maxDate.setSeconds(0);

  return (
    valueDate.getTime() >= maxDate.getTime() ||
    `${maxDate.toLocaleDateString()} tarihinden sonra olmalıdır`
  );
}

export function date(value) {
  try {
    new Date(value);
    return true;
  } catch (e) {
    return "Girilen tarih YYYY-AA-GG biçiminde olmalıdır.";
  }
}

export function numbersOnly(value) {
  return (
    (value ? value.replace(/[0-9]/g, "") === "" : true) ||
    "Sadece rakam girmelisiniz."
  );
}

export function numbersOnlyWithParam(value) {
  return (
    (value ? value.replace(/[0-9+ ]/g, "") === "" : true) ||
    "Sadece rakam girmelisiniz."
  );
}

export default {
  iban,
  date: date,
  email,
  exactLength,
  max,
  maxDate,
  maxOrEqualDate,
  maxLength,
  min,
  minDate,
  minOrEqualDate,
  minLength,
  numbersOnly,
  required,
  maxMoney,
  numbersOnlyWithParam,
};
