<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    clusterId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Kasa",
    },
  },
  computed: {
    ...mapGetters(["cluster"]),
  },
  watch: {
    cluster() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.cluster) {
        this.loadList();
      }
    });
  },
  methods: {
    loadList() {
      const clusterId = this.clusterId || this.cluster.id;
      const params = { cluster_id: clusterId, per_page: 100 };
      this.options = [];

      this.$api
        .query("safes", { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Safes not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
};
</script>
