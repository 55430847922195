export default {
  data() {
    return {
      pageMeta: {
        title: null,
      },
    };
  },
  watch: {
    "pageMeta.title"(v) {
      this.setTitle(v);
    },
  },
  methods: {
    setTitle(title) {
      this.pageMeta.title = title;

      if (title) {
        document.title = title + " | Robosay";
      } else {
        document.title = "Robosay";
      }
    },
  },
  mounted() {
    if (this.pageMeta.title) {
      this.setTitle(this.pageMeta.title);
    }
  },
};
