import Vue from "vue";

import AutocompleteCluster from "./AutocompleteCluster";
import AutocompleteCompany from "./AutocompleteCompany";
import AutocompleteCompanyUser from "./AutocompleteCompanyUser";
import AutocompleteUser from "./AutocompleteUser";
import BankKeywords from "./BankKeywords";
import BottomButtons from "./BottomButtons";
import Datepicker from "./Datepicker";
import File from "./File";
import IsActive from "./IsActive";
import ModalHouseUser from "./ModalHouseUser";
import ModalResident from "./ModalResident";
import Textarea from "./Textarea";
import TelField from "./TelField";
import TextField from "./TextField";
import Timepicker from "./Timepicker";
import Select from "./Select";
import SelectBank from "./SelectBank";
import SelectBankAccount from "./SelectBankAccount";
import SelectBankAccountType from "./SelectBankAccountType";
import SelectBlock from "./SelectBlock";
import SelectBudget from "./SelectBudget";
import SelectCluster from "./SelectCluster";
import SelectCollecting from "./SelectCollecting";
import SelectCompanyProvider from "./SelectCompanyProvider";
import SelectCustomer from "./SelectCustomer";
import SelectCustomerGroup from "./SelectCustomerGroup.vue";
import SelectEnforcement from "./SelectEnforcement";
import SelectExpenseType from "./SelectExpenseType";
import SelectHouse from "./SelectHouse";
import SelectLocation from "./SelectLocation";
import SelectNotificationTemplate from "./SelectNotificationTemplate";
import SelectProduct from "./SelectProduct";
import SelectProject from "./SelectProject";
import SelectProvider from "./SelectProvider";
import SelectResident from "./SelectResident";
import SelectRole from "./SelectRole";
import SelectSafe from "./SelectSafe";
import MoneyField from "./MoneyField";

Vue.component("rs-autocomplete-cluster", AutocompleteCluster);
Vue.component("rs-autocomplete-company", AutocompleteCompany);
Vue.component("rs-autocomplete-company-user", AutocompleteCompanyUser);
Vue.component("rs-autocomplete-user", AutocompleteUser);
Vue.component("rs-select-project", SelectProject);
Vue.component("rs-select-resident", SelectResident);
Vue.component("rs-bank-keywords", BankKeywords);
Vue.component("rs-datepicker", Datepicker);
Vue.component("rs-file", File);
Vue.component("rs-is-active", IsActive);
Vue.component("rs-form-buttons", BottomButtons);
Vue.component("rs-modal-house-user", ModalHouseUser);
Vue.component("rs-modal-resident", ModalResident);
Vue.component("rs-select", Select);
Vue.component("rs-select-bank", SelectBank);
Vue.component("rs-select-bank-account", SelectBankAccount);
Vue.component("rs-select-bank-account-type", SelectBankAccountType);
Vue.component("rs-select-block", SelectBlock);
Vue.component("rs-select-budget", SelectBudget);
Vue.component("rs-select-cluster", SelectCluster);
Vue.component("rs-select-collecting", SelectCollecting);
Vue.component("rs-select-company-provider", SelectCompanyProvider);
Vue.component("rs-select-customer", SelectCustomer);
Vue.component("rs-select-customer-group", SelectCustomerGroup);
Vue.component("rs-select-enforcement", SelectEnforcement);
Vue.component("rs-select-expense-type", SelectExpenseType);
Vue.component("rs-select-house", SelectHouse);
Vue.component("rs-select-location", SelectLocation);
Vue.component("rs-select-notification-template", SelectNotificationTemplate);
Vue.component("rs-select-product", SelectProduct);
Vue.component("rs-select-provider", SelectProvider);
Vue.component("rs-select-role", SelectRole);
Vue.component("rs-select-safe", SelectSafe);
Vue.component("rs-tel-field", TelField);
Vue.component("rs-text-field", TextField);
Vue.component("rs-textarea", Textarea);
Vue.component("rs-timepicker", Timepicker);
Vue.component("rs-money-field", MoneyField);
