// action types
export const UPDATE_BANK_LIST = "updateBankList";
export const UPDATE_BANK_ACCOUNT_TYPE_LIST = "updateBankAccountTypeList";
export const UPDATE_BANK_ACTIVITY_STATUS_LIST = "updateBankActivityStatusList";
export const UPDATE_COLLECTING_TYPE_LIST = "updateCollectingTypeList";

export const UPDATE_PROVIDER_TYPE_LIST = "updateProviderTypeList";
export const UPDATE_BANK_INTEGRATION_TYPE_LIST =
  "updateBankIntegrationTypeList";
export const UPDATE_AUTO_ASSESSMENT_TIMING_TYPE_LIST =
  "updateAutoAssessmentTimingTypeList";
export const UPDATE_USER_COMMERCIAL_TYPE_LIST = "updateUserCommercialTypeList";
export const UPDATE_HOUSE_USER_TYPE_LIST = "updateHouseUserTypeList";

export const UPDATE_NOTE_TYPE_LIST = "updateNoteTypeList";
export const UPDATE_NOTIFICATION_CHANNEL_LIST = "updateNotificationChannelList";
export const UPDATE_INVOICED_TYPE_LIST = "updateInvoicedTypeList";
export const UPDATE_DELAY_CALCULATION_TYPE_LIST =
  "updateDelayCalculationTypeList";
export const UPDATE_CITY_LIST = "updateCityList";
export const UPDATE_COUNTRY_LIST = "updateCountryList";
export const UPDATE_ROLES = "updateRoles";
export const UPDATE_PAYBACK_REASONS_LIST = "updatePaybackReasonsList";
export const UPDATE_FIXTURE_STATUS_LIST = "updateFixtureStatusList";
export const UPDATE_NOTIFICATION_STATUS_LIST = "updateNotificationStatusList";
export const UPDATE_REMINDER_PERIOD_LIST = "updateReminderPeriodList";
export const UPDATE_CARE_TARGET_LIST = "updateCareTargetList";
export const UPDATE_CARE_STATUS_LIST = "updateCareStatusList";
export const UPDATE_CARE_TYPE_LIST = "updateCareTypeList";
export const UPDATE_PAYMENT_PROVIDER_LIST = "updatePaymentProviderList";
export const UPDATE_NOTIFICATION_PROVIDER_LIST =
  "updateNotificationProviderList";
export const UPDATE_BUDGET_DISTRIBUTION_TYPE_LIST =
  "updateBudgetDistributionTypeList";

// mutation types
export const SET_BANK_LIST = "setBankList";
export const SET_BANK_ACCOUNT_TYPE_LIST = "setBankAccountTypeList";
export const SET_BANK_ACTIVITY_STATUS_LIST = "setBankActivityStatusList";
export const SET_COLLECTING_TYPE_LIST = "setCollectingTypeList";
export const SET_PROVIDER_TYPE_LIST = "setProviderTypeList";
export const SET_BANK_INTEGRATION_TYPE_LIST = "setBankIntegrationTypeList";
export const SET_AUTO_ASSESSMENT_TIMING_TYPE_LIST =
  "setAutoAssessmentTimingTypeList";
export const SET_USER_COMMERCIAL_TYPE_LIST = "setUserCommercialTypeList";
export const SET_HOUSE_USER_TYPE_LIST = "setHouseUserTypeList";
export const SET_NOTE_TYPE_LIST = "setNoteTypeList";
export const SET_NOTIFICATION_CHANNEL_LIST = "setNotificationChannelList";
export const SET_INVOICED_TYPE_LIST = "setInvoicedTypeList";
export const SET_DELAY_CALCULATION_TYPE_LIST = "setDelayCalculationTypeList";
export const SET_CITY_LIST = "setCityList";
export const SET_COUNTRY_LIST = "setCountryList";
export const SET_ROLES = "setRoles";
export const SET_PAYBACK_REASONS_LIST = "setPaybackReasonsList";
export const SET_FIXTURE_STATUS_LIST = "setFixtureStatusList";
export const SET_NOTIFICATION_STATUS_LIST = "setNotificationStatusList";
export const SET_REMINDER_PERIOD_LIST = "setReminderPeriodList";
export const SET_CARE_TARGET_LIST = "setCareTargetList";
export const SET_CARE_STATUS_LIST = "setCareStatusList";
export const SET_CARE_TYPE_LIST = "setCareTypeList";
export const SET_PAYMENT_PROVIDER_LIST = "setPaymentProviderList";
export const SET_NOTIFICATION_PROVIDER_LIST = "setNotificationProviderList";
export const SET_BUDGET_DISTRIBUTION_TYPE_LIST =
  "setBudgetDistributionTypeList";

const state = {
  action_type_list: [
    { id: 1, name: "Ekleme" },
    { id: 2, name: "Güncelleme" },
    { id: 4, name: "Silme" },
  ],
  bank_list: [],
  bank_account_type_list: [],
  bank_activity_status_list: [],
  city_list: [],
  collecting_type_list: [],
  country_list: [],
  income_expense_type_list: [],
  provider_type_list: [],
  bank_integration_type_list: [],
  auto_assessment_timing_type_list: [],
  user_commercial_type_list: [],
  house_user_type_list: [],
  note_type_list: [],
  notification_channel_list: [],
  roles: [],
  payback_reasons_list: [],
  fixture_status_list: [],
  notification_status_list: [],
  reminder_period_list: [],
  payment_provider_list: [],
  notification_provider_list: [],
  type_list: [
    { id: "company", name: "Şirket" },
    { id: "cluster", name: "TYA" },
    { id: "block", name: "Blok" },
    { id: "house", name: "Bağımsız Bölüm" },
    { id: "resident", name: "Sakin" },
    { id: "provider", name: "Tedarikçi" },
    { id: "safe", name: "Kasa" },
    { id: "bank_account", name: "Banka Hesabı" },
    { id: "invoice", name: "Fatura" },
    { id: "fixture", name: "Demirbaş" },
    { id: "breakdown", name: "Arıza" },
    { id: "suggestion", name: "Öneri" },
    { id: "complaint", name: "Şikayet" },
  ],
  payment_type_list: [
    { id: 1, name: "Peşin" },
    { id: 2, name: "Avanslı" },
  ],
  debitor_type_list: [
    { id: 1, name: "Kat Malikleri" },
    { id: 2, name: "Oturanlar" },
  ],
  assessment_calculation_type_list: [
    { id: 1, name: "Tüm TYA" },
    { id: 2, name: "Seçili Bloklar" },
  ],
  management_type_list: [
    { id: 1, name: "Yönetim Şirketi" },
    { id: 2, name: "Yönetici" },
  ],
  month_list: [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ],
  invoice_type_list: [
    {
      id: 1,
      name: "Fatura Gelecek",
    },
    {
      id: 2,
      name: "Faturalı",
    },
    {
      id: 3,
      name: "Faturasız",
    },
    {
      id: 4,
      name: "Gider Makbuzlu",
    },
    {
      id: 5,
      name: "Devir",
    },
    {
      id: 6,
      name: "Tedarikçi Avans",
    },
  ],
  delay_calculation_type_list: [
    {
      id: 1,
      name: "Aylık",
    },
    {
      id: 2,
      name: "Günlük",
    },
  ],
  care_target_list: [],
  care_status_list: [],
  care_type_list: [],
  budget_distribution_type_list: [],
  amount_type_list: [
    { id: 1, name: "Aylık" },
    { id: 2, name: "Toplam" },
  ],
};

const getters = {
  actionTypeList(state) {
    return state.action_type_list;
  },
  autoAssessmentTimingTypeList(state) {
    return state.auto_assessment_timing_type_list;
  },
  assessmentCalculationTypeList(state) {
    return state.assessment_calculation_type_list;
  },
  bankList(state) {
    return state.bank_list;
  },
  bankAccountTypeList(state) {
    return state.bank_account_type_list;
  },
  bankActivityStatusList(state) {
    return state.bank_activity_status_list;
  },
  cityList(state) {
    return state.city_list;
  },
  collectingTypeList(state) {
    return state.collecting_type_list;
  },
  countryList(state) {
    return state.country_list;
  },
  debitorTypeList(state) {
    return state.debitor_type_list;
  },
  incomeExpenseTypeList(state) {
    return state.income_expense_type_list;
  },
  managementTypeList(state) {
    return state.management_type_list;
  },
  monthList(state) {
    return state.month_list;
  },
  providerTypeList(state) {
    return state.provider_type_list;
  },
  reminderPeriodList(state) {
    return state.reminder_period_list;
  },
  bankIntegrationTypeList(state) {
    return state.bank_integration_type_list;
  },
  houseUserTypeList(state) {
    return state.house_user_type_list;
  },
  userCommercialTypeList(state) {
    return state.user_commercial_type_list;
  },
  noteTypeList(state) {
    return state.note_type_list;
  },
  notificationChannelList(state) {
    return state.notification_channel_list.map((item) => {
      return {
        id: item.channel,
        name: item.name,
      };
    });
  },
  notificationChannelListIds(state) {
    return state.notification_channel_list.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  },
  notificationProviderList(state) {
    return state.notification_provider_list;
  },
  notificationStatusList(state) {
    return state.notification_status_list;
  },
  invoiceTypeList(state) {
    return state.invoice_type_list;
  },
  invoicedTypeList(state) {
    return state.invoice_type_list;
  },
  paymentTypeList(state) {
    return state.payment_type_list;
  },
  delayCalculationTypeList(state) {
    return state.delay_calculation_type_list;
  },
  roleList(state) {
    return state.roles;
  },
  paybackReasonsList(state) {
    return state.payback_reasons_list;
  },
  paymentProviderList(state) {
    return state.payment_provider_list;
  },
  fixtureStatusList(state) {
    return state.fixture_status_list;
  },
  typeList(state) {
    return state.type_list;
  },
  careTargetList(state) {
    return state.care_target_list;
  },
  careStatusList(state) {
    return state.care_status_list;
  },
  careTypeList(state) {
    return state.care_type_list;
  },
  budgetDistributionTypeList(state) {
    return state.budget_distribution_type_list;
  },
  amountTypeList(state) {
    return state.amount_type_list;
  },
};

const actions = {
  [UPDATE_BANK_LIST](context, payload) {
    context.commit(SET_BANK_LIST, payload);
  },
  [UPDATE_BANK_ACCOUNT_TYPE_LIST](context, payload) {
    context.commit(SET_BANK_ACCOUNT_TYPE_LIST, payload);
  },
  [UPDATE_BANK_ACTIVITY_STATUS_LIST](context, payload) {
    context.commit(SET_BANK_ACTIVITY_STATUS_LIST, payload);
  },
  [UPDATE_CITY_LIST](context, payload) {
    context.commit(SET_CITY_LIST, payload);
  },
  [UPDATE_COLLECTING_TYPE_LIST](context, payload) {
    context.commit(SET_COLLECTING_TYPE_LIST, payload);
  },
  [UPDATE_PROVIDER_TYPE_LIST](context, payload) {
    context.commit(SET_PROVIDER_TYPE_LIST, payload);
  },
  [UPDATE_BANK_INTEGRATION_TYPE_LIST](context, payload) {
    context.commit(SET_BANK_INTEGRATION_TYPE_LIST, payload);
  },
  [UPDATE_AUTO_ASSESSMENT_TIMING_TYPE_LIST](context, payload) {
    context.commit(SET_AUTO_ASSESSMENT_TIMING_TYPE_LIST, payload);
  },
  [UPDATE_HOUSE_USER_TYPE_LIST](context, payload) {
    context.commit(SET_HOUSE_USER_TYPE_LIST, payload);
  },
  [UPDATE_USER_COMMERCIAL_TYPE_LIST](context, payload) {
    context.commit(SET_USER_COMMERCIAL_TYPE_LIST, payload);
  },
  [UPDATE_NOTE_TYPE_LIST](context, payload) {
    context.commit(SET_NOTE_TYPE_LIST, payload);
  },
  [UPDATE_NOTIFICATION_CHANNEL_LIST](context, payload) {
    context.commit(SET_NOTIFICATION_CHANNEL_LIST, payload);
  },
  [UPDATE_NOTIFICATION_PROVIDER_LIST](context, payload) {
    context.commit(SET_NOTIFICATION_PROVIDER_LIST, payload);
  },
  [UPDATE_NOTIFICATION_STATUS_LIST](context, payload) {
    context.commit(SET_NOTIFICATION_STATUS_LIST, payload);
  },
  [UPDATE_INVOICED_TYPE_LIST](context, payload) {
    context.commit(SET_INVOICED_TYPE_LIST, payload);
  },
  [UPDATE_DELAY_CALCULATION_TYPE_LIST](context, payload) {
    context.commit(SET_DELAY_CALCULATION_TYPE_LIST, payload);
  },
  [UPDATE_COUNTRY_LIST](context, payload) {
    context.commit(SET_COUNTRY_LIST, payload);
  },
  [UPDATE_ROLES](context, payload) {
    context.commit(SET_ROLES, payload);
  },
  [UPDATE_PAYBACK_REASONS_LIST](context, payload) {
    context.commit(SET_PAYBACK_REASONS_LIST, payload);
  },
  [UPDATE_FIXTURE_STATUS_LIST](context, payload) {
    context.commit(SET_FIXTURE_STATUS_LIST, payload);
  },
  [UPDATE_REMINDER_PERIOD_LIST](context, payload) {
    context.commit(SET_REMINDER_PERIOD_LIST, payload);
  },
  [UPDATE_CARE_TARGET_LIST](context, payload) {
    context.commit(SET_CARE_TARGET_LIST, payload);
  },
  [UPDATE_CARE_STATUS_LIST](context, payload) {
    context.commit(SET_CARE_STATUS_LIST, payload);
  },
  [UPDATE_CARE_TYPE_LIST](context, payload) {
    context.commit(SET_CARE_TYPE_LIST, payload);
  },
  [UPDATE_PAYMENT_PROVIDER_LIST](context, payload) {
    context.commit(SET_PAYMENT_PROVIDER_LIST, payload);
  },
  [UPDATE_BUDGET_DISTRIBUTION_TYPE_LIST](context, payload) {
    context.commit(SET_BUDGET_DISTRIBUTION_TYPE_LIST, payload);
  },
};

const mutations = {
  [SET_BANK_LIST](state, list) {
    state.bank_list = list;
  },
  [SET_BANK_ACCOUNT_TYPE_LIST](state, list) {
    state.bank_account_type_list = list;
  },
  [SET_BANK_ACTIVITY_STATUS_LIST](state, list) {
    state.bank_activity_status_list = list;
  },
  [SET_CITY_LIST](state, list) {
    state.city_list = list;
  },
  [SET_COLLECTING_TYPE_LIST](state, list) {
    state.collecting_type_list = list;
  },
  [SET_PROVIDER_TYPE_LIST](state, list) {
    state.provider_type_list = list;
  },
  [SET_BANK_INTEGRATION_TYPE_LIST](state, list) {
    state.bank_integration_type_list = list;
  },
  [SET_AUTO_ASSESSMENT_TIMING_TYPE_LIST](state, list) {
    state.auto_assessment_timing_type_list = list;
  },
  [SET_HOUSE_USER_TYPE_LIST](state, list) {
    state.house_user_type_list = list;
  },
  [SET_USER_COMMERCIAL_TYPE_LIST](state, list) {
    state.user_commercial_type_list = list;
  },
  [SET_NOTE_TYPE_LIST](state, list) {
    state.note_type_list = list;
  },
  [SET_NOTIFICATION_CHANNEL_LIST](state, list) {
    state.notification_channel_list = list;
  },
  [SET_NOTIFICATION_PROVIDER_LIST](state, list) {
    state.notification_provider_list = list;
  },
  [SET_NOTIFICATION_STATUS_LIST](state, list) {
    state.notification_status_list = list;
  },
  [SET_INVOICED_TYPE_LIST](state, list) {
    state.note_type_list = list;
  },
  [SET_DELAY_CALCULATION_TYPE_LIST](state, list) {
    state.delay_calculation_type_list = list;
  },
  [SET_COUNTRY_LIST](state, list) {
    state.country_list = list;
  },
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [SET_PAYBACK_REASONS_LIST](state, list) {
    state.payback_reasons_list = list;
  },
  [SET_FIXTURE_STATUS_LIST](state, list) {
    state.fixture_status_list = list;
  },
  [SET_REMINDER_PERIOD_LIST](state, list) {
    state.reminder_period_list = list;
  },
  [SET_CARE_TARGET_LIST](state, list) {
    state.care_target_list = list;
  },
  [SET_CARE_STATUS_LIST](state, list) {
    state.care_status_list = list;
  },
  [SET_CARE_TYPE_LIST](state, list) {
    state.care_type_list = list;
  },
  [SET_PAYMENT_PROVIDER_LIST](state, list) {
    state.payment_provider_list = list;
  },
  [SET_BUDGET_DISTRIBUTION_TYPE_LIST](state, list) {
    state.budget_distribution_type_list = list;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
