<template>
  <VSelect
    label="Proje"
    :items="projects"
    item-text="name"
    item-value="id"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    :clearable="!$attrs.required"
  />
</template>

<script>
import { VSelect } from "vuetify/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    VSelect,
  },
  computed: {
    ...mapGetters(["projects"]),
  },
};
</script>
