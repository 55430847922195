export default {
  props: {
    hideBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSave: {
      type: Boolean,
      required: false,
      default: false,
    },
    sideView: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {},
};
