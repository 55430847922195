import ClusterService from "@/core/services/cluster.service";

// action types
export const UPDATE_BLOCKS = "updateBlocks";
export const UPDATE_CHILD_EXPENSE_TYPES = "updateChildIncomeTypes";
export const UPDATE_CHILD_MAINTENANCE_TYPES = "updateChildMaintenanceTypes";
export const UPDATE_CLUSTER = "updateCluster";
export const UPDATE_CLUSTER_ID = "updateClusterId";
export const UPDATE_CUSTOM_INCOME_TYPES = "updateCustomIncomeTypes";
export const UPDATE_EXPENSE_TYPES = "updateExpenseTypes";
export const UPDATE_INCOME_TYPES = "updateIncomeTypes";
export const UPDATE_POSITIVE_EXPENSE_TYPES = "updatePositiveExpenseTypes";
export const UPDATE_PROJECTS = "updateProjects";
export const UPDATE_LAWYERS = "updateLawyers";
export const UPDATE_FIXTURE_GROUPS = "updateFixtureGroups";
export const UPDATE_PERMISSIONS = "updatePermissionList";
export const UPDATE_MAINTENANCE_TYPES = "updateMaintenanceTypes";
export const RELOAD_CLUSTER = "reloadCluster";

// mutation types
export const SET_BLOCKS = "setBlocks";
export const SET_CHILD_EXPENSE_TYPES = "setChildIncomeTypes";
export const SET_CHILD_MAINTENANCE_TYPES = "setChildMaintenanceTypes";
export const SET_CLUSTER = "setCluster";
export const SET_CLUSTER_ID = "setClusterId";
export const SET_CUSTOM_INCOME_TYPES = "setCustomIncomeTypes";
export const SET_EXPENSE_TYPES = "setExpenseTypes";
export const SET_POSITIVE_EXPENSE_TYPES = "setPositiveExpenses";
export const SET_PROJECTS = "setProjects";
export const SET_INCOME_TYPES = "setIncomeTypes";
export const SET_LAWYERS = "setLawyers";
export const SET_FIXTURE_GROUPS = "setFixtureGroups";
export const SET_MAINTENANCE_TYPES = "setMaintenanceTypes";
export const SET_PERMISSIONS = "setPermissionList";

const state = {
  blocks: [],
  childExpenseTypeList: [],
  childMaintenanceTypeList: [],
  cluster: null,
  clusterId: null,
  customIncomeTypes: [],
  expenseTypeList: [],
  houseTypes: [],
  incomeTypes: [],
  lawyers: [],
  maintenanceTypes: [],
  positiveExpenseTypeList: [],
  projects: [],
  fixtureGroupsList: [],
  permissionList: [],
};

// try {
//   state.cluster = JSON.parse(window.localStorage.getItem("cluster")) || null;
// } catch (e) {
//   // eslint-disable-next-line
//   console.log("cluster could not be loaded");
// }

const getters = {
  blocks(state) {
    return state.blocks;
  },
  childExpenseTypeList(state) {
    return state.childExpenseTypeList;
  },
  childMaintenanceTypeList(state) {
    return state.childMaintenanceTypeList;
  },
  cluster(state) {
    return state.cluster;
  },
  clusterId(state) {
    return parseInt(state.clusterId);
  },
  customIncomeTypeList(state) {
    return state.customIncomeTypes;
  },
  expenseTypeList(state) {
    return state.expenseTypeList;
  },
  fullExpenseTypeList(state) {
    return state.expenseTypeList
      .concat(state.positiveExpenseTypeList)
      .sort((a, b) => {
        if (a.id === b.id) return 0;
        return a.name < b.name ? -1 : 1;
      });
  },
  houseTypes(state) {
    if (!state.cluster) {
      return [];
    }

    return state.cluster.house_types;
  },
  incomeTypeList(state) {
    return state.incomeTypes.filter((item) => item.sign === 1);
  },
  otherExpenseTypeList(state) {
    return state.incomeTypes.filter((item) => item.sign === -1);
  },
  lawyers(state) {
    return state.lawyers;
  },
  maintenanceTypes(state) {
    return state.maintenanceTypes;
  },
  positiveExpenseTypeList(state) {
    return state.positiveExpenseTypeList;
  },
  projects(state) {
    return state.projects;
  },
  fixtureGroupsList(state) {
    return state.fixtureGroupsList;
  },
  permissionList(state) {
    return state.permissionList;
  },
};

const actions = {
  [UPDATE_BLOCKS](context, payload) {
    context.commit(SET_BLOCKS, payload);
  },
  [UPDATE_CHILD_EXPENSE_TYPES](context, payload) {
    context.commit(SET_CHILD_EXPENSE_TYPES, payload);
  },
  [UPDATE_CHILD_MAINTENANCE_TYPES](context, payload) {
    context.commit(SET_CHILD_MAINTENANCE_TYPES, payload);
  },
  [UPDATE_CLUSTER](context, payload) {
    context.commit(SET_CLUSTER, payload);
    if (payload.active_company === null) {
      context.dispatch("updateCompanyId", null);
    } else {
      context.dispatch("updateCompanyId", payload.active_company.company_id);
    }
  },
  [UPDATE_CLUSTER_ID](context, payload) {
    if (payload !== state.clusterId) {
      window.localStorage.setItem("clusterId", payload);
      ClusterService.updateClusterId(payload);
      context.commit(SET_CLUSTER_ID, payload);
    }
  },
  [RELOAD_CLUSTER]() {
    ClusterService.updateClusterId(state.clusterId);
  },
  [UPDATE_CUSTOM_INCOME_TYPES](context, payload) {
    context.commit(SET_CUSTOM_INCOME_TYPES, payload);
  },
  [UPDATE_EXPENSE_TYPES](context, payload) {
    context.commit(SET_EXPENSE_TYPES, payload);
  },
  [UPDATE_LAWYERS](context, payload) {
    context.commit(SET_LAWYERS, payload);
  },
  [UPDATE_MAINTENANCE_TYPES](context, payload) {
    context.commit(SET_MAINTENANCE_TYPES, payload);
  },
  [UPDATE_POSITIVE_EXPENSE_TYPES](context, payload) {
    context.commit(SET_POSITIVE_EXPENSE_TYPES, payload);
  },
  [UPDATE_PROJECTS](context, payload) {
    context.commit(SET_PROJECTS, payload);
  },
  [UPDATE_INCOME_TYPES](context, payload) {
    context.commit(SET_INCOME_TYPES, payload);
  },
  [UPDATE_FIXTURE_GROUPS](context, payload) {
    context.commit(SET_FIXTURE_GROUPS, payload);
  },
  [UPDATE_PERMISSIONS](context, payload) {
    context.commit(SET_PERMISSIONS, payload);
  },
};

const mutations = {
  [SET_BLOCKS](state, blocks) {
    state.blocks = blocks.sort((a, b) => a.name > b.name);
  },
  [SET_CHILD_EXPENSE_TYPES](state, childExpenseTypes) {
    state.childExpenseTypeList = childExpenseTypes;
  },
  [SET_CHILD_MAINTENANCE_TYPES](state, childList) {
    state.childMaintenanceTypeList = childList;
  },
  [SET_CLUSTER](state, cluster) {
    state.cluster = cluster;
  },
  [SET_CLUSTER_ID](state, clusterId) {
    state.clusterId = clusterId;
  },
  [SET_CUSTOM_INCOME_TYPES](state, customIncomeTypes) {
    state.customIncomeTypes = customIncomeTypes;
  },
  [SET_EXPENSE_TYPES](state, expenseTypes) {
    state.expenseTypeList = expenseTypes;
  },
  [SET_LAWYERS](state, lawyers) {
    state.lawyers = lawyers;
  },
  [SET_MAINTENANCE_TYPES](state, maintenanceTypes) {
    state.maintenanceTypes = maintenanceTypes;
  },
  [SET_POSITIVE_EXPENSE_TYPES](state, positiveExpenseTypeList) {
    state.positiveExpenseTypeList = positiveExpenseTypeList;
  },
  [SET_PROJECTS](state, projects) {
    state.projects = projects;
  },
  [SET_INCOME_TYPES](state, incomeTypes) {
    state.incomeTypes = incomeTypes;
  },
  [SET_FIXTURE_GROUPS](state, fixtureGroupsList) {
    state.fixtureGroupsList = fixtureGroupsList;
  },
  [SET_PERMISSIONS](state, list) {
    state.permissionList = list;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
