// Turkey
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  yes: "Evet",
  no: "Hayır",
  active: "Aktif",
  passive: "Pasif",
  success: "Başarılı",
  fail: "Başarısız",
  saved: "Kaydedildi",
  edit: "Düzenle",
  delete: "Sil",
  sum: "Toplam",
  download: "İndir",
  company: {
    type: {
      1: "TYA Yönetim",
      2: "Tedarikçi",
      3: "Şirket Tedarikçisi",
    },
    accounting_type: {
      1: "Varsayılan",
      2: "Bu Yıl",
      3: "Son 3 Ay",
      4: "Son 6 Ay",
      5: "Son 12 Ay",
      6: "Tümü",
    },
  },
  provider: {
    type: {
      1: "Avukat",
      2: "Banka Komisyon",
      3: "Personel",
      4: "Personel SGK",
      5: "Tedarikçi",
      6: "Dağıtım Şirketi",
    },
    bank_workflow_type: {
      1: "Onay Bekle",
      2: "Son Ödeme Tarihine Göre Tahsilat",
    },
    provider_bank_workflow_type: {
      0: "Varsayılan",
      1: "Onay Bekle",
      2: "Bul veya Yeni Fatura Oluştur veya Kapat",
      3: "Otomatik Oluşan Faturayı Düzenle ve Kapat",
    },
  },
  house: {
    type: {
      house: "Konut",
      workplace: "İşyeri",
      member: "Üye",
      management_office: "Yönetim Ofisi",
    },
    old_renter_type: {
      left_cluster: "Toplu Yaşam Alanından Ayrıldı",
      moved_to_another_house: "Başka Bağımsız Bölüme Taşındı",
      lives_in_cluster: "Toplu Yaşam Alanında Yaşıyor",
    },
    new_renter_type: {
      new_user: "Yeni Kişi Oluştur",
      from_cluster: "TYA'dan Kişi Seç",
      empty_house: "Yeni Kiracı Yok",
    },
    user_type: {
      owner: "Ev Sahibi",
      renter: "Kiracı",
      guest: "Misafir",
      1: "Ev Sahibi",
      2: "Kiracı",
      3: "Misafir",
      4: "Eski Ev Sahibi",
      5: "Eski Kiracı",
      6: "Borçlu",
    },
  },
  cluster: {
    tech_worker_authorization: {
      view_only: "Sadece Görüntüleyebilir",
      can_approve_and_comment: "Onaylayabilir ve Yorum Yapabilir",
      all_actions: "Tüm İşlemleri Yapabilir",
    },
    share_meter_rounding: {
      no_rounding: "Küsuratlı",
      round: "Yuvarla",
    },
  },
  headers: {
    notes_and_reminders: "Notlar ve Hatırlatmalar",
    documents: "Belgeler",
    info: "Bilgiler",
    general_info: "Genel Bilgiler",
    clusters_authorized: "Yetkili Olduğu TYA'lar",
    other_settings: "Diğer Ayarlar",
    house_types_s: "BB Tipleri",
    account_activity: "Hesap Hareketleri",
    defer_info: "Gecikme Hesap Bilgileri",
    share_meter_info: "Pay Ölçer Bilgileri",
    share_meter_graph: "Pay Ölçer Grafiği",
    payment_analysis: "Ödeme Analizi",
    notes: "Notlar",
  },
  toasts: {
    item_deleted: "Silindi",
    item_updated: "Güncellendi",
    error_retrieving: "Bilgiler yüklenirken bir sorun oluştu.",
  },
  labels: {
    name: "İsim",
    name_surname: "Adı Soyadı",
    company_name: "Şirket İsmi",
    company_short_name: "Şirket Kısa İsmi",
    company_title: "Şirket Unvanı",
    city: "Şehir",
    district: "İlçe",
    tax_office: "Vergi Dairesi",
    tax_number: "Vergi Numarası",
    authorized_person: "Yetkili Kişi",
    authorized_person_email: "Yetkili E-posta",
    authorized_person_phone: "Yetkili Telefon",
    company_phone: "Şirket Telefonu",
    company_email: "Şirket E-postası",
    address: "Adres",
    company_address: "Şirket Adresi",
    billing_address: "Fatura Adresi",
    address_code: "Adres Kodu",
    logo: "Logo",
    report_logo: "Rapor Logo",
    phone: "Telefon",
    fax: "Faks",
    email: "E-posta",
    house: "Bağımsız Bölüm",
    house_no: "Bağımsız Bölüm No",
    house_kind: "Bağımsız Bölüm Türü",
    house_type: "Bağımsız Bölüm Tipi",
    renter: "Kiracı",
    renter_phone: "Kiracı Cep",
    renter_balance: "Kiracı Bakiye",
    renter_atm_code: "Kiracı ATM Ödeme Kodu",
    owner: "Ev Sahibi",
    owner_atm_code: "Ev Sahibi ATM Ödeme Kodu",
    owner_phone: "Ev Sahibi Cep",
    owner_balance: "Ev Sahibi Bakiye",
    area_gross: "Brüt m²",
    area_net: "Net m²",
    area_share_meter: "Pay Ölçer m²",
    area_garden: "Bahçe m²",
    area_land_share: "Arsa Payı",
    has_periodical_assessments: "Periyodik Borçlandır",
    has_periodical_crediting: "Periyodik Alacaklandır",
    has_multiple_owners: "Hissedarlı Bağımsız Bölüm",
    calculate_share_meter: "Pay Ölçer Hesaplama",
    has_share_meter_assessments: "Pay Ölçer Borçlandırma",
    share_total: "Toplam Hisse",
    cash_payment_notification: "Nakit Ödeme Bildirimleri",
    cash_collection_notification: "Nakit Tahsilat Bildirimleri",
    has_module_lawyer: "Avukatlık Hizmetleri",
    has_module_accounting: "Bütçe Hizmetleri",
    subject: "Konu",
    description: "Açıklama",
    document: "Belge",
    documents: "Belgeler",
    reminder: "Hatırlatıcı",
    reminder_repeating: "Tekrarlı Hatırlatıcı",
    reminder_date: "Hatırlatıcı Tarihi",
    reminder_time: "Hatırlatıcı Saati",
    note_date: "Not Tarihi",
    user_role: "Kullanıcı Rolü",
    is_active: "Aktif",
    status: "Durum",
    sms_company: "SMS Şirketi",
    sms_username: "SMS Kullanıcı Adı",
    sms_password: "SMS Parolası",
    gsm_company_balance: "GSM Şirket Bakiyesi",
    sms_available_balance: "Kullanılabilir SMS Bakiye",
    sms_fetch_date: "Son Kontrol Edilme Tarihi",
    sms_balance: "SMS Bakiye",
    sms_balance_waiting_approval: "Onay Bekleyen Bakiye",
    sms_balance_sent_waiting: "Gönderim Bekleyen Bakiye",
    cluster_name: "TYA Adı",
    cluster_title: "TYA Unvanı",
    receipt_no: "Sistem No",
    receipt_type: "Fiş Tipi",
    receipt_name: "Makbuzda Yazacak Kişi/Kurum",
    created_date: "Kayıt Tarihi",
    house_count: "BB Sayısı",
    new_aggrement_date: "Sözleşme Yenileme Tarihi",
    agreement_price: "Sözleşme Ücreti",
    last_receipt_number: "Son Makbuz No",
    person_risk_parameter: "Kişi Risk Parametresi",
    house_risk_price: "Bağımsız Bölüm Risk Tutarı",
    notification_minimum_balance: "Bildirim İçin Bakiye Alt Sınırı",
    fee_last_payment_day: "Aidat Son Ödeme Günü",
    expired_option_days: "Gecikme Opsiyonu Gün Sayısı",
    share_meter_rounding_type: "Pay Ölçer Yuvarlama",
    bank_workflow_collecting_type: "Banka Entegrasyon/Tahsilat İş Akış Türü",
    bank_workflow_payment_type: "Banka Entegrasyon Fatura Ödeme İş Akışı",
    floor: "Kat",
    block: "Blok",
    block_no: "Blok No",
    block_name: "Blok Adı",
    area: "BB m²",
    fee: "Aidat",
    fee_amount: "Aidat (Gider Avansı) Tutar",
    provider_type: "Tedarikçi Türü",
    income_expense_type: "Gelir/Gider Türü",
    bank: "Banka",
    bank_1: "Banka 1",
    bank_1_iban: "Banka 1 IBAN",
    bank_2: "Banka 2",
    bank_2_iban: "Banka 2 IBAN",
    create_on_new_cluster: "Yeni TYA Tanımlarına Ekle",
    total_purchase: "Toplam Alım",
    total_payment: "Toplam Ödeme",
    balance: "Bakiye",
    website: "İnternet Sitesi",
    safe_short_name: "Kasa Kısa Adı",
    safe_name: "Kasa Adı",
    debt: "Borç",
    due: "Alacak",
    can_collect_money: "BB Tahsilatı",
    can_receive_bills: "Fatura Ödeme",
    user_commercial_type: "Yasal Statü",
    personal: "Kişisel",
    commercial: "Ticari",
    account_short_name: "Hesap Kısa Adı",
    account_name: "Hesap Adı",
    bank_institution_code: "Kurum Kodu",
    bank_account_number: "Hesap Numarası",
    bank_branch_code: "Şube Kodu",
    iban: "IBAN",
    account_type: "Hesap Tipi",
    bank_name: "Banka Adı",
    bank_client_code: "Müşteri Numarası",
    can_used_for_sms: "SMS",
    residents_can_see: "Sakinlere Açık",
    concern_name: "İlgili Kişi",
    concern_position: "İlgili Görevi",
    concern_phone: "İlgili Telefon",
    concern_email: "İlgili E-posta",
    bank_income_expense_type: "Banka Entegrasyon Tahsilat Fiş Türü",
    auto_assessment: "Otomatik Tahakkuk",
    provider_code: "Tedarikçi Kodu",
    provider_name: "Tedarikçi Adı",
    provider_title: "Tedarikçi Unvanı",
    bank_integration_type: "Banka Entegrasyon Kuralı",
    expense_type: "Gider Türü",
    expense_kind: "Gider Tipi",
    share: "Hisse Miktarı",
    is_staying: "Burada Oturuyor",
    is_spokesperson: "İlgili Kişi",
    new_renter_date: "Yeni Kiracı Giriş Tarihi",
    new_owner_date: "Yeni Ev Sahibi Giriş Tarihi",
    car_plate_number: "Araç Plakası",
    password: "Parola",
    date: "Tarih",
    opening_receipt_type: "Alacak Fiş Türü",
    show_only_house_users: "Sadece B.B. Kişilerini Göster",
    opening_receipt_amount: "Açılış Devir Alacaklı Tutar",
    debt_type: "Borçlandırma Nedeni",
    debtor_name: "Borçlu Kişi",
    valid_until: "Geçerlilik Tarihi",
    assessment_timing_type: "Tahakkuk Zamanlama Tipi",
    assessment_timing_day: "Tahakkuk Zamanlama Günü",
    last_payment_timing_type: "Son Ödeme Zamanlama Tipi",
    last_payment_timing_day: "Son Ödeme Zamanlama Günü",
    debt_amount: "Borç Tutarı",
    delay_calculation_type: "Gecikme Hesaplama Türü",
    delay_percentage: "Gecikme Oranı (Aylık)",
    delay_option_days: "Gecikme Opsiyon",
    project_code: "Proje",
    identity_no: "TC Kimlik No",
    father_name: "Baba Adı",
    verified: "Doğrulandı",
    not_verified: "Doğrulanmadı",
    address_legal: "Tebligat Adresi",
    address_work: "İş Adresi",
    phone_verification_status: "Telefon Doğrulama Durumu",
    phone_2: "Telefon 2",
    phone_home: "Ev Telefonu",
    phone_work: "İş Telefonu",
    blood_type: "Kan Grubu",
    email_2: "E-posta 2",
    door_number: "Kapı Numarası",
    door_number_s: "BB No",
    house_user_type: "Oturum Şekli",
    notification_medium: "Bildirim Şekli",
    text: "Metin",
    is_seen: "Görülme Durumu",
    file_type: "Belge Tipi",
    file_size: "Belge Boyutu",
    title: "Başlık",
    note_type: "Not Tipi",
    has_push_notifications: "Mobil Bildirim",
    mobile_os: "Mobil Cihaz",
    paid: "Ödendi",
    not_paid: "Ödenmedi",
    invoiced_type: "Fatura Durumu",
    bill_date: "Fatura Tarihi",
    last_payment_date: "Son Ödeme Tarihi",
    date_of_issue: "Düzenlenme Tarihi",
    bill_no: "Belge No",
    utility_no: "Tesisat No",
    goods_description: "Ürün / Hizmet Açıklaması",
    bill_description: "Fatura Açıklaması",
    amount: "Tutar",
    safe_bank: "Kasa veya Banka Hesabı",
    payment_date: "Ödeme Tarihi",
    mobile_phone: "Cep Telefonu",
    custom_expense_type: "Özel Gider Türü",
    management_company: "Yönetim Şirketi",
    updates_person_data: "Kişi Bilgilerini Güncelle",
    share_expense_data: "Gider Bilgilerini Paylaş",
    has_share_meter: "Pay Ölçer Sistemi",
    has_cash_collection_notifications: "Nakit Tahsilat Bildirimleri",
    has_cash_payment_notifications: "Nakit Ödeme Bildirimleri",
    geo_block: "Ada",
    geo_plot: "Parsel",
    house_type_s: "BB Tipi",
    total_assessment: "Tahakkuk Eden",
    house_balance_s: "BB Bakiye",
    all: "Tümü",
    assessment: "Tahakkuk",
    payed: "Ödenen",
    defer_assessment: "Gecikme Tahakkuk",
    defer_payed: "Gecikme Ödenen",
    defer_balance: "Gecikme Bakiye",
    total_balance: "Toplam Bakiye",
    has_enforcement: "İcra",
  },
};
