<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Müşteriye TYA Ekle</template>
    <template slot="body">
      <v-form @submit.prevent="handleFormSubmit" ref="form">
        <v-row cols="12" dense>
          <v-col sm="12">
            <rs-select-cluster
              label="TYA Seçin"
              v-model="formData.cluster_id"
              :rules="[rules.required]"
              :filled="isLoading || id"
              :readonly="isLoading || id"
              :admin="hasRole('superadministrator')"
              :clearable="!id"
            />
          </v-col>

          <v-col sm="6">
            <rs-datepicker
              label="Başlangıç Tarihi"
              v-model="formData.starts_on"
              :rules="[rules.required]"
              hint="Ücretlendirme başlangıç tarihini belirler"
              persistent-hint
            />
          </v-col>

          <v-col sm="6">
            <rs-datepicker
              label="Bitiş Tarihi"
              v-model="formData.ends_on"
              :disabled="!formData.starts_on"
              :rules="
                !formData.starts_on
                  ? []
                  : [rules.min(formData.ends_on, formData.starts_on)]
              "
              :min="formData.starts_on"
              hint="Ücretlendirme bitiş tarihini belirler"
              persistent-hint
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  mixins: [hasForm, hasPermissions],
  data() {
    return {
      id: null,
      formData: {
        customer_id: null,
        cluster_id: null,
        starts_on: new Date().toJSON().split("T")[0],
        ends_on: null,
      },
    };
  },
  methods: {
    show(customerId, id) {
      this.formData = {
        cluster_id: null,
        customer_id: null,
        starts_on: new Date().toJSON().split("T")[0],
        ends_on: null,
      };
      if (customerId) {
        this.formData.customer_id = customerId;
      } else {
        return false;
      }

      if (id) {
        this.id = id;
        this.load();
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/customer-clusters/${this.id}`)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`api-super-admin/customer-clusters/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`api-super-admin/customer-clusters`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
