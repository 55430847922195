import ApiService from "./api.service";
import {
  UPDATE_BANK_ACTIVITY_COUNT,
  UPDATE_COMPANY_ID,
  UPDATE_COMPANY,
  UPDATE_WORKER_POSITIONS_LIST,
} from "./store/company.module";
import store from "@/core/services/store";

const CompanyService = {
  company: null,
  companyId: null,
  init() {
    try {
      this.companyId =
        parseInt(window.localStorage.getItem("companyId")) || null;
      store.dispatch(UPDATE_COMPANY_ID, this.companyId);
      this.updateCompanyId(this.companyId);
    } catch (e) {
      // eslint-disable-next-line
      console.log("company could not be loaded");
    }
  },
  updateCompanyId(companyId) {
    if (!companyId) {
      this.companyId = null;
      this.company = null;
      store.dispatch(UPDATE_COMPANY_ID, null);
      this.updateCompany(null);
      // store.dispatch(UPDATE_BANK_ACTIVITY_COUNT, 0);

      return;
    }

    ApiService.query(`companies/${companyId}`)
      .then((response) => {
        this.updateCompany(response.data.data);
        this.companyId = response.data.data.id;
        // this.getBankActivityCount();
      })
      .catch((error) => {
        store.dispatch(UPDATE_COMPANY_ID, null);
        // eslint-disable-next-line
        console.error(error);
      });
  },
  updateCompany(company) {
    this.company = company;
    store.dispatch(UPDATE_COMPANY, company);

    if (this.companyId) {
      this.getWorkerPositions();
    }
  },
  getBankActivityCount() {
    const params = {
      list_type: "open",
      per_page: 1,
      company_id: this.companyId,
    };

    ApiService.query("income/bank-activities", { params })
      .then((response) => {
        store.dispatch(UPDATE_BANK_ACTIVITY_COUNT, response.data.meta.total);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  getWorkerPositions() {
    const params = {
      company_id: this.companyId,
    };

    ApiService.query("worker-positions", { params })
      .then((response) => {
        if (response.data.data) {
          store.dispatch(UPDATE_WORKER_POSITIONS_LIST, response.data.data);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error("Could not retrieve generic list", error);
      });
  },
};

export default CompanyService;
