<template>
  <tr v-if="initialized">
    <td
      v-for="header in headers"
      :key="header.value"
      :class="{
        'text-center': header.searchable && header.searchable === 'number',
      }"
    >
      <rs-text-field
        class="py-2"
        :label="`${header.text} Ara`"
        v-if="header.searchable && header.searchable === 'text'"
        v-model="searchLocal[header.value]"
        dense
        @input="handleChange"
      />

      <rs-select
        class="py-2"
        v-if="header.searchable && header.searchable === 'select'"
        :items="
          typeof header.options === 'function'
            ? header.options()
            : header.options
        "
        :item-text="header.itemText"
        :item-value="header.itemValue || 'id'"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.searchValue || header.value]"
        clearable
        dense
        hide-search
        @change="handleChange"
      />

      <rs-select
        class="py-2"
        v-if="header.searchable && header.searchable === 'multiselect'"
        :items="
          typeof header.options === 'function'
            ? header.options()
            : header.options
        "
        :item-text="header.itemText"
        item-value="id"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.searchValue || header.value]"
        multiple
        clearable
        dense
        hide-search
        :menu-props="{
          minWidth: 150,
        }"
        @change="handleChange"
      />

      <rs-select
        class="py-2"
        v-if="header.searchable && header.searchable === 'yesNo'"
        :items="yesNoOptions"
        :item-text="header.itemText || 'text'"
        item-value="id"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.value]"
        clearable
        dense
        hide-search
        @change="handleChange"
      />

      <rs-select
        class="py-2"
        v-if="header.searchable && header.searchable === 'isActive'"
        :items="isActiveOptions"
        :item-text="header.itemText || 'text'"
        item-value="id"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.value]"
        clearable
        dense
        hide-search
        @change="handleChange"
      />

      <rs-select
        class="py-2"
        v-if="header.searchable && header.searchable === 'successFail'"
        :items="successFailOptions"
        :item-text="header.itemText || 'text'"
        item-value="id"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.value]"
        clearable
        dense
        hide-search
        @change="handleChange"
      />

      <v-menu
        v-if="header.searchable && header.searchable === 'date'"
        offset-y
        max-width="200"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed small v-on="on" v-bind="attrs">
            <template
              v-if="
                searchLocal[header.value].min && searchLocal[header.value].max
              "
            >
              {{ searchLocal[header.value].min }} ~
              {{ searchLocal[header.value].max }}
            </template>
            <template v-else-if="searchLocal[header.value].min">
              {{ searchLocal[header.value].min }}
            </template>
            <template v-else-if="searchLocal[header.value].max">
              {{ searchLocal[header.value].max }}
            </template>
            <template v-else>Ara</template>
          </v-btn>
        </template>
        <v-card>
          <v-card-text v-if="searchLocal[header.value] !== undefined">
            <v-form>
              <v-row cols="2" dense>
                <v-col>
                  <rs-datepicker
                    label="Minimum"
                    v-model="searchLocal[header.value].min"
                    dense
                    autofocus
                    @change="handleNumberChange(header.value)"
                  />
                </v-col>
                <v-col>
                  <rs-datepicker
                    label="Maksimum"
                    v-model="searchLocal[header.value].max"
                    dense
                    @change="handleNumberChange(header.value)"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-menu
        v-if="header.searchable && header.searchable === 'number'"
        offset-y
        max-width="200"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn depressed small v-on="on" v-bind="attrs">
            <template
              v-if="
                searchLocal[header.value].min && searchLocal[header.value].max
              "
            >
              {{ searchLocal[header.value].min }} ~
              {{ searchLocal[header.value].max }}
            </template>
            <template v-else-if="searchLocal[header.value].min">
              {{ searchLocal[header.value].min }}
            </template>
            <template v-else-if="searchLocal[header.value].max">
              {{ searchLocal[header.value].max }}
            </template>
            <template v-else>Ara</template>
          </v-btn>
        </template>
        <v-card>
          <v-card-text v-if="searchLocal[header.value] !== undefined">
            <v-form>
              <v-row cols="2" dense>
                <v-col>
                  <rs-text-field
                    type="number"
                    label="Minimum"
                    v-model="searchLocal[header.value].min"
                    dense
                    autofocus
                    @change="handleNumberChange(header.value)"
                  />
                </v-col>
                <v-col>
                  <rs-text-field
                    type="number"
                    label="Maksimum"
                    v-model="searchLocal[header.value].max"
                    dense
                    @change="handleNumberChange(header.value)"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-menu>

      <div v-if="header.searchable && header.searchable === 'bankSafe'">
        <rs-select-collecting
          :label="`${header.text} Ara`"
          :bank-account-id.sync="searchLocal.bank_account_id"
          :safe-id.sync="searchLocal.safe_id"
          clearable
          dense
          hide-search
          @change="handleChange"
        />
      </div>

      <rs-select-location
        class="py-2"
        v-if="header.searchable && header.searchable === 'location'"
        :item-text="header.itemText"
        item-value="id"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.value]"
        clearable
        dense
        hide-search
        @change="handleChange"
        :location-type-id="header.locationTypeId"
        :parent-id="
          typeof header.parentId === 'function'
            ? header.parentId()
            : header.parentId
        "
      />

      <rs-select-cluster
        class="py-2"
        v-if="header.searchable && header.searchable === 'cluster'"
        :item-text="header.itemText"
        item-value="id"
        :label="`${header.text} Ara`"
        v-model="searchLocal[header.searchValue || header.value]"
        clearable
        dense
        hide-search
        hide-buttons
        @change="handleChange"
        :multiple="header.multiple || false"
      />
    </td>
  </tr>
</template>

<script>
import i18n from "@/core/plugins/vue-i18n";

export default {
  name: "rsay-head-search",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    search: {
      type: Object,
      required: true,
    },
  },
  watch: {
    search: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    headers() {
      this.initialize();
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.searchLocal = Object.assign({}, this.search);
      this.setNumberRanges();
      this.initialized = true;
    },
    reset() {
      this.initialized = false;
      const search = {
        query: null,
        trigger: null,
      };
      this.$emit("update:search", search);
      this.setNumberRanges();
      this.initialized = true;
    },
    setNumberRanges() {
      for (const index in this.headers) {
        if (
          this.headers[index].searchable &&
          (this.headers[index].searchable === "number" ||
            this.headers[index].searchable === "date")
        ) {
          if (this.search[this.headers[index].value] !== undefined) {
            const search = this.search[this.headers[index].value];

            if (search.min !== undefined) {
              if (search.min.constructor.name === "Date") {
                search.min = search.min.toJSON().split("T")[0];
              }
            }
            if (search.max !== undefined) {
              if (search.max.constructor.name === "Date") {
                search.max = search.max.toJSON().split("T")[0];
              }
            }

            this.searchLocal[this.headers[index].value] = search;
          } else {
            this.searchLocal[this.headers[index].value] = {
              min: undefined,
              max: undefined,
            };
          }
        }
      }
    },
    handleNumberChange(itemKey) {
      this.searchLocal[itemKey] = Object.assign(
        {},
        {
          min: this.searchLocal[itemKey].min,
          max: this.searchLocal[itemKey].max,
        }
      );
      this.$nextTick(() => {
        this.$emit("update:search", this.searchLocal);
      });
    },
    handleChange() {
      this.$emit("update:search", this.searchLocal);
    },
  },
  data() {
    return {
      searchLocal: {},
      initialized: false,
      isActiveOptions: [
        {
          id: true,
          text: i18n.t("active"),
        },
        {
          id: false,
          text: i18n.t("passive"),
        },
      ],
      yesNoOptions: [
        {
          id: true,
          text: i18n.t("yes"),
        },
        {
          id: false,
          text: i18n.t("no"),
        },
      ],
      successFailOptions: [
        {
          id: true,
          text: i18n.t("success"),
        },
        {
          id: false,
          text: i18n.t("fail"),
        },
      ],
    };
  },
};
</script>
