<template>
  <VSelect
    :items="roles"
    item-text="display_name"
    item-value="id"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    :multiple="!single"
  />
</template>

<script>
import { VSelect } from "vuetify/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    VSelect,
  },
  props: {
    roleType: {
      type: String,
      required: false,
      default: null,
    },
    single: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["roleList"]),
    roles() {
      if (!this.roleType) {
        return this.roleList;
      }

      return this.roleList.filter((item) => {
        return item.type === this.roleType;
      });
    },
  },
};
</script>
