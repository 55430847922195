<template>
  <rs-select
    :label="label"
    :items="bankList"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    item-text="name"
    item-value="id"
  />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: "Banka Seçin",
    },
  },
  computed: {
    ...mapGetters(["bankList"]),
  },
};
</script>
