import Vue from "vue";
import ApiService from "@/core/services/api.service";

function Api(Vue) {
  ApiService.init();

  Vue.prototype.$api = ApiService;
}

Vue.use(Api);
