export default [
  {
    path: "/expenses/invoice/create",
    name: "expenses.invoice.create",
    props: () => ({ type: "simple" }),
    component: () => import("@/view/pages/expense/invoice/InvoiceForm.vue"),
  },
  {
    path: "/expenses/invoices",
    name: "expenses.invoices.list",
    component: () => import("@/view/pages/expense/invoice/InvoiceList.vue"),
  },
  {
    path: "/expenses/invoices/:id",
    name: "expenses.invoices.show",
    props: (route) => ({ id: parseInt(route.params.id), disabled: true }),
    component: () => import("@/view/pages/expense/invoice/InvoiceForm.vue"),
  },
  {
    path: "/expenses/invoices/:id/edit",
    name: "expenses.invoices.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/expense/invoice/InvoiceForm.vue"),
  },
  {
    path: "/expenses/invoiceitems/list",
    name: "expenses.invoiceitems.list",
    component: () =>
      import("@/view/pages/expense/invoice/InvoiceItemsList.vue"),
  },
  {
    path: "/expenses/invoicepayments/list",
    name: "expenses.invoicepayments.list",
    component: () =>
      import("@/view/pages/expense/invoice/InvoicePaymentsList.vue"),
  },
];
