<template>
  <span v-if="number !== null" :class="cssClass"
    ><span v-if="!print"
      >{{ percentage ? "%" : "" }}{{ stringValue }}{{ price ? "₺" : "" }}</span
    ><span v-else>{{ value }}</span></span
  >
</template>

<script>
import { getBalanceClass } from "@/core/helpers";

export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    colored: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    price: {
      type: Boolean,
      required: false,
      default: false,
    },
    mutedZeros: {
      type: Boolean,
      required: false,
      default: false,
    },
    percentage: {
      type: Boolean,
      required: false,
      default: false,
    },
    print: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cssClass() {
      const classes = [];
      if (this.colored) {
        if (this.reverse) {
          classes.push(getBalanceClass(this.value * -1));
        } else {
          classes.push(getBalanceClass(this.value));
        }
      }

      if (this.mutedZeros && (this.number === 0 || this.number === 0.0)) {
        classes.push("text-muted");
      }

      return classes.join(" ") || null;
    },
    number() {
      if (this.value === undefined || this.value === null) {
        return null;
      }

      if (this.value.constructor.name !== "Number") {
        return new Number(this.value);
      }

      return this.value;
    },
    stringValue() {
      if (this.price) {
        return this.number.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      return this.number.toLocaleString();
    },
  },
};
</script>
