<template>
  <div>
    <v-data-table
      v-if="cluster"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          title="Sakinler"
          icon="mdi-account-badge"
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          @click:edit="handleEditClick"
          :search.sync="search.query"
          v-if="!hideTitleBar && $refs.headSearch"
        >
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.house_count="{ value }">
        <rs-table-cell-number :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_active="{ item }">
        <rs-table-cell-boolean :value="item.is_active" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.can_receive_bills="{ item }">
        <rs-table-cell-boolean :value="item.can_receive_bills" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.can_collect_money="{ item }">
        <rs-table-cell-boolean :value="item.can_collect_money" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_demo="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_staying="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.role="{ value }">
        {{ value.join(", ") }}
      </template>
    </v-data-table>

    <p v-else>Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasDataTable],
  computed: {
    ...mapGetters([
      "blocks",
      "cluster",
      "clusterId",
      "countryList",
      "houseTypes",
      "roleList",
      "userCommercialTypeList",
      "houseUserTypeList",
    ]),
  },
  props: {
    blockId: {
      type: Number,
      required: false,
      default: null,
    },
    blockIds: {
      type: Array,
      required: false,
      default: undefined,
    },
    hideTitleBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      search: {
        is_active: true,
      },
      options: {
        sortBy: ["name"],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-resident"),
        },
        {
          text: "Adı Soyadı",
          value: "name",
          searchable: "text",
          width: "155px",
        },
        {
          text: this.$t("labels.house_type"),
          value: "house_type",
          searchable: "multiselect",
          options: () => this.houseTypes,
          itemText: "name",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          options: () => this.houseUserTypeList,
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.floor"),
          value: "floor",
          searchable: "text",
        },
        {
          text: this.$t("labels.phone"),
          value: "phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.identity_no"),
          value: "identity_number",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
        {
          text: this.$t("labels.user_role"),
          value: "role",
          searchable: "multiselect",
          options: () => this.roleList,
          itemText: "display_name",
        },
        {
          text: "Vatandaşlık",
          value: "citizenship",
          searchable: "select",
          options: () => this.countryList,
          itemText: "name",
        },
        {
          text: this.$t("labels.user_commercial_type"),
          value: "user_commercial_type",
          searchable: "select",
          options: () => this.userCommercialTypeList,
        },
        {
          text: "BB Sayısı",
          value: "house_count",
          searchable: "number",
          sortable: true,
          align: "end",
        },
        {
          text: "Oturuyor",
          value: "is_staying",
          searchable: "yesNo",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`clusters/${this.clusterId}/residents`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    blockId() {
      this.loadList();
    },
    blockIds: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    extraParams: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    selectedItems(newValue) {
      this.$emit("update", newValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
