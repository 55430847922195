<template>
  <vue-tel-input-vuetify
    :label="label"
    :readonly="disabled"
    :filled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
    placeholder="Lütfen bir numara giriniz"
    :clearable="true"
    :name="name"
    defaultCountry="TR"
    :disabledFetchingCountry="true"
    selectLabel="Ülke Kodu"
    mode="international"
    wrapperClasses="global-tel"
  >
  </vue-tel-input-vuetify>
</template>

<script>
export default {
  name: "TelField",
  props: {
    name: {
      required: false,
      type: String,
      default: "phone",
    },
    label: {
      required: false,
      type: String,
      default: "Telefon",
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
.vue-tel-input-vuetify .country-code {
  width: 6em;
}
.global-tel .v-select__selections:after {
  content: "\F035D";
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.54);
  font-size: 18px;
}
</style>
