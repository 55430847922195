<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    @change="handleChange"
    :loading="isLoading"
    :custom-item="customItem"
  >
    <template v-slot:item="data">
      <v-list-item-content :class="data.item.is_active ? '' : 'text-muted'">
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <template slot="append-outer" v-if="can('edit-expense-type')">
      <v-btn
        v-if="$refs.expenseTypeForm"
        small
        icon
        outlined
        @click="$refs.expenseTypeForm.show()"
        color="pink"
        title="Yeni gelir türü ekle"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <ExpenseTypeBasicForm @created="handleCreated" ref="expenseTypeForm" />
    </template>
  </rs-select>
</template>

<script>
import { mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";
import { ExpenseTypeBasicForm } from "@/view/content/definitions/forms";

export default {
  props: {
    clusterId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Gider Türü",
    },
    showPassive: {
      type: Boolean,
      required: false,
      default: false,
    },
    customItem: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mixins: [hasPermissions],
  components: { ExpenseTypeBasicForm },
  computed: {
    ...mapGetters(["cluster", "expenseTypeList"]),
    options() {
      let list = this.expenseTypeList;

      if (!this.showPassive) {
        list = list.filter((v) => v.is_active);
      }

      return list;
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false;
    });
  },
  methods: {
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
    },
    handleCreated(provider) {
      this.options.push(provider);
      this.$emit("input", provider.id);
    },
  },
};
</script>
