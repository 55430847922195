export default [
  {
    path: "/admin/users",
    name: "admin.users.list",
    component: () => import("@/view/pages/admin/users/UserList.vue"),
  },
  {
    path: "/admin/users/:id/edit",
    name: "admin.users.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/admin/users/UserForm.vue"),
  },
  {
    path: "/admin/payout-check",
    name: "admin.payout-check.list",
    component: () =>
      import("@/view/pages/admin/payout-check/PayoutCheckList.vue"),
  },
  {
    path: "/admin/import/transfers",
    name: "admin.import.transfers",
    component: () => import("@/view/pages/admin/import/ImportTransfers.vue"),
  },

  {
    path: "/admin/ads",
    name: "admin.ads.list",
    component: () => import("@/view/pages/admin/ads/AdvertList.vue"),
  },
  {
    path: "/admin/ads/create",
    name: "admin.ads.create",
    component: () => import("@/view/pages/admin/ads/AdvertForm.vue"),
  },
  {
    path: "/admin/ads/:id/edit",
    name: "admin.ads.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/admin/ads/AdvertForm.vue"),
  },

  {
    path: "/admin/crm/customers",
    name: "admin.crm.customers.list",
    component: () => import("@/view/pages/admin/customers/CustomerList.vue"),
  },
  {
    path: "/admin/crm/customers/create",
    name: "admin.crm.customers.create",
    component: () => import("@/view/pages/admin/customers/CustomerForm.vue"),
  },
  {
    path: "/admin/crm/customers/:id/edit",
    name: "admin.crm.customers.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/admin/customers/CustomerForm.vue"),
  },

  {
    path: "/admin/crm/customer-groups",
    name: "admin.crm.customer-groups.list",
    component: () =>
      import("@/view/pages/admin/customer-groups/CustomerGroupList.vue"),
  },
  {
    path: "/admin/crm/customer-groups/create",
    name: "admin.crm.customer-groups.create",
    component: () =>
      import("@/view/pages/admin/customer-groups/CustomerGroupForm.vue"),
  },
  {
    path: "/admin/crm/customer-groups/:id/edit",
    name: "admin.crm.customer-groups.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/admin/customer-groups/CustomerGroupForm.vue"),
  },

  {
    path: "/admin/crm/customer-invoices",
    name: "admin.crm.customer-invoices.list",
    component: () =>
      import("@/view/pages/admin/customer-invoices/CustomerInvoiceList.vue"),
  },
  {
    path: "/admin/crm/customer-invoices/create",
    name: "admin.crm.customer-invoices.create",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/admin/customer-invoices/CustomerInvoiceForm.vue"),
  },
  {
    path: "/admin/crm/customer-invoices/:id/edit",
    name: "admin.crm.customer-invoices.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/admin/customer-invoices/CustomerInvoiceForm.vue"),
  },

  {
    path: "/admin/crm/customer-payments",
    name: "admin.crm.customer-payments.list",
    component: () =>
      import("@/view/pages/admin/customer-payments/CustomerPaymentList.vue"),
  },
  {
    path: "/admin/crm/customer-payments/create",
    name: "admin.crm.customer-payments.create",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/admin/customer-payments/CustomerPaymentForm.vue"),
  },
  {
    path: "/admin/crm/customer-payments/:id/edit",
    name: "admin.crm.customer-payments.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/admin/customer-payments/CustomerPaymentForm.vue"),
  },
  {
    path: "/admin/crm/bank-activities",
    name: "admin.crm.bank-activities.list",
    component: () =>
      import("@/view/pages/admin/bank-activities/BankActivityList.vue"),
  },

  {
    path: "/admin/products",
    name: "admin.products.list",
    component: () => import("@/view/pages/admin/products/ProductList.vue"),
  },
  {
    path: "/admin/products/create",
    name: "admin.products.create",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/admin/products/ProductForm.vue"),
  },
  {
    path: "/admin/products/:id/edit",
    name: "admin.products.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/admin/products/ProductForm.vue"),
  },
];
