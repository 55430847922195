export default function buildSearchParams(search, options) {
  const params = {};
  for (const key in search) {
    if (key === "trigger") {
      continue;
    } else if (typeof search[key] === "boolean") {
      params[key] = search[key] ? "true" : "false";
    } else if (
      search[key] !== null &&
      search[key].constructor.name === "Object"
    ) {
      if (JSON.stringify(search[key]) !== "{}") {
        params[key] = {};
        for (const index in search[key]) {
          if (search[key][index] !== "") {
            params[key][index] = search[key][index];
          }
        }
      }
    } else if (search[key]) {
      params[key] = search[key];
      if (
        Object.keys(this.$route.params).length > 0 ||
        this.$route.name.includes("create")
      ) {
        params[key] = search[key];
      } else {
        params[key] = search[key];
      }
    }
  }

  if (options) {
    if (options.sortBy && options.sortBy.length > 0) {
      params.order_by = options.sortBy[0];
      params.order = options.sortDesc[0] === true ? "desc" : "asc";
    }

    if (options.itemsPerPage) {
      params.per_page = options.itemsPerPage;
    }

    params.page = options.page;

    if (
      Object.keys(this.$route.params).length > 0 ||
      this.$route.name.includes("create")
    ) {
      params.page = options.page;
    } else {
      const page = window.location.search.split("?page=")[1];
      if (page == options.page) {
        params.page = page;
      } else {
        params.page = options.page;
      }
    }
  }

  return params;
}
