<template>
  <VFileInput
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    :clearable="!required"
  >
  </VFileInput>
</template>

<script>
import { VFileInput } from "vuetify/lib";

export default {
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    VFileInput,
  },
};
</script>
