export default [
  {
    path: "/analysis/expense-pivot",
    name: "analysis.expense-pivot.list",
    component: () =>
      import("@/view/pages/analysis/expense-pivot/ExpensePivotList.vue"),
  },
  {
    path: "/analysis/expense-payment-pivot",
    name: "analysis.expense-payment-pivot.list",
    component: () =>
      import("@/view/pages/analysis/expense-pivot/ExpensePaymentPivotList.vue"),
  },
  {
    path: "/analysis/income-type-house-user",
    name: "analysis.income-type-house-user",
    component: () =>
      import("@/view/pages/analysis/IncomeTypeHouseUserList.vue"),
  },
  {
    path: "/analysis/monthly-house-user",
    name: "analysis.monthly-house-user",
    component: () =>
      import("@/view/pages/analysis/income/MonthlyHouseUserList.vue"),
  },
  {
    path: "/analysis/financial-status",
    name: "analysis.financial-status",
    component: () => import("@/view/pages/analysis/FinancialStatus.vue"),
  },
  {
    path: "/analysis/assessments",
    name: "analysis.assessments",
    component: () => import("@/view/pages/analysis/AssessmentAnalysis.vue"),
  },
];
