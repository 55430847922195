<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Ödeme Tedarikçisi Ekle</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="6">
            <rs-select
              :items="paymentProviderList"
              :rules="[rules.required]"
              label="Ödeme Tedarikçisi"
              v-model="formData.payment_provider_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="6">
            <rs-select-bank-account
              label="POS Hesabı"
              v-model="formData.pos_account_id"
              :rules="[rules.required]"
              hint="POS tahsilatları bu banka hesabına yapılır"
              persistent-hint
            />
          </v-col>

          <v-col sm="6">
            <rs-select-bank-account
              label="Banka Hesabı"
              v-model="formData.bank_account_id"
              :rules="[
                rules.required,
                formData.pos_account_id !== formData.bank_account_id ||
                  'POS hesabıyla aynı olmamalı',
              ]"
              hint="POS tahsilatları bu hesaba topluca aktarılır"
              persistent-hint
              @change="handleBankAccountChange"
            />
          </v-col>

          <v-col sm="6">
            <v-switch
              v-model="formData.is_active"
              :disabled="disabled"
              :label="formData.is_active ? 'Aktif' : 'Pasif'"
              hide-details="auto"
            ></v-switch>
          </v-col>

          <v-col sm="6">
            <v-switch
              v-model="formData.is_default"
              :disabled="disabled"
              label="Varsayılan"
              hide-details="auto"
            ></v-switch>
          </v-col>

          <v-col sm="6" v-if="formData.payment_provider_id === 1">
            <rs-text-field
              label="Sipay Alt Üye İşyeri ID"
              v-model="formData.submerchant_id"
              :rules="[
                rules.required,
                rules.minLength(formData.submerchant_id, 10),
                rules.maxLength(formData.submerchant_id, 100),
              ]"
              required
            />
          </v-col>

          <v-col cols="12" v-if="formData.payment_provider_id === 2">
            <v-divider class="my-6" />

            <v-row dense>
              <v-col cols="12"><h3>Param Detayları</h3></v-col>

              <v-col sm="6">
                <rs-select
                  v-model="formDataParam.company_type_id"
                  :disabled="disabled"
                  label="Şirket Türü"
                  :rules="[rules.required]"
                  :items="[
                    {
                      id: 1,
                      name: 'Kişi',
                    },
                    {
                      id: 2,
                      name: 'Şahıs Şirketi',
                    },
                    {
                      id: 3,
                      name: 'Limited veya Anonim Şirket',
                    },
                  ]"
                  hide-details="auto"
                ></rs-select>
              </v-col>

              <v-col sm="6">
                <rs-tel-field
                  label="Cep Telefonu"
                  :rules="[rules.required, rules.numbersOnlyWithParam]"
                  v-model="formDataParam.mobile_phone"
                  :disabled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  label="Yetkili Kişi"
                  v-model="formDataParam.person_name"
                  :rules="[
                    rules.required,
                    rules.maxLength(formDataParam.person_name, 100),
                  ]"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-datepicker
                  label="Yetkili kişi doğum tarihi"
                  v-model="formDataParam.birthdate"
                  :rules="[rules.required]"
                  required
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  class="identity-number"
                  :label="$t('labels.identity_no')"
                  v-model="formDataParam.tc_number"
                  :rules="[
                    rules.exactLength(formDataParam.tc_number, 11),
                    rules.numbersOnly,
                    rules.required,
                  ]"
                  minlength="11"
                  maxlength="11"
                  :readonly="disabled"
                  :filled="disabled"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  label="IBAN hesap sahibi"
                  v-model="formDataParam.iban_title"
                  readonly
                  hint="Banka hesap tanımlarındaki kayıtlı hesap adı"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "ClusterPaymentProviderForm",
  mixins: [hasForm, hasPermissions],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["clusterId", "paymentProviderList"]),
  },
  data() {
    return {
      formData: {
        payment_provider_id: 2,
        pos_account_id: null,
        bank_account_id: null,
        is_active: true,
        is_default: true,
        submerchant_id: null,
      },
      formDataParam: {
        company_type_id: null,
        mobile_phone: null,
        person_name: null,
        birthdate: null,
        tc_number: null,
        iban_title: null,
      },
      id: null,
      disabled: false,
      extraParams: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(id, disabled, extraParams) {
      this.formData = Object.assign(
        {
          payment_provider_id: 2,
          pos_account_id: null,
          is_active: true,
          is_default: true,
          submerchant_id: null,
        },
        extraParams || {}
      );

      this.originalData = {};
      this.extraParams = {};

      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);
      const params = Object.assign({}, this.extraParams, formData);
      params.cluster_id = this.clusterId;

      if (params.payment_provider_id === 2) {
        params.param = Object.assign({}, this.formDataParam);
        params.param.mobile_phone = params.param.mobile_phone.replace(
          /[^0-9+]/g,
          ""
        );
      }

      this.isLoading = true;

      this.$api
        .post("cluster-payment-providers", params)
        .then((response) => {
          this.$toast.success(this.$t("saved"));
          this.$emit("created", response.data.data);
          this.$emit("saved", response.data.data);
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleBankAccountChange(bankAccountId) {
      this.isLoading = true;

      this.$api
        .get(`bank-accounts`, bankAccountId)
        .then((response) => {
          this.formDataParam.iban_title = response.data.data.title;
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
