export default [
  {
    path: "/my-company/info",
    name: "my-company.info",
    // TODO: get company id from top select
    props: () => ({ mode: "myCompany", id: -1 }),
    component: () => import("@/view/pages/companies/CompanyForm.vue"),
  },
  {
    path: "/my-company/workers",
    name: "my-company.workers.list",
    component: () => import("@/view/pages/my-company/WorkerList.vue"),
  },
  {
    path: "/my-company/workers/create",
    name: "my-company.workers.create",
    component: () => import("@/view/pages/my-company/WorkerForm.vue"),
  },
  {
    path: "/my-company/workers/:id/edit",
    name: "my-company.workers.edit",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () => import("@/view/pages/my-company/WorkerForm.vue"),
  },
  {
    path: "/my-company/workers/:id",
    name: "my-company.workers.show",
    props: (route) => ({ id: parseInt(route.params.id), disabled: true }),
    component: () => import("@/view/pages/my-company/WorkerForm.vue"),
  },
  {
    path: "/my-company/residents",
    name: "my-company.residents.list",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () => import("@/view/pages/my-company/ResidentList.vue"),
  },
  {
    path: "/my-company/worker-positions",
    name: "my-company.worker-positions.list",
    component: () =>
      import(
        "@/view/pages/my-company/worker-positions/WorkerPositionsList.vue"
      ),
  },
  {
    path: "/my-company/worker-positions/create",
    name: "my-company.worker-positions.create",
    component: () =>
      import("@/view/pages/my-company/worker-positions/WorkerPositionForm.vue"),
  },
  {
    path: "/my-company/worker-positions/:id",
    name: "my-company.worker-positions.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/my-company/worker-positions/WorkerPositionForm.vue"),
  },
  {
    path: "/my-company/worker-positions/:id/edit",
    name: "my-company.worker-positions.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/my-company/worker-positions/WorkerPositionForm.vue"),
  },
  {
    path: "/my-company/providers",
    name: "my-company.providers.list",
    component: () => import("@/view/pages/my-company/ProviderList.vue"),
  },
  {
    path: "/my-company/providers/create",
    name: "my-company.providers.create",
    component: () => import("@/view/pages/my-company/ProviderForm.vue"),
  },
  {
    path: "/my-company/providers/:id/edit",
    name: "my-company.providers.edit",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/view/pages/my-company/ProviderForm.vue"),
  },
  {
    path: "/my-company/providers/:id/show",
    name: "my-company.providers.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () => import("@/view/pages/my-company/ProviderForm.vue"),
  },
];
