<template>
  <button
    @click="goBack()"
    class="btn btn-success btn-sm d-flex align-items-center"
  >
    <i class="mdi mdi-arrow-left"></i>

    Geri Dön
  </button>
</template>
<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
i {
  font-size: 1.2em !important;
  margin-right: 4px;
}
</style>
