<template>
  <VCombobox
    dense
    label="Banka Entegrasyon Anahtar Kelimeleri"
    multiple
    chips
    clearable
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
  />
</template>

<script>
import { VCombobox } from "vuetify/lib";

export default {
  components: {
    VCombobox,
  },
};
</script>
