import Vue from "vue";

import ButtonDelete from "./ButtonDelete";
import CellBalance from "./CellBalance";
import CellBoolean from "./CellBoolean";
import CellDate from "./CellDate";
import CellEmail from "./CellEmail";
import CellNumber from "./CellNumber";
import CellPhone from "./CellPhone";
import CellString from "./CellString";
import FootTotals from "./FootTotals";
import HeadSearch from "./HeadSearch";
import TitleBar from "./TitleBar";

Vue.component("rs-table-button-delete", ButtonDelete);
Vue.component("rs-table-cell-balance", CellBalance);
Vue.component("rs-table-cell-boolean", CellBoolean);
Vue.component("rs-table-cell-date", CellDate);
Vue.component("rs-table-cell-email", CellEmail);
Vue.component("rs-table-cell-number", CellNumber);
Vue.component("rs-table-cell-phone", CellPhone);
Vue.component("rs-table-cell-string", CellString);
Vue.component("rs-table-foot-totals", FootTotals);
Vue.component("rs-table-head-search", HeadSearch);
Vue.component("rs-table-title-bar", TitleBar);
