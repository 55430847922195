<template>
  <div>
    <v-data-table
      v-if="cluster"
      v-bind="dataTableAttrs"
      :headers="headers"
      :items="list"
      :loading="isLoading"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page.sync="itemsPerPage"
      v-model="selectedItems"
      @update:items-per-page="handleItemsPerPageUpdated"
    >
      <template v-slot:top>
        <rs-table-title-bar
          title="Sakinler"
          icon="mdi-account-badge"
          @clear-filters="$refs.headSearch.reset"
          @reload="loadList"
          @click:edit="handleEditClick"
          :search.sync="search.query"
          v-if="!hideTitleBar && $refs.headSearch"
        >
        </rs-table-title-bar>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:body.prepend="{ headers }">
        <rs-table-head-search
          :headers="headers"
          :search.sync="search"
          ref="headSearch"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_active="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.is_staying="{ value }">
        <rs-table-cell-boolean :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.phone="{ value }">
        <rs-table-cell-phone :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.email="{ value }">
        <rs-table-cell-email :value="value" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.balance="{ value }">
        <rs-table-cell-balance colored :value="value" />
      </template>
    </v-data-table>

    <p v-else>Listeyi görmek için üst menüden bir toplu yaşam alanı seçin.</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  mixins: [hasDataTable],
  computed: {
    ...mapGetters([
      "blocks",
      "cluster",
      "clusterId",
      "countryList",
      "houseTypes",
      "userCommercialTypeList",
      "houseUserTypeList",
    ]),
  },
  props: {
    blockId: {
      type: Number,
      required: false,
      default: null,
    },
    blockIds: {
      type: Array,
      required: false,
      default: undefined,
    },
    hideTitleBar: {
      type: Boolean,
      required: false,
      default: true,
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      options: {
        sortBy: ["resident"],
      },
      search: {
        is_active: true,
      },
      headers: [
        {
          text: "Adı Soyadı",
          value: "resident",
          searchable: "text",
          width: "155px",
        },
        {
          text: this.$t("labels.house_type"),
          value: "house_type",
          searchable: "multiselect",
          searchValue: "house_type_ids",
          options: () => this.houseTypes,
          itemText: "name",
        },
        {
          text: "Oturum Şekli",
          value: "house_user_type",
          searchable: "multiselect",
          searchValue: "house_user_type_ids",
          options: () => this.houseUserTypeList,
        },
        {
          text: "Oturuyor",
          value: "is_staying",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: this.$t("labels.block"),
          value: "block",
          searchable: "multiselect",
          options: () => this.blocks,
          itemText: "name",
          width: "75px",
          align: "center",
          searchValue: "block_ids",
        },
        {
          text: "BB No",
          value: "door_number",
          searchable: "text",
          width: "75px",
          align: "center",
        },
        {
          text: this.$t("labels.floor"),
          value: "floor",
          searchable: "text",
        },
        {
          text: "Bakiye",
          value: "balance",
          searchable: "number",
          align: "end",
        },
        {
          text: this.$t("labels.phone"),
          value: "phone",
          searchable: "text",
        },
        {
          text: this.$t("labels.email"),
          value: "email",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.selectedItems = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      this.$api
        .query(`clusters/${this.clusterId}/house-users`, { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }, 250),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    blockId() {
      this.loadList();
    },
    blockIds: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    extraParams: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
    selectedItems(newValue) {
      this.$emit("update", newValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
