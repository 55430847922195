<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    custom-item
    @change="handleChange"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <template>
            Aylık:
            <strong
              ><rs-table-cell-number
                price
                :value="data.item.monthly_price_per_house"
              />/ay</strong
            >
            | Yıllık:
            <strong
              ><rs-table-cell-number
                price
                :value="data.item.yearly_price_per_house"
              />/yıl</strong
            >
          </template>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template slot="append-outer">
      <v-btn
        v-if="$refs.productForm"
        small
        icon
        outlined
        @click="$refs.productForm.show(null, null, formParams)"
        color="pink"
        title="Yeni tedarikçi tanımla"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <ProductForm @saved="handleProductCreated" ref="productForm" />
    </template>
  </rs-select>
</template>

<script>
import { ProductForm } from "@/view/pages/admin/components";

export default {
  name: "SelectProduct",
  props: {
    includePassive: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "Ürün Seçin",
    },
  },
  components: { ProductForm },
  watch: {
    includePassive() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      const params = {
        is_available: this.includePassive ? undefined : true,
        per_page: 1000,
      };
      this.options = [];

      return this.$api
        .query("api-super-admin/products", { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Products not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
      this.$emit("change:item", null);
    },
    handleProductCreated(product) {
      this.loadList().then(() => {
        this.$emit("change:item", product);
        this.$emit("input", product.id);
      });
    },
  },
};
</script>
