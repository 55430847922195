import CompanyService from "@/core/services/company.service";

// action types
export const UPDATE_BANK_ACTIVITY_COUNT = "updateBankActivityCount";
export const UPDATE_COMPANY = "updateCompany";
export const UPDATE_COMPANY_ID = "updateCompanyId";
export const UPDATE_REMINDER_COUNT = "updateReminderCount";
export const UPDATE_REMINDER_RECENT_LIST = "updateReminderRecentList";
export const UPDATE_WORKER_POSITIONS_LIST = "updateWorkerPositionsList";
export const UPDATE_BREAKDOWN_COUNT = "updateBreakdownCount";
export const UPDATE_BREAKDOWN_RECENT_LIST = "updateBreakdownRecentList";
export const UPDATE_SUGGESTION_COUNT = "updateSuggestionCount";
export const UPDATE_SUGGESTION_RECENT_LIST = "updateSuggestionRecentList";
export const UPDATE_COMPLAINT_COUNT = "updateComplaintCount";
export const UPDATE_COMPLAINT_RECENT_LIST = "updateComplaintRecentList";
export const UPDATE_MAINTENANCE_COUNT = "updateMaintenanceCount";
export const UPDATE_MAINTENANCE_RECENT_LIST = "updateMaintenanceRecentList";

// mutation types
export const SET_BANK_ACTIVITY_COUNT = "setBankActivityCount";
export const SET_COMPANY = "setCompany";
export const SET_COMPANY_ID = "setCompanyId";
export const SET_REMINDER_COUNT = "setReminderCount";
export const SET_REMINDER_RECENT_LIST = "setReminderRecentList";
export const SET_WORKER_POSITIONS_LIST = "setWorkerPositionsList";
export const SET_BREAKDOWN_COUNT = "setBreakdownCount";
export const SET_BREAKDOWN_RECENT_LIST = "setBreakdownRecentList";
export const SET_SUGGESTION_COUNT = "setSuggestionCount";
export const SET_SUGGESTION_RECENT_LIST = "setSuggestionRecentList";
export const SET_COMPLAINT_COUNT = "setComplaintCount";
export const SET_COMPLAINT_RECENT_LIST = "setComplaintRecentList";
export const SET_MAINTENANCE_COUNT = "setMaintenanceCount";
export const SET_MAINTENANCE_RECENT_LIST = "setMaintenanceRecentList";

const state = {
  bankActivityCount: null,
  company: null,
  companyId: null,
  reminderCount: 0,
  reminderRecentList: [],
  worker_positions_list: [],
  breakdownCount: 0,
  breakdownRecentList: [],
  suggestionCount: 0,
  suggestionRecentList: [],
  complaintCount: 0,
  complaintRecentList: [],
  maintenanceCount: 0,
  maintenanceRecentList: [],
};

// try {
//   state.company = JSON.parse(window.localStorage.getItem("company")) || null;
// } catch (e) {
//   // eslint-disable-next-line
//   console.log("company could not be loaded");
// }

const getters = {
  bankActivityCount(state) {
    return state.bankActivityCount;
  },
  company(state) {
    return state.company;
  },
  companyId(state) {
    return state.companyId;
  },
  companyClustersActive(state) {
    if (state.company) {
      return state.company.clusters_active.map((item) => {
        return item.cluster;
      });
    }

    return [];
  },
  reminderCount(state) {
    return state.reminderCount;
  },
  reminderRecentList(state) {
    return state.reminderRecentList;
  },
  workerPositionsList(state) {
    return state.worker_positions_list;
  },
  breakdownCount(state) {
    return state.breakdownCount;
  },
  breakdownRecentList(state) {
    return state.breakdownRecentList;
  },
  suggestionCount(state) {
    return state.suggestionCount;
  },
  suggestionRecentList(state) {
    return state.suggestionRecentList;
  },
  complaintCount(state) {
    return state.complaintCount;
  },
  complaintRecentList(state) {
    return state.complaintRecentList;
  },
  maintenanceCount(state) {
    return state.maintenanceCount;
  },
  maintenanceRecentList(state) {
    return state.maintenanceRecentList;
  },
};

const actions = {
  [UPDATE_BANK_ACTIVITY_COUNT](context, payload) {
    context.commit(SET_BANK_ACTIVITY_COUNT, payload);
  },
  [UPDATE_COMPANY](context, payload) {
    context.commit(SET_COMPANY, payload);
  },
  [UPDATE_COMPANY_ID](context, payload) {
    if (payload !== state.companyId) {
      window.localStorage.setItem("companyId", payload);
      context.commit(SET_COMPANY_ID, payload);
      CompanyService.updateCompanyId(payload);
    }
  },
  [UPDATE_REMINDER_COUNT](context, payload) {
    context.commit(SET_REMINDER_COUNT, payload);
  },
  [UPDATE_REMINDER_RECENT_LIST](context, payload) {
    context.commit(SET_REMINDER_RECENT_LIST, payload);
  },
  [UPDATE_WORKER_POSITIONS_LIST](context, payload) {
    context.commit(SET_WORKER_POSITIONS_LIST, payload);
  },
  [UPDATE_BREAKDOWN_COUNT](context, payload) {
    context.commit(SET_BREAKDOWN_COUNT, payload);
  },
  [UPDATE_BREAKDOWN_RECENT_LIST](context, payload) {
    context.commit(SET_BREAKDOWN_RECENT_LIST, payload);
  },
  [UPDATE_SUGGESTION_COUNT](context, payload) {
    context.commit(SET_SUGGESTION_COUNT, payload);
  },
  [UPDATE_SUGGESTION_RECENT_LIST](context, payload) {
    context.commit(SET_SUGGESTION_RECENT_LIST, payload);
  },
  [UPDATE_COMPLAINT_COUNT](context, payload) {
    context.commit(SET_COMPLAINT_COUNT, payload);
  },
  [UPDATE_COMPLAINT_RECENT_LIST](context, payload) {
    context.commit(SET_COMPLAINT_RECENT_LIST, payload);
  },
  [UPDATE_MAINTENANCE_COUNT](context, payload) {
    context.commit(SET_MAINTENANCE_COUNT, payload);
  },
  [UPDATE_MAINTENANCE_RECENT_LIST](context, payload) {
    context.commit(SET_MAINTENANCE_RECENT_LIST, payload);
  },
};

const mutations = {
  [SET_BANK_ACTIVITY_COUNT](state, bankActivityCount) {
    state.bankActivityCount = bankActivityCount;
  },
  [SET_COMPANY](state, company) {
    state.company = company;
  },
  [SET_COMPANY_ID](state, companyId) {
    state.companyId = companyId;
  },
  [SET_REMINDER_COUNT](state, reminderCount) {
    state.reminderCount = window.parseInt(reminderCount, 10) || 0;
  },
  [SET_REMINDER_RECENT_LIST](state, list) {
    state.reminderRecentList = list;
  },
  [SET_WORKER_POSITIONS_LIST](state, list) {
    state.worker_positions_list = list;
  },
  [SET_BREAKDOWN_COUNT](state, breakdownCount) {
    state.breakdownCount = window.parseInt(breakdownCount, 10) || 0;
  },
  [SET_BREAKDOWN_RECENT_LIST](state, list) {
    state.breakdownRecentList = list;
  },
  [SET_SUGGESTION_COUNT](state, suggestionCount) {
    state.suggestionCount = window.parseInt(suggestionCount, 10) || 0;
  },
  [SET_SUGGESTION_RECENT_LIST](state, list) {
    state.suggestionRecentList = list;
  },
  [SET_COMPLAINT_COUNT](state, complaintCount) {
    state.complaintCount = window.parseInt(complaintCount, 10) || 0;
  },
  [SET_COMPLAINT_RECENT_LIST](state, list) {
    state.complaintRecentList = list;
  },
  [SET_MAINTENANCE_COUNT](state, maintenanceCount) {
    state.maintenanceCount = window.parseInt(maintenanceCount, 10) || 0;
  },
  [SET_MAINTENANCE_RECENT_LIST](state, list) {
    state.maintenanceRecentList = list;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
