<template>
  <v-dialog v-model="showDialog" max-width="290">
    <v-card>
      <v-card-title>{{ text }}</v-card-title>
      <v-card-actions>
        <div class="text-end w-100">
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="default"
            class="me-5"
            type="button"
            @click="$emit('cancelled')"
          >
            {{ $t("no") }}
          </v-btn>

          <v-btn
            :loading="loading"
            :disabled="loading"
            color="red"
            dark
            @click="$emit('confirmed')"
          >
            {{ $t("yes") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      text: "Emin misiniz?",
    };
  },
  methods: {
    show(text) {
      if (text) {
        this.text = text;
      }
      this.showDialog = true;
    },
    hide() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  word-break: keep-all;
}
</style>
