// @ts-check

import ApiService from "./api.service";
import {
  UPDATE_BLOCKS,
  UPDATE_CHILD_EXPENSE_TYPES,
  UPDATE_CHILD_MAINTENANCE_TYPES,
  UPDATE_CLUSTER,
  UPDATE_CLUSTER_ID,
  UPDATE_CUSTOM_INCOME_TYPES,
  UPDATE_EXPENSE_TYPES,
  UPDATE_INCOME_TYPES,
  UPDATE_LAWYERS,
  UPDATE_MAINTENANCE_TYPES,
  UPDATE_POSITIVE_EXPENSE_TYPES,
  UPDATE_PROJECTS,
  UPDATE_FIXTURE_GROUPS,
  UPDATE_PERMISSIONS,
  // @ts-ignore
} from "@/core/services/store/cluster.module";
import { UPDATE_BANK_ACTIVITY_COUNT } from "./store/company.module";
// @ts-ignore
import store from "@/core/services/store";
// @ts-ignore
import company from "@/core/services/store";

const ClusterService = {
  /** @type {?Object} */
  cluster: null,
  /** @type {?Number} */
  clusterId: null,
  /** @type {any[]} */
  childExpenseTypeList: [],
  /** @type {any[]} */
  childMaintenanceTypeList: [],
  /** @type {any[]} */
  fixtureGroupsList: [],
  init() {
    try {
      this.clusterId =
        parseInt(window.localStorage.getItem("clusterId") || "") || null;
      store.dispatch(UPDATE_CLUSTER_ID, this.clusterId);
      this.updateClusterId(this.clusterId);
    } catch (e) {
      // eslint-disable-next-line
      console.log("cluster could not be loaded");
    }
  },
  updateClusterId(clusterId) {
    if (!clusterId) {
      this.clusterId = null;
      this.cluster = null;
      this.loadUserPermissionList();
      // if (!company.state.company.companyId) {
      //   store.dispatch(UPDATE_BANK_ACTIVITY_COUNT, 0);
      // } else {
      //   this.getBankActivityCount(true);
      // }
      return;
    }

    // TODO: This check needs to be done but it does not load the options on
    // full page reload. Fix needed.
    // if (clusterId === this.clusterId) {
    //   return;
    // }

    ApiService.query(`clusters/${clusterId}`)
      .then((response) => {
        this.clusterId = response.data.data.id;
        this.updateCluster(response.data.data);
        this.loadBlocks();
        this.loadExpenseTypeList();
        this.loadIncomeTypeList();
        this.loadProjectList();
        this.loadLawyerList();
        this.loadMaintenanceTypeList();
        this.getFixtureGroupsList();
        this.loadUserPermissionList();
        // this.getBankActivityCount(false);
      })
      .catch((error) => {
        store.dispatch(UPDATE_CLUSTER_ID, null);
        // eslint-disable-next-line
        console.error(error);
      });
  },
  updateCluster(cluster) {
    this.cluster = cluster;
    store.dispatch(UPDATE_CLUSTER, cluster);
  },
  loadBlocks() {
    const params = {
      order_by: "name",
      per_page: 150,
    };

    ApiService.query(`clusters/${this.cluster.id}/blocks`, { params })
      .then((response) => {
        store.dispatch(UPDATE_BLOCKS, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  loadExpenseTypeList() {
    const params = {
      order_by: "name",
      per_page: 1000,
      cluster_id: this.clusterId,
    };

    ApiService.query("types/expense-types", { params })
      .then((response) => {
        const parents = [];
        const children = [];
        const positive = [];

        for (const index in response.data.data) {
          const item = response.data.data[index];

          if (item.sign === -1) {
            positive.push(item);
          } else if (item.parent_id) {
            children.push(item);
          } else {
            parents.push(item);
          }
        }

        this.childExpenseTypeList = children;

        store.dispatch(UPDATE_EXPENSE_TYPES, parents);
        store.dispatch(UPDATE_CHILD_EXPENSE_TYPES, children);
        store.dispatch(UPDATE_POSITIVE_EXPENSE_TYPES, positive);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  loadIncomeTypeList() {
    const params = {
      order_by: "id",
      per_page: 1000,
    };

    ApiService.query(`clusters/${this.cluster.id}/income-types`, { params })
      .then((response) => {
        const parents = [];
        const others = [];

        for (const index in response.data.data) {
          const item = response.data.data[index];
          if (item.parent_id) {
            others.push(item);
          } else {
            parents.push(item);
          }
        }

        store.dispatch(UPDATE_INCOME_TYPES, parents);
        store.dispatch(UPDATE_CUSTOM_INCOME_TYPES, others);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  loadProjectList() {
    const params = {
      order_by: "name",
      per_page: 1000,
    };

    ApiService.query(`clusters/${this.cluster.id}/projects`, { params })
      .then((response) => {
        store.dispatch(UPDATE_PROJECTS, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  loadLawyerList() {
    const params = {
      order_by: "name",
      provider_type: 1,
      per_page: 150,
      cluster_id: this.cluster.id,
    };

    ApiService.query("cluster-providers", { params })
      .then((response) => {
        store.dispatch(UPDATE_LAWYERS, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  loadMaintenanceTypeList() {
    const params = {
      order_by: "name",
      is_active: true,
      cluster_id: this.clusterId,
      per_page: 200,
    };

    ApiService.query("maintenance-types", { params })
      .then((response) => {
        const parents = [];
        const children = [];

        for (const index in response.data.data) {
          const item = response.data.data[index];

          if (item.parent_id) {
            children.push(item);
          } else {
            parents.push(item);
          }
        }

        this.childMaintenanceTypeList = children;

        store.dispatch(UPDATE_MAINTENANCE_TYPES, parents);
        store.dispatch(UPDATE_CHILD_MAINTENANCE_TYPES, children);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  getMaintenanceTypeChildren(parentId) {
    const list = [];

    for (const index in this.childMaintenanceTypeList) {
      if (this.childMaintenanceTypeList[index].parent_id === parentId) {
        list.push(this.childMaintenanceTypeList[index]);
      }
    }

    return list;
  },
  getExpenseTypeChildren(expenseTypeId) {
    const list = [];

    for (const index in this.childExpenseTypeList) {
      if (this.childExpenseTypeList[index].parent_id === expenseTypeId) {
        list.push(this.childExpenseTypeList[index]);
      }
    }

    return list;
  },
  getFixtureGroupsList() {
    const params = {
      order_by: "name",
      per_page: 1000,
    };

    ApiService.query(`/clusters/${this.cluster.id}/fixture-groups/`, { params })
      .then((response) => {
        store.dispatch(UPDATE_FIXTURE_GROUPS, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  loadUserPermissionList() {
    let params = {};

    if (this.clusterId) {
      params = {
        cluster_id: this.clusterId,
      };
    }

    ApiService.query("/permissions", { params })
      .then((response) => {
        store.dispatch(UPDATE_PERMISSIONS, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
  getBankActivityCount(noCluster) {
    let params = {};
    if (noCluster) {
      params = {
        list_type: "open",
        per_page: 1,
        cluster: undefined,
        company_id: company.state.company.companyId,
      };
    } else {
      params = {
        list_type: "open",
        per_page: 1,
        cluster: this.clusterId ? [this.clusterId] : undefined,
        company_id: this.cluster.active_company
          ? this.cluster.active_company.company_id
          : undefined,
      };
    }

    ApiService.query("income/bank-activities", { params })
      .then((response) => {
        store.dispatch(UPDATE_BANK_ACTIVITY_COUNT, response.data.meta.total);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
};

export default ClusterService;
