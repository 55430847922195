<script>
import { VTextarea } from "vuetify/lib";

export default {
  extends: VTextarea,
  mixins: [
    {
      props: {
        hideDetails: {
          default: "auto",
        },
        rows: {
          default: 2,
        },
      },
    },
  ],
};
</script>
