export default [
  {
    path: "/reports/operating/income-expense",
    name: "reports.operating.income-expense",
    component: () => import("@/view/pages/reports/IncomeExpenseReport.vue"),
  },
  {
    path: "/reports/cluster/audit",
    name: "reports.cluster.audit",
    component: () => import("@/view/pages/reports/AuditReport.vue"),
  },
  {
    path: "/reports/cluster/operating-items",
    name: "reports.cluster.operating-items",
    component: () => import("@/view/pages/reports/OperatingItemList.vue"),
  },
  {
    path: "/reports/cluster/operating",
    name: "reports.cluster.operating",
    component: () => import("@/view/pages/reports/OperatingReport.vue"),
  },
  {
    path: "/reports/extracts/account-statement",
    name: "reports.extracts.account-statement",
    component: () => import("@/view/pages/reports/AccountStatement.vue"),
  },
  {
    path: "/reports/operating/accounting-income-expense",
    name: "reports.operating.accounting-income-expense",
    component: () => import("@/view/pages/reports/AccountingIncomeExpense.vue"),
  },
  {
    path: "/reports/extracts/balance-activities/bank-account",
    name: "reports.extracts.balance-activities.bank-account",
    component: () => import("@/view/pages/reports/balance-activities/Bank.vue"),
  },
  {
    path: "/reports/extracts/balance-activities/safe",
    name: "reports.extracts.balance-activities.safe",
    component: () => import("@/view/pages/reports/balance-activities/Safe.vue"),
  },
  {
    path: "/reports/extracts/balance-activities/provider",
    name: "reports.extracts.balance-activities.provider",
    component: () =>
      import("@/view/pages/reports/balance-activities/Provider.vue"),
  },
  {
    path: "/reports/operating/expense",
    name: "reports.operating.expense",
    component: () => import("@/view/pages/reports/ExpenseReport.vue"),
  },
  {
    path: "/reports/operating/income",
    name: "reports.operating.income",
    component: () => import("@/view/pages/reports/IncomeReport.vue"),
  },
  {
    path: "/reports/operating/income-expense-review",
    name: "reports.operating.income-expense-review",
    component: () =>
      import("@/view/pages/reports/operating/IncomeExpenseReviewReport.vue"),
  },
  {
    path: "/reports/review-reports/review",
    name: "reports.review-reports.review",
    component: () => import("@/view/pages/reports/ReviewReport.vue"),
  },
  {
    path: "/reports/review-reports/review-detail",
    name: "reports.review-reports.review-detail",
    component: () => import("@/view/pages/reports/ReviewDetailReport.vue"),
  },
  {
    path: "/reports/sums/expense-detail",
    name: "reports.sums.expense-detail",
    component: () =>
      import("@/view/pages/reports/sums/ExpenseDetailReport.vue"),
  },
];
