export default {
  data() {
    return {
      titleBarAttrs: {
        showExport: true,
        exportColumns: [],
        exportUrl: null,
      },
    };
  },
  methods: {
    generateExportColumns(headers) {
      const columns = [];

      for (const index in headers) {
        if (headers[index].value === "edit") {
          continue;
        }

        if (
          headers[index].hide !== undefined &&
          headers[index].hide.constructor.name === "Function"
        ) {
          if (headers[index].hide()) {
            continue;
          }
        }

        columns.push({
          text: headers[index].text,
          value: headers[index].value,
        });
      }

      this.titleBarAttrs.exportColumns = columns;

      return columns;
    },
  },
};
