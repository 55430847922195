<script>
import { VSwitch } from "vuetify/lib";

import i18n from "@/core/plugins/vue-i18n";

export default {
  extends: VSwitch,
  mixins: [
    {
      computed: {
        label() {
          return this.lazyValue ? i18n.t("active") : i18n.t("passive");
        },
      },
      props: {
        hideDetails: {
          default: "auto",
        },
      },
    },
  ],
};
</script>
