// @ts-check

import hasDataTable from "./hasDataTable";
// import { debounce } from "@/core/plugins/debounce";

export default {
  mixins: [hasDataTable],
  watch: {
    // options: {
    //   handler() {
    //     this.setURLParams();
    //   },
    //   deep: true,
    // },
    // search: {
    //   handler() {
    //     this.setURLParams();
    //   },
    //   deep: true,
    // },
  },
  methods: {
    setURLParams() {
      const params = {
        page: this.options.page || 1,
      };

      if (this.options.sortBy && this.options.sortBy.length) {
        params.order_by = this.options.sortBy[0];
      }

      if (this.options.sortDesc && this.options.sortDesc.length) {
        params.order = this.options.sortDesc[0] === true ? "desc" : "asc";
      }

      if (this.options.itemsPerPage) {
        params.per_page = this.options.itemsPerPage;
      }

      for (const index in this.search) {
        const value = this.search[index];
        if (value === null || value === undefined) {
          continue;
        }

        if (value.constructor.name === "String") {
          if (value.trim() !== "") {
            params[index] = value;
          }
        } else if (value.constructor.name === "Number") {
          params[index] = value;
        } else if (value.constructor.name === "Array") {
          if (value.length > 0) {
            params[index + "[]"] = value;
          }
        } else if (value.constructor.name === "Object") {
          // This should be a date or number range
          if (value.min) {
            params[index + "[min]"] = value.min;
          }

          if (value.max) {
            params[index + "[max]"] = value.max;
          }
        } else if (value.constructor.name === "Boolean") {
          params[index] = value.toString();
        }
      }

      const urlSearchParams = new URLSearchParams(params);
      const newUrl =
        window.location.origin +
        window.location.pathname +
        "?" +
        urlSearchParams.toString();

      window.history.replaceState({ path: newUrl }, "", newUrl);
      this.loadList();
    },
    loadURLParams() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const options = {};
      const search = {};

      if (urlSearchParams.has("page")) {
        options.page = window.parseInt(urlSearchParams.get("page") || "1", 10);
      }

      if (urlSearchParams.has("per_page")) {
        options.itemsPerPage = window.parseInt(
          urlSearchParams.get("per_page") || "20",
          10
        );
      }

      if (urlSearchParams.has("order_by")) {
        options.sortBy = [urlSearchParams.get("order_by")];
      }

      if (urlSearchParams.has("order")) {
        options.sortDesc =
          urlSearchParams.get("order") === "desc" ? [true] : [false];
      }

      for (const [index, value] of urlSearchParams) {
        if (["page", "order_by", "order"].indexOf(index) !== -1) {
          continue;
        }

        const number = new Number(value);

        if (index.substring(index.length - 2) === "[]") {
          // Then we have an array
          const arr = value.split(",");
          if (!arr.length) {
            continue;
          }

          const number = new Number(arr[0]);

          if (!Number.isNaN(number.valueOf()) && value.indexOf(".") === -1) {
            // It's an integer array
            search[index.substring(0, index.length - 2)] = arr.map((v) =>
              window.parseInt(v, 10)
            );
          } else if (
            !Number.isNaN(number.valueOf()) &&
            value.indexOf(".") !== -1
          ) {
            // it's a float array
            search[index.substring(0, index.length - 2)] = arr.map((v) =>
              window.parseFloat(v)
            );
          } else {
            // It's a string array
            search[index.substring(0, index.length - 2)] = arr;
          }
        } else if (
          index.substring(index.length - 1) === "]" &&
          (index.endsWith("[min]") || index.endsWith("[max]"))
        ) {
          // We have a range here! For example: amount[min]=15&amount[max]=30
          const key = index.substring(0, index.length - 5);
          const number = new Number(value);
          const date = new Date(value);

          if (index.endsWith("[min]") && value.trim().length) {
            search[key] = search[key] || {};
            if (date.toString() !== "Invalid Date") {
              // it's a date
              search[key].min = value;
            } else if (
              !Number.isNaN(number.valueOf()) &&
              value.indexOf(".") === -1
            ) {
              // it's an integer
              search[key].min = window.parseInt(value, 10);
            } else if (
              !Number.isNaN(number.valueOf()) &&
              value.indexOf(".") !== -1
            ) {
              // it's an float
              search[key].min = window.parseFloat(value);
            }
          } else if (index.endsWith("[max]") && value.trim().length) {
            search[key] = search[key] || {};
            if (date.toString() !== "Invalid Date") {
              // it's a date
              search[key].max = value;
            } else if (
              !Number.isNaN(number.valueOf()) &&
              value.indexOf(".") === -1
            ) {
              // it's an integer
              search[key].max = window.parseInt(value, 10);
            } else if (
              !Number.isNaN(number.valueOf()) &&
              value.indexOf(".") !== -1
            ) {
              // it's an float
              search[key].max = window.parseFloat(value);
            }
          }
        } else if (value === "true" || value === "false") {
          search[index] = value === "true";
        } else if (!Number.isNaN(number.valueOf())) {
          // We have a number
          if (value.indexOf(".") === -1) {
            // It's an integer
            search[index] = window.parseInt(value, 10);
          } else {
            search[index] = window.parseFloat(value);
          }
        } else {
          // We have a string here
          if (value.trim().length) {
            search[index] = value.trim();
          }
        }
      }

      this.options = Object.assign({}, this.options, options);
      this.search = Object.assign({}, this.search, search);
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.loadURLParams();
  },
};
