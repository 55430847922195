<template>
  <rs-side-panel ref="sidePanel">
    <template slot="body">
      <ProductForm
        side-view
        :hide-save="true"
        :hide-back-button="true"
        @saved="handleProductSaved"
        @cancelled="hide"
        :id="id"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm } from "@/view/mixins";

import ProductForm from "@/view/pages/admin/products/ProductForm.vue";

export default {
  components: { ProductForm },
  mixins: [hasForm],
  data() {
    return {
      id: null,
    };
  },
  methods: {
    show(id) {
      if (id) {
        this.id = id;
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    handleProductSaved(event) {
      this.$emit("saved", event);
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
