<template>
  <v-data-table
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title=""
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        add-route=""
        @click:add="handleAddClick"
        hide-edit
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.append="{ headers }">
      <rs-table-foot-totals
        :headers="headers"
        :totals="footTotals"
        v-if="list.length > 0"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.edit="{ item }">
      <router-link
        :to="{
          name: 'admin.crm.customer-invoices.edit',
          params: {
            id: item.id,
          },
        }"
        class="btn btn-icon btn-sm btn-clean"
      >
        <i class="menu-icon mdi mdi-pencil"></i>
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount_collected="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.amount_tax_included="{ value }">
      <rs-table-cell-number price :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.invoiced_on="{ value }">
      <rs-table-cell-date :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.actions="{ item }">
      <rs-table-button-delete
        @deleted="handleDeleteClick(item)"
        confirmed
        :loading="isLoading"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <!-- <template v-slot:item.removed_at="{ value, item }">
      <rs-table-cell-date show-time :value="value" v-if="value" />
      <rs-table-button-delete
        v-else
        @deleted="handleDeleteConfirmed(item)"
        :loading="isLoading"
        confirmed
        label="Kaldır"
      />
    </template> -->
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "AdminCustomerInvoiceList",
  mixins: [hasDataTable],
  props: {
    customerId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    customerId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["invoiced_on"],
        sortDesc: [false],
      },
      search: {
        is_active: true,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Tarih",
          value: "invoiced_on",
          searchable: "date",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Tutar (KDV dahil)",
          value: "amount_tax_included",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödeme Tutarı",
          value: "amount_collected",
          searchable: "number",
          align: "end",
        },
        // {
        //   text: null,
        //   value: "actions",
        //   searchable: false,
        //   sortable: false,
        //   align: "right",
        // },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.customerId) {
        params.customer_id = this.customerId;
      }

      return this.$api
        .query("api-super-admin/customer-invoices", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleAddClick() {
      this.$refs.customerProductForm.show(this.customerId);
    },
    handleEditClick(item) {
      this.$refs.customerProductForm.show(null, item.id);
    },
    // handleDeleteClick(item) {
    //   if (this.isLoading) {
    //     return false;
    //   }

    //   this.isLoading = true;

    //   return this.$api
    //     .delete(`api-super-admin/customer-invoices/${item.id}`)
    //     .then(() => {
    //       this.$toast.success(this.$t("toasts.item_deleted"));
    //     })
    //     .catch((error) => {
    //       this.handleError(error);
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     })
    //     .then(() => {
    //       this.loadList();
    //     });
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
