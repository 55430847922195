<template>
  <span :title="title">
    <v-icon v-if="value" color="success">mdi-check</v-icon>
    <v-icon v-else :color="falseColor">mdi-close</v-icon>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    title: {
      default: null,
      type: String,
      required: false,
    },
    falseColor: {
      default: "warning",
      type: String,
      required: false,
    },
  },
};
</script>
