<template>
  <rs-side-panel ref="sidePanel" :size="size">
    <template slot="header">Sakin-BB Seçin</template>
    <template slot="body">
      <SimpleHouseUserList @update="handleSelectionUpdate" />

      <rs-form-buttons
        hide-submit
        :is-loading="isLoading"
        @cancel="hide()"
        @submit="handleFormSubmit"
        save-close-label="Seçilenleri Ekle"
      />
    </template>
  </rs-side-panel>
</template>

<script>
import { mapGetters } from "vuex";

import { hasForm } from "@/view/mixins";
import SimpleHouseUserList from "@/view/content/clusters/SimpleHouseUserList";

export default {
  name: "ModalHouseUser",
  mixins: [hasForm],
  components: {
    SimpleHouseUserList,
  },
  props: {
    size: {
      type: String,
      required: false,
      default: "small",
    },
  },
  computed: {
    ...mapGetters([]),
  },
  data() {
    return {
      selectedIds: [],
    };
  },
  methods: {
    handleSelectionUpdate(newItems) {
      this.selectedIds = newItems.map((v) => v.id);
    },
    handleFormSubmit() {
      this.$emit("selected", this.selectedIds);
      this.hide();
    },
    show() {
      this.originalData = {};
      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
  },
};
</script>
