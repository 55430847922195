<template>
  <rs-select :items="items" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import { mapGetters } from "vuex";

import OptionsService from "@/core/services/options.service";

export default {
  props: {
    locationTypeId: {
      type: Number,
      required: true,
      default: null,
    },
    parentId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["cityList"]),
  },
  watch: {
    locationTypeId() {
      this.setItems();
    },
    parentId() {
      this.setItems();
    },
  },
  data() {
    return {
      items: [],
    };
  },
  mounted() {
    this.$nextTick(() => this.setItems());
  },
  methods: {
    setItems() {
      if (this.locationTypeId === 2) {
        this.items = this.cityList;
      } else if (this.locationTypeId === 3) {
        if (!this.parentId) {
          return;
        }

        this.items = [];

        OptionsService.getDistrictList(this.parentId).then((list) => {
          this.items = list;
        });
      } else {
        // eslint-disable-next-line
        console.info("Unused location type id: " + this.locationTypeId);
      }
    },
  },
};
</script>
