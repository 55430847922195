import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";
import "@/core/plugins/portal-vue";
// import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
// import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
// import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import "@/core/plugins/vue-toastification";
import "@/core/plugins/v-mask";
import "@/core/plugins/api";
import "@/core/plugins/matomo";

import "@/view/content";
import "@/view/content/forms";
import "@/view/content/data-table";

Vue.use(VueTelInputVuetify, {
  vuetify,
});

store.dispatch(VERIFY_AUTH);

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  // store.dispatch(VERIFY_AUTH)]).then(next);
  document.title = "Robosay";
  next();
  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
