<template>
  <rs-select
    :label="label"
    :items="options"
    custom-item
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="data.item.system_id === 7">
          <strong>E-posta içinde ekstre gönderilir.</strong>
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ data.item.description }}
          <!-- <rs-table-cell-string :limit="50" :value="data.item.body" /> -->
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </rs-select>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectNotificationTemplate",
  props: {
    clusterId: {
      type: Number,
      required: false,
      default: null,
    },
    companyId: {
      type: Number,
      required: false,
      default: null,
    },
    channels: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
      default: "Şablon Seçin",
    },
    preselectSystemId: {
      type: Number,
      required: false,
      default: null,
    },
    sendable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["cluster", "company"]),
  },
  watch: {
    cluster() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
    company() {
      this.loadList();
    },
    companyId() {
      this.loadList();
    },
    channels() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
      firstLoad: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      const clusterId =
        this.clusterId || (this.cluster ? this.cluster.id : null) || null;
      const companyId =
        this.companyId || (this.company ? this.company.id : null) || null;
      const params = { per_page: 100 };

      if (clusterId) {
        params.cluster_id = clusterId;
      }

      if (companyId) {
        params.company_id = companyId;
      }

      if (this.channels.length !== 0) {
        params.notification_channel_id = this.channels;
      }

      if (this.sendable) {
        params.sendable = true;
      }

      params.order_by = "name";
      params.order = "asc";

      this.$api
        .query("notification-templates", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.options = response.data.data;

            if (this.firstLoad && this.preselectSystemId) {
              for (const index in this.options) {
                if (this.options[index].system_id === this.preselectSystemId) {
                  this.$emit("input", this.options[index].id);
                  break;
                }
              }
            }
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
