<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    @change="handleChange"
    custom-item
    :loading="isLoading"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ getExpenseName(data.item) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template slot="append-outer" v-if="can('edit-cluster-provider')">
      <v-btn
        v-if="$refs.providerForm"
        small
        icon
        outlined
        @click="$refs.providerForm.show(null, null, formParams)"
        color="pink"
        title="Yeni tedarikçi tanımla"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <ClusterProviderBasicForm
        @created="handleProviderCreated"
        ref="providerForm"
      />
    </template>
  </rs-select>
</template>

<script>
import { mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";
import { ClusterProviderBasicForm } from "@/view/content/definitions/forms";

export default {
  props: {
    clusterId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Tedarikçi",
    },
    searchParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    formParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mixins: [hasPermissions],
  components: { ClusterProviderBasicForm },
  computed: {
    ...mapGetters(["cluster", "expenseTypeList"]),
  },
  watch: {
    cluster() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.cluster) {
        this.loadList();
      }
    });
  },
  methods: {
    loadList() {
      const clusterId = this.clusterId || this.cluster.id;
      if (!clusterId) {
        return false;
      }

      const params = Object.assign(
        { per_page: 1000, order_by: "name", order: "asc" },
        this.searchParams
      );
      params.cluster_id = clusterId;
      this.options = [];
      this.isLoading = true;

      this.$api
        .query("cluster-providers", { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Providers not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getExpenseName(provider) {
      for (const index in this.expenseTypeList) {
        if (this.expenseTypeList[index].id === provider.expense_type_id) {
          return this.expenseTypeList[index].name;
        }
      }

      return null;
    },
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
    },
    handleProviderCreated(provider) {
      this.options.push(provider);
      this.$emit("input", provider.id);
    },
  },
};
</script>
