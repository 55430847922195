<template>
  <v-autocomplete
    :items="list"
    :loading="isLoading"
    :search-input.sync="search"
    :filter="() => true"
    item-text="name"
    item-value="id"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    @change="handleChange"
  ></v-autocomplete>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";

export default {
  props: {
    label: {
      type: String,
      required: false,
      default: "Yönetim Şirketi Seçin",
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  watch: {
    search: debounce(function () {
      this.loadList();
    }, 250),
  },
  mounted() {
    this.$nextTick(() => this.loadList());
  },
  data() {
    return {
      list: [],
      isLoading: false,
      search: null,
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const params = Object.assign(
        {
          page: 1,
          query: this.companySearch,
          company_type_id: 1,
        },
        this.extraParams
      );

      if (this.search) {
        params.query = this.search;
      }
      params.is_active = 1;

      this.$api
        .query("companies", { params })
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error loading company list", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleChange(selectedId) {
      if (!selectedId) {
        this.$emit("change:option", { id: null, item: null });
        return false;
      }
      let selectedItem;

      for (const index in this.list) {
        if (this.list[index].id === selectedId) {
          selectedItem = Object.assign(this.list[index]);
          break;
        }
      }

      this.$emit("change:option", {
        id: selectedId,
        item: selectedItem,
      });

      this.isLoading = true;
      this.$nextTick(() => {
        this.isLoading = false;
      });

      return false;
    },
    /**
     * Adds a new option to result list.
     * @param  {Object} item Company Object
     * @return {Boolean}      True if added, false if already exists
     */
    pushOption(item) {
      for (const index in this.list) {
        if (this.list[index].id === item.id) {
          return false;
        }
      }
      this.list.push(item);

      return true;
    },
  },
};
</script>
