const ID_TOKEN_KEY = "token";
const ID_TOKEN_KEY_ID = "token_id";
const SUPERADMIN_TOKEN = "superadmin_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getTokenId = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY_ID);
};

export const saveToken = (token) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveTokenId = (tokenId) => {
  window.localStorage.setItem(ID_TOKEN_KEY_ID, tokenId);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem(ID_TOKEN_KEY_ID);
};

export const setTemporaryToken = (token, tokenId) => {
  const data = {};
  data[ID_TOKEN_KEY] = window.localStorage.getItem(ID_TOKEN_KEY);
  data[ID_TOKEN_KEY_ID] = window.localStorage.getItem(ID_TOKEN_KEY_ID);

  window.localStorage.setItem(SUPERADMIN_TOKEN, JSON.stringify(data));

  saveToken(token);
  saveTokenId(tokenId);
};

export const removeTemporaryToken = () => {
  const superAdminToken = JSON.parse(
    window.localStorage.getItem(SUPERADMIN_TOKEN)
  );

  saveToken(superAdminToken[ID_TOKEN_KEY]);
  saveTokenId(superAdminToken[ID_TOKEN_KEY_ID]);
  window.localStorage.removeItem(SUPERADMIN_TOKEN);
};

export const hasTemporaryToken = () => {
  let superAdminToken = null;

  try {
    superAdminToken = JSON.parse(window.localStorage.getItem(SUPERADMIN_TOKEN));
  } catch {
    return false;
  }

  return superAdminToken && superAdminToken[ID_TOKEN_KEY] ? true : false;
};

export default {
  getToken,
  getTokenId,
  saveToken,
  saveTokenId,
  destroyToken,
  setTemporaryToken,
  removeTemporaryToken,
  hasTemporaryToken,
};
