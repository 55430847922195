<template>
  <VSelect
    :item-text="itemText"
    :item-value="itemValue"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    :items="itemsShown"
    :clearable="$attrs.clearable === undefined ? !required : $attrs.clearable"
    open-on-clear
    @change="handleChange"
    :multiple="multiple"
    @click:clear="handleClearClick"
  >
    <template v-slot:prepend-item v-if="!hideSearch || !hideButtons">
      <v-list-item v-if="!hideSearch">
        <rs-text-field autofocus v-model="search" label="Ara" />
      </v-list-item>
      <v-list-item v-if="!hideButtons && multiple">
        <rs-action @click="handleSelectAllClick">Tümünü Seç</rs-action>
        <rs-action @click="handleSelectNoneClick">Tümünü Kaldır</rs-action>
      </v-list-item>
    </template>

    <template slot="append-outer">
      <slot name="append-outer"></slot>
    </template>

    <template v-slot:item="data" v-if="customItem">
      <slot name="item" v-bind="data">
        {{ data.item[itemValue] }}
      </slot>
    </template>
  </VSelect>
</template>

<script>
import { VSelect } from "vuetify/lib";

import { slugify } from "@/core/helpers";

export default {
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemText: {
      type: String,
      required: false,
      default: "name",
    },
    itemValue: {
      type: String,
      required: false,
      default: "id",
    },
    hideButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    customItem: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    VSelect,
  },
  computed: {
    itemsShown() {
      if (typeof this.items !== "object") {
        return [];
      }

      if (!this.search) {
        return this.items.concat(this.extraOptions);
      }

      const list = [];
      const search = slugify(this.search);

      for (const index in this.items.concat(this.extraOptions)) {
        const item = this.items[index];
        if (slugify(item[this.itemText]).indexOf(search) !== -1) {
          list.push(item);
        }
      }

      return list;
    },
  },
  data() {
    return {
      search: null,
      extraOptions: [],
    };
  },
  methods: {
    pushOption(option) {
      this.extraOptions.push(option);
    },
    handleChange(id) {
      for (const index in this.items) {
        if (id === this.items[index].id) {
          this.$emit("change:item", this.items[index]);
          return;
        }
      }
    },
    handleSelectAllClick() {
      this.$emit(
        "input",
        this.items.map((v) => v.id)
      );
    },
    handleSelectNoneClick() {
      this.$emit("input", []);
    },
    handleClearClick() {
      this.search = null;
    },
  },
};
</script>
