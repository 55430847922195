<template>
  <span v-if="value" :class="cssClass" :title="value">
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
      type: String,
    },
    limit: {
      default: null,
      required: false,
      type: Number,
    },
    cssClass: {
      default: null,
      required: false,
    },
    limiter: {
      default: "…",
      required: false,
      type: String,
    },
  },
  computed: {
    text() {
      const value = this.value.trim();

      if (value) {
        if (this.limit > 0) {
          if (value.length > this.limit) {
            return value.substr(0, this.limit) + this.limiter;
          }
        }

        return value;
      }

      return null;
    },
  },
};
</script>
