<template>
  <v-data-table
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title=""
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        hide-edit
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.cluster_name="{ item }">
      <router-link
        v-if="item.cluster_id"
        :to="{
          name: 'definitions.clusters.edit',
          params: {
            id: item.cluster_id,
          },
        }"
      >
        {{ item.cluster_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.batch_notification_name="{ item }">
      <router-link
        v-if="item.batch_notification_id"
        :to="{
          name: 'communication.batch-notifications.edit',
          params: {
            id: item.batch_notification_id,
          },
        }"
      >
        {{ item.batch_notification_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.text="{ value }">
      <rs-table-cell-string :value="value" :limit="100" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_read="{ value, item }">
      <rs-table-cell-boolean
        :value="value"
        :title="value !== null && new Date(item.read_at).toLocaleString()"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "AdminNotificationList",
  mixins: [hasDataTable],
  props: {
    userId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["notificationStatusList"]),
  },
  watch: {
    userId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "Metin",
          value: "text",
          searchable: "text",
        },
        {
          text: "Okunma",
          value: "is_read",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "Durum",
          value: "notification_status",
          searchable: "multiselect",
          searchValue: "notification_status_ids",
          options: () => this.notificationStatusList,
          width: "120px",
        },
        {
          text: "TYA",
          value: "cluster_name",
          searchable: "text",
        },
        {
          text: "Toplu Gönderim",
          value: "batch_notification_name",
          searchValue: "batch_notification_ids",
          searchable: "text",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.userId) {
        params.user_id = this.userId;
      }

      return this.$api
        .query("api-super-admin/notifications", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
