import Vue from "vue";
import Toast, { POSITION } from "vue-toastification";

import "vue-toastification/dist/index.css";

const options = {
  timeout: 1500,
  position: POSITION.TOP_RIGHT,
};

Vue.use(Toast, options);
