<template>
  <div class="pb-1">
    <h3 class="d-inline-block me-2" style="vertical-align: top">
      <v-icon v-if="icon && icon.substring(0, 3) === 'mdi'">
        {{ icon }}
      </v-icon>
      <i v-else-if="icon" class="menu-icon icon-xl" :class="icon"></i>
      {{ title }}&nbsp;
    </h3>
    <h5 v-if="subtitle">{{ subtitle }}</h5>
    <slot name="buttons">
      <v-btn
        color="pink"
        small
        class="float-end ms-2 text-white"
        :to="addRoute === '#' ? null : addRoute"
        v-if="addRoute !== null"
        @click="handleAddClick"
        ref="addButton"
        accesskey="e"
        :disabled="!addEnabled"
      >
        Ekle
      </v-btn>
    </slot>
    <slot name="buttons:append"></slot>
    <v-btn
      text
      icon
      small
      color="secondary"
      title="Filtreleri temizle"
      class="float-end"
      @click.prevent="handleRemoveFiltersClick"
      v-if="!hideFilterClear"
      accesskey="f"
    >
      <v-icon>mdi-filter-remove-outline</v-icon>
    </v-btn>
    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          icon
          small
          color="secondary"
          title="Dışa aktar"
          class="float-end"
          v-if="showExport"
          v-on="on"
          v-bind="attrs"
          :loading="exportLoading"
          accesskey="d"
        >
          <v-icon>mdi-database-export</v-icon>
        </v-btn>
      </template>

      <slot name="body">
        <v-list>
          <v-list-item @click="handleExportClick('ods')"> ODS </v-list-item>
          <v-list-item @click="handleExportClick('xlsx')"> XLSX </v-list-item>
          <v-list-item @click="handleExportClick('pdf')"> PDF </v-list-item>
        </v-list>
      </slot>
    </v-menu>
    <v-btn
      text
      icon
      small
      color="secondary"
      title="İçe aktar"
      class="float-end"
      v-if="!hideImport"
    >
      <v-icon>mdi-database-import</v-icon>
    </v-btn>
    <v-btn
      text
      icon
      small
      color="secondary"
      title="Tabloyu yenile"
      class="float-end"
      @click="handleReloadClick"
      v-if="showReload"
      accesskey="r"
    >
      <v-icon>mdi-reload</v-icon>
    </v-btn>
    <v-btn
      text
      icon
      small
      color="secondary"
      title="Düzenle"
      class="float-end"
      @click="$emit('click:edit', $event)"
      :disabled="!editEnabled"
      v-if="!hideEdit"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <span class="float-end d-inline-block" ref="deleteButton" v-if="showDelete">
      <v-btn
        text
        icon
        small
        color="secondary"
        :title="$t('delete')"
        @click="handleDeleteClick"
        :disabled="!deleteEnabled"
      >
        <v-icon>mdi-beaker-remove-outline</v-icon>
      </v-btn>

      <rs-confirm
        ref="confirmDelete"
        :loading="isLoading"
        @confirmed="handleDeleteConfirmed"
        @cancelled="$refs.confirmDelete.hide()"
      />
    </span>
    <rs-text-field
      :value="search"
      @input="$emit('update:search', $event)"
      @click:clear="$emit('update:search', null)"
      label="Ara"
      v-if="!hideSearch"
      dense
      class="float-end"
      style="max-width: 150px"
      clearable
    />

    <slot name="buttons:prepend"></slot>

    <v-tooltip
      bottom
      :activator="$refs.deleteButton"
      v-if="$refs.deleteButton && showDelete && deleteTooltip"
    >
      <span>{{ deleteTooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { hasDataTable } from "@/view/mixins";

export default {
  name: "RsTitleBar",
  mixins: [hasDataTable],
  props: {
    addRoute: {
      type: Object,
      required: false,
      default: null,
    },
    addEnabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    hideFilterClear: {
      type: Boolean,
      required: false,
      default: false,
    },
    showReload: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: String,
      required: false,
      default: "mdi mdi-home",
    },
    editEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteTooltip: {
      type: String,
      required: false,
      default: null,
    },
    deleteConfirmed: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideImport: {
      type: Boolean,
      required: false,
      default: true,
    },
    search: {
      type: String,
      required: false,
      default: null,
    },
    showExport: {
      type: Boolean,
      required: false,
      default: false,
    },
    exportUrl: {
      type: String,
      required: false,
      default: null,
    },
    exportParams: {
      type: Object,
      required: false,
      default: null,
    },
    exportColumns: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      exportLoading: false,
    };
  },
  mounted() {
    if (this.addRoute) {
      // document.querySelector("body").
    }
  },
  methods: {
    handleRemoveFiltersClick(event) {
      this.$emit("clear-filters", event);
    },
    handleReloadClick(event) {
      this.$emit("reload", event);
    },
    handleAddClick(event) {
      if (this.addRoute === null || this.addRoute === "#") {
        event.preventDefault();
      }

      this.$emit("click:add", event);
    },
    handleDeleteClick(event) {
      if (this.deleteConfirmed) {
        this.$refs.confirmDelete.show("Silmek istediğinizden emin misiniz?");
      } else {
        this.handleDeleteConfirmed(event);
      }
    },
    handleDeleteConfirmed(event) {
      this.$emit("click:delete", event);
      this.$refs.confirmDelete.hide();
    },
    handleExportClick(fileType) {
      this.$emit("click:export", fileType);

      const params = this.exportParams();
      params.export = 1;
      if (this.exportColumns) {
        params.export_columns = JSON.stringify(this.exportColumns);
      }

      params.export_type = "ods";
      if (fileType) {
        params.export_type = fileType;
      }

      let exportUrl = this.exportUrl;

      if (exportUrl.constructor.name === "Function") {
        exportUrl = exportUrl();
      }

      this.exportLoading = true;

      this.$api
        .query(exportUrl, { params })
        .then((response) => {
          const anchor = document.createElement("a");
          anchor.href = response.data.data.url;
          anchor.target = "_blank";
          // anchor.download =
          //   response.data.data.title + "." + response.data.data.extension;
          anchor.click();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.exportLoading = false));
    },
  },
};
</script>
