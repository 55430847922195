<template>
  <rs-text-field
    :clearable="!disabled"
    :label="label"
    :readonly="disabled"
    :filled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
    type="date"
    :min="min"
    :max="max"
  />
</template>

<script>
export default {
  name: "Datepicker",
  props: {
    label: {
      required: false,
      type: String,
      default: "Tarih",
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    min: {
      required: false,
      type: String,
    },
    max: {
      required: false,
      type: String,
      default: "2099-12-12",
    },
  },
};
</script>
