<template>
  <a :title="title" :href="`mailto:${value}`" v-if="value">
    {{ value }}
  </a>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    title: {
      default: null,
      type: String,
      required: false,
    },
  },
};
</script>
