export default [
  {
    path: "/other-definitions/projects",
    name: "other-definitions.projects.list",
    component: () =>
      import("@/view/pages/definitions/projects/ProjectList.vue"),
  },
  {
    path: "/other-definitions/projects/create",
    name: "other-definitions.projects.create",
    component: () =>
      import("@/view/pages/definitions/projects/ProjectForm.vue"),
  },
  {
    path: "/other-definitions/projects/:id",
    name: "other-definitions.projects.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/projects/ProjectForm.vue"),
  },
  {
    path: "/other-definitions/projects/:id/edit",
    name: "other-definitions.projects.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/projects/ProjectForm.vue"),
  },
  {
    path: "/other-definitions/expense-types",
    name: "other-definitions.expense-types.list",
    component: () =>
      import("@/view/pages/definitions/expense-types/ExpenseTypeList.vue"),
  },
  {
    path: "/other-definitions/expense-types/create",
    name: "other-definitions.expense-types.create",
    component: () =>
      import("@/view/pages/definitions/expense-types/ExpenseTypeForm.vue"),
  },
  {
    path: "/other-definitions/expense-types/:id",
    name: "other-definitions.expense-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/expense-types/ExpenseTypeForm.vue"),
  },
  {
    path: "/other-definitions/expense-types/:id/edit",
    name: "other-definitions.expense-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/expense-types/ExpenseTypeForm.vue"),
  },
  {
    path: "/other-definitions/income-types",
    name: "other-definitions.income-types.list",
    component: () =>
      import("@/view/pages/definitions/income-types/IncomeTypeList.vue"),
  },
  {
    path: "/other-definitions/income-types/create",
    name: "other-definitions.income-types.create",
    component: () =>
      import("@/view/pages/definitions/income-types/IncomeTypeForm.vue"),
  },
  {
    path: "/other-definitions/income-types/:id",
    name: "other-definitions.income-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/income-types/IncomeTypeForm.vue"),
  },
  {
    path: "/other-definitions/income-types/:id/edit",
    name: "other-definitions.income-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/income-types/IncomeTypeForm.vue"),
  },
  {
    path: "/other-definitions/other-incomes",
    name: "other-definitions.other-incomes.list",
    component: () =>
      import("@/view/pages/definitions/other-incomes/IncomeTypeList.vue"),
  },
  {
    path: "/other-definitions/other-incomes/create",
    name: "other-definitions.other-incomes.create",
    component: () =>
      import("@/view/pages/definitions/other-incomes/IncomeTypeForm.vue"),
  },
  {
    path: "/other-definitions/other-incomes/:id",
    name: "other-definitions.other-incomes.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/other-incomes/IncomeTypeForm.vue"),
  },
  {
    path: "/other-definitions/other-incomes/:id/edit",
    name: "other-definitions.other-incomes.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/other-incomes/IncomeTypeForm.vue"),
  },
  {
    path: "/other-definitions/child-expense-types",
    name: "other-definitions.child-expense-types.list",
    component: () =>
      import(
        "@/view/pages/definitions/child-expense-types/ChildExpenseTypeList.vue"
      ),
  },
  {
    path: "/other-definitions/child-expense-types/create",
    name: "other-definitions.child-expense-types.create",
    props: (route) => ({ parent_id: route.query.parent_id }),
    component: () =>
      import(
        "@/view/pages/definitions/child-expense-types/ChildExpenseTypeForm.vue"
      ),
  },
  {
    path: "/other-definitions/child-expense-types/:parentId/create",
    name: "other-definitions.child-expense-types.parent-id.create",
    props: (route) => ({ parent_id: route.query.parent_id }),
    component: () =>
      import(
        "@/view/pages/definitions/child-expense-types/ChildExpenseTypeForm.vue"
      ),
  },
  {
    path: "/other-definitions/child-expense-types/:id/edit",
    name: "other-definitions.child-expense-types.parent-id.edit",
    props: (route) => ({
      parent_id: parseInt(route.query.parent_id),
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        "@/view/pages/definitions/child-expense-types/ChildExpenseTypeForm.vue"
      ),
  },
  {
    path: "/other-definitions/child-expense-types/:id",
    name: "other-definitions.child-expense-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import(
        "@/view/pages/definitions/child-expense-types/ChildExpenseTypeForm.vue"
      ),
  },
  {
    path: "/other-definitions/child-expense-types/:id/edit",
    name: "other-definitions.child-expense-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import(
        "@/view/pages/definitions/child-expense-types/ChildExpenseTypeForm.vue"
      ),
  },
  {
    path: "/other-definitions/fixtures/fixture-groups",
    name: "other-definitions.fixtures.fixture-groups.list",
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureGroupList.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixture-groups/create",
    name: "other-definitions.fixtures.fixture-groups.create",
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureGroupForm.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixture-groups/:id",
    name: "other-definitions.fixtures.fixture-groups.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureGroupForm.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixture-groups/:id/edit",
    name: "other-definitions.fixtures.fixture-groups.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureGroupForm.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixtures",
    name: "other-definitions.fixtures.fixtures.list",
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureList.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixtures/create",
    name: "other-definitions.fixtures.fixtures.create",
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureForm.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixtures/:id",
    name: "other-definitions.fixtures.fixtures.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureForm.vue"),
  },
  {
    path: "/other-definitions/fixtures/fixtures/:id/edit",
    name: "other-definitions.fixtures.fixtures.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/fixtures/FixtureForm.vue"),
  },
  {
    path: "/other-definitions/housing-income",
    name: "other-definitions.housing-income.list",
    component: () =>
      import("@/view/pages/definitions/housing-income/HousingIncomeList.vue"),
  },
  {
    path: "/other-definitions/payback-reasons",
    name: "other-definitions.payback-reasons.list",
    component: () =>
      import("@/view/pages/definitions/payback-reasons/PaybackReasonsList.vue"),
  },
  {
    path: "/other-definitions/payback-reasons/create",
    name: "other-definitions.payback-reasons.create",
    component: () =>
      import("@/view/pages/definitions/payback-reasons/PaybackReasonsForm.vue"),
  },
  {
    path: "/other-definitions/payback-reasons/:id",
    name: "other-definitions.payback-reasons.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/payback-reasons/PaybackReasonsForm.vue"),
  },
  {
    path: "/other-definitions/payback-reasons/:id/edit",
    name: "other-definitions.payback-reasons.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/payback-reasons/PaybackReasonsForm.vue"),
  },
  {
    path: "/other-definitions/types/contract-types",
    name: "other-definitions.types.contract-types.list",
    component: () =>
      import("@/view/pages/definitions/types/ContractTypeList.vue"),
  },
  {
    path: "/other-definitions/types/contract-types/create",
    name: "other-definitions.types.contract-types.create",
    component: () =>
      import("@/view/pages/definitions/types/ContractTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/contract-types/:id",
    name: "other-definitions.types.contract-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/types/ContractTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/contract-types/:id/edit",
    name: "other-definitions.types.contract-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/types/ContractTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/maintenance-types",
    name: "other-definitions.types.maintenance-types.list",
    component: () =>
      import("@/view/pages/definitions/types/MaintenanceTypeList.vue"),
  },
  {
    path: "/other-definitions/types/maintenance-types/create",
    name: "other-definitions.types.maintenance-types.create",
    component: () =>
      import("@/view/pages/definitions/types/MaintenanceTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/maintenance-types/:id",
    name: "other-definitions.types.maintenance-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/types/MaintenanceTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/maintenance-types/:id/edit",
    name: "other-definitions.types.maintenance-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/types/MaintenanceTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/cleaning-types",
    name: "other-definitions.types.cleaning-types.list",
    component: () =>
      import("@/view/pages/definitions/types/CleaningTypeList.vue"),
  },
  {
    path: "/other-definitions/types/cleaning-types/create",
    name: "other-definitions.types.cleaning-types.create",
    component: () =>
      import("@/view/pages/definitions/types/CleaningTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/cleaning-types/:id",
    name: "other-definitions.types.cleaning-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/types/CleaningTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/cleaning-types/:id/edit",
    name: "other-definitions.types.cleaning-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/types/CleaningTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/breakdown-types",
    name: "other-definitions.types.breakdown-types.list",
    component: () =>
      import("@/view/pages/definitions/types/BreakdownTypeList.vue"),
  },
  {
    path: "/other-definitions/types/breakdown-types/create",
    name: "other-definitions.types.breakdown-types.create",
    component: () =>
      import("@/view/pages/definitions/types/BreakdownTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/breakdown-types/:id",
    name: "other-definitions.types.breakdown-types.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/definitions/types/BreakdownTypeForm.vue"),
  },
  {
    path: "/other-definitions/types/breakdown-types/:id/edit",
    name: "other-definitions.types.breakdown-types.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/types/BreakdownTypeForm.vue"),
  },
  {
    path: "/other-definitions/cluster-phones",
    name: "other-definitions.cluster-phones.list",
    component: () =>
      import("@/view/pages/definitions/cluster-phones/ClusterPhonesList.vue"),
  },
  {
    path: "/other-definitions/cluster-phones/create",
    name: "other-definitions.cluster-phones.create",
    component: () =>
      import("@/view/pages/definitions/cluster-phones/ClusterPhonesForm.vue"),
  },
  {
    path: "/other-definitions/cluster-phones/:id/edit",
    name: "other-definitions.cluster-phones.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/cluster-phones/ClusterPhonesForm.vue"),
  },
  {
    path: "/other-definitions/maintenance",
    name: "other-definitions.maintenance.list",
    component: () =>
      import("@/view/pages/definitions/maintenances/MaintenanceList.vue"),
  },
  {
    path: "/other-definitions/maintenance/create",
    name: "other-definitions.maintenance.create",
    component: () =>
      import("@/view/pages/definitions/maintenances/MaintenanceForm.vue"),
  },
  {
    path: "/other-definitions/maintenance/:id/edit",
    name: "other-definitions.maintenance.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/definitions/maintenances/MaintenanceForm.vue"),
  },
  {
    path: "/other-definitions/house-integrations",
    name: "other-definitions.house-integrations.list",
    component: () =>
      import(
        "@/view/pages/definitions/house-integrations/HouseIntegrationList.vue"
      ),
  },
];
