<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    left
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        type="time"
        v-model="time"
        prepend-icon="mdi-clock"
        v-bind="Object.assign(attrs, $attrs)"
        v-on="Object.assign($listeners, on)"
        :label="$attrs.label"
        hide-details="auto"
      ></v-text-field>
    </template>
    <v-time-picker
      v-model="time"
      @change="save"
      v-bind="$attrs"
      v-on="$listeners"
      format="24hr"
    ></v-time-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
      default: null,
    },
  },
  data: () => ({
    time: null,
    menu: false,
  }),
  mounted() {
    this.$nextTick(() => {
      this.time = this.value;
    });
  },
  watch: {
    value(newValue) {
      this.time = newValue;
    },
  },
  methods: {
    save(time) {
      this.$refs.menu.save(time);
    },
  },
};
</script>
