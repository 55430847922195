<template>
  <v-overlay :z-index="zIndex" :light="true" :dark="false" :value="visible">
    <div class="side-panel" :class="'side-panel-' + size" ref="mainElement">
      <div class="side-panel-content">
        <div class="side-panel-header">
          <h3>
            <slot name="header"></slot>
          </h3>
        </div>
        <div class="side-panel-body" v-if="visible">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </v-overlay>
</template>

<script>
export default {
  props: {
    zIndex: {
      type: Number,
      required: false,
      default: 99,
    },
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "small",
    },
  },
  data() {
    return {
      isLoading: false,
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
      this.$emit("input", true);
      window.setTimeout(() => {
        this.$refs.mainElement.classList.add("active");
      }, 100);
    },
    hide() {
      this.visible = false;
      this.$emit("input", false);
    },
    handleCancelClick() {
      setTimeout(() => this.$emit("dismissed"), 300);
    },
  },
};
</script>
