<template>
  <v-data-table
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title=""
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        hide-edit
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.company_name="{ item }">
      <router-link
        :to="{
          name: 'definitions.companies.edit',
          params: {
            id: item.company_id,
          },
        }"
      >
        {{ item.company_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.cluster_count="{ item }">
      <router-link
        v-if="item.cluster_count"
        :to="{
          name: 'my-company.workers.edit',
          hash: '#clusters',
          params: {
            id: item.id,
          },
        }"
      >
        {{ item.cluster_count }}
      </router-link>
      <em v-else>Tümü</em>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.created_at="{ value }">
      <rs-table-cell-date show-time :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";
import { mapGetters } from "vuex";

export default {
  name: "AdminCompanyUserList",
  mixins: [hasDataTable],
  props: {
    userId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    userId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["roleList"]),
  },
  data() {
    return {
      options: {
        sortBy: ["company_name"],
        sortDesc: [false],
      },
      headers: [
        {
          text: "Şirket",
          value: "company_name",
          searchable: "text",
        },
        {
          text: "Rol",
          value: "role_name",
          searchable: "multiselect",
          searchValue: "role_ids",
          options: () => this.roleList,
          itemText: "display_name",
        },
        {
          text: "Pozisyon",
          value: "position_name",
          searchable: "text",
        },
        {
          text: "Durum",
          value: "is_active",
          searchable: "isActive",
          align: "center",
          width: "40px",
        },
        {
          text: "Yetkili TYA",
          value: "cluster_count",
          searchable: "number",
          align: "end",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.userId) {
        params.user_id = this.userId;
      }

      return this.$api
        .query("api-super-admin/company-users", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
