export default [
  {
    path: "/enforcements",
    name: "enforcements.list",
    component: () => import("@/view/pages/enforcements/EnforcementList.vue"),
  },
  {
    path: "/enforcements/create",
    name: "enforcements.create",
    component: () => import("@/view/pages/enforcements/EnforcementForm.vue"),
  },
  {
    path: "/enforcements/:id",
    name: "enforcements.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () => import("@/view/pages/enforcements/EnforcementForm.vue"),
  },
  {
    path: "/enforcements/:id/edit",
    name: "enforcements.edit",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/view/pages/enforcements/EnforcementForm.vue"),
  },
];
