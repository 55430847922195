<template>
  <span :title="title" v-if="date" :class="cssClass">
    <time
      :datetime="date.toISOString()"
      :title="showTime ? date.toLocaleString() : date.toLocaleDateString()"
    >
      <template v-if="showTime">
        {{ date.toLocaleString() }}
      </template>
      <template v-else>
        {{ date.toLocaleDateString() }}
      </template>
    </time>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      default: false,
    },
    title: {
      default: null,
      type: String,
      required: false,
    },
    cssClass: {
      default: null,
      required: false,
    },
    showTime: {
      default: false,
      type: Boolean,
      required: false,
    },
  },
  computed: {
    date() {
      if (this.value) {
        if (this.value.constructor.name === "Date") {
          return this.value;
        } else if (this.value.constructor.name === "String") {
          return new Date(this.value);
        }
      }

      return null;
    },
  },
};
</script>
