<template>
  <VSelect
    :items="blocks"
    item-text="name"
    item-value="id"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    :label="label"
  />
</template>

<script>
import { VSelect } from "vuetify/lib";
import { mapGetters } from "vuex";

export default {
  components: {
    VSelect,
  },
  props: {
    clusterId: {
      required: false,
      type: Number,
    },
    label: {
      required: false,
      type: String,
      default: "Blok seçin",
    },
  },
  watch: {
    clusterId: {
      handler(value) {
        // console.log(value);
        if (value) {
          this.loadOptions();
        } else {
          this.options = [];
        }
      },
    },
  },
  computed: {
    ...mapGetters(["blocks"]),
  },
  methods: {
    loadOptions() {
      //
    },
  },
};
</script>
