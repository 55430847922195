<template>
  <rs-select
    :label="label"
    :items="list || options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    custom-item
    @change="handleChange"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.customer_count }} Müşteri
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </rs-select>
</template>

<script>
export default {
  name: "SelectCustomerGroup",
  props: {
    // includePassive: {
    //   type: Boolean,
    //   required: false,
    //   default: false,
    // },
    label: {
      type: String,
      required: false,
      default: "Müşteri Grubu Seçin",
    },
    list: {
      type: Array,
      required: false,
    },
  },
  watch: {
    // includePassive() {
    //   this.loadList();
    // },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      if (this.list !== undefined) {
        return;
      }

      const params = {
        per_page: 1000,
        order_by: "name",
      };
      this.options = [];

      this.$api
        .query("api-super-admin/customer-groups", { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Customer groups not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
      this.$emit("change:item", null);
    },
  },
};
</script>
