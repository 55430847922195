import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import tr from "vuetify/lib/locale/tr";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { tr },
    current: "tr",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#5867dd",
        secondary: "#abcf39",
        accent: "#5d78ff",
        error: "#fd397a",
        info: "#5578eb",
        success: "#0abb87",
        warning: "#ffb822",
      },
    },
  },
});
