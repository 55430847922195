<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tedarikçi</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="6">
            <rs-select
              :items="providerTypeList"
              :rules="[rules.required]"
              :label="$t('labels.provider_type')"
              v-model="formData.provider_type_id"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="6">
            <rs-text-field
              :label="$t('labels.provider_name')"
              :rules="[rules.required, rules.maxLength(formData.name, 50)]"
              v-model="formData.name"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="12">
            <rs-text-field
              :label="$t('labels.provider_title')"
              :rules="[rules.required, rules.maxLength(formData.title, 100)]"
              v-model="formData.title"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>

          <v-col sm="6">
            <rs-select
              :items="fullExpenseTypeList"
              :rules="formData.bank_integration_type_id ? [rules.required] : []"
              :label="$t('labels.income_expense_type')"
              v-model="formData.expense_type_id"
              :readonly="disabled"
              :filled="disabled"
              :required="formData.bank_integration_type_id"
            />
          </v-col>

          <v-col sm="6">
            <rs-select
              :label="$t('labels.expense_kind')"
              :rules="[]"
              :items="childExpenseTypeList"
              v-model="formData.child_expense_type_id"
              :readonly="disabled"
              :filled="disabled"
              :disabled="!formData.expense_type_id"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "ClusterProviderFormBasic",
  mixins: [hasForm, hasPermissions],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "cluster",
      "clusterId",
      "fullExpenseTypeList",
      "providerTypeList",
    ]),
    childExpenseTypeList() {
      if (!this.formData.expense_type_id) {
        return [];
      }

      return ClusterService.getExpenseTypeChildren(
        this.formData.expense_type_id
      );
    },
  },
  data() {
    return {
      formData: {
        cluster_id: null,
        provider_type_id: null,
        name: null,
        title: null,
        expense_type_id: null,
        child_expense_type_id: null,
        bank_workflow_type_id: 0,
      },
      id: null,
      disabled: false,
      extraParams: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(id, disabled, extraParams) {
      this.formData = Object.assign(
        {
          cluster_id: null,
          provider_type_id: null,
          name: null,
          title: null,
          expense_type_id: null,
          child_expense_type_id: null,
          bank_workflow_type_id: 0,
        },
        extraParams || {}
      );

      this.originalData = {};
      this.extraParams = {};

      if (id) {
        this.id = id;
        this.disabled = true;
        this.load();
      }

      if (disabled) {
        this.disabled = true;
      }

      if (extraParams) {
        this.extraParams = extraParams;
      }

      this.$refs.sidePanel.show();
    },
    load() {
      if (this.isLoading || !this.id) return false;

      this.isLoading = true;
      this.selectedItems = [];

      this.$api
        .get("providers", this.id)
        .then((response) => {
          this.loadData(response);

          if (this.cluster.id !== response.data.data.cluster_id) {
            this.updateClusterId(response.data.data.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);
      const params = Object.assign({}, this.extraParams, formData);
      params.cluster_id = this.clusterId;

      this.isLoading = true;

      this.$api
        .post("cluster-providers", params)
        .then((response) => {
          this.$toast.success(this.$t("saved"));
          this.$emit("created", response.data.data);
          ClusterService.loadLawyerList();
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
