<template>
  <div
    class="wrapper-money"
    :class="[disabled ? 'wrapper-money-disabled' : '']"
  >
    <rs-text-field
      :label="label"
      :readonly="disabled || $attrs.readonly"
      :filled="disabled"
      v-bind="$attrs"
      v-on="$listeners"
      :hint="hint"
      suffix="₺"
      type="number"
      :clearable="!disabled && $attrs.readonly === undefined"
      step="0.01"
      @click="formatFix"
      ref="myInput"
      @blur="handleBlur($event)"
      @focus="formatFix"
    />
    <span
      class="formatted-text"
      v-bind:class="[isformatted ? '' : 'd-none']"
      v-text="formatted"
    ></span>
  </div>
</template>

<script>
export default {
  name: "MoneyField",
  data() {
    return {
      formatted: null,
      isformatted: true,
      ticker: null,
    };
  },
  props: {
    label: {
      required: false,
      type: String,
      default: "Tutar",
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hint: {
      required: false,
      type: String,
      default: "Tutar Giriniz",
    },
  },
  mounted() {
    if (
      this.$refs.myInput.value != undefined &&
      this.$refs.myInput.value != ""
    ) {
      var formatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      this.formatted = formatter.format(this.$refs.myInput.value);
    } else {
      this.isformatted = false;
    }

    this.ticker = window.setInterval(() => {
      if (this.isformatted) {
        this.handleBlur({ target: this.$refs.myInput });
      }
    }, 1000);
  },
  destroyed() {
    window.clearInterval(this.ticker);
  },
  methods: {
    handleBlur(e) {
      if (e.target.value !== "") {
        var formatter = new Intl.NumberFormat(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        this.formatted = formatter.format(e.target.value);
        this.isformatted = true;
      }
    },
    formatFix() {
      this.isformatted = false;
    },
  },
};
</script>
<style scoped>
.formatted-text {
  background-color: white;
  position: absolute;
  top: 40%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}
.wrapper-money {
  position: relative;
}

.wrapper-money-disabled .formatted-text {
  background-color: #eee;
  left: 12px;
  top: 50%;
}
</style>
<style>
.v-text-field__details {
  min-height: unset !important;
  height: 0em;
  overflow: visible !important;
}
</style>
