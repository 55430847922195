<template>
  <v-autocomplete
    :items="list"
    :loading="isLoading"
    :search-input.sync="search"
    :filter="() => true"
    item-text="resident"
    :item-value="(item) => item.id"
    :label="label"
    v-bind="$attrs"
    hide-details="auto"
    @input="handleSearchInputChange"
    @change="handleChange"
  >
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.id }} - {{ data.item.resident }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Kalan miktar:
              <rs-table-cell-number price :value="data.item.left_amount" />
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              İcra Tarihi: <rs-table-cell-date :value="data.item.issued_on" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { buildSearchParams } from "@/core/helpers";
import { debounce } from "@/core/plugins/debounce";

export default {
  props: {
    clusterId: {
      type: Number,
      required: true,
      default: null,
    },
    residentId: {
      type: Number,
      required: false,
      default: null,
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    label: {
      type: String,
      required: false,
      default: "Kişi seçin",
    },
  },
  watch: {
    search: debounce(function () {
      if (this.firstLoad) {
        this.firstLoad = false;
        return;
      }

      this.loadList();
    }, 250),
    clusterId() {
      this.loadList();
    },
    houseId() {
      this.loadList();
    },
    extraParams() {
      this.loadList();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  data() {
    return {
      list: [],
      isLoading: false,
      search: null,
      selectedName: null,
      selectedItem: {},
      firstLoad: null,
    };
  },
  methods: {
    loadList() {
      if (this.isLoading || !this.clusterId) {
        return;
      }

      if (
        this.search &&
        (this.search === this.selectedName ||
          this.search === this.selectedItem.name)
      ) {
        return;
      }

      this.isLoading = true;

      const params = buildSearchParams(Object.assign({}, this.extraParams));

      if (this.search) {
        params.query = this.search;
      }

      params.per_page = 1000;
      params.cluster_id = [this.clusterId];
      params.is_closed = 0;

      if (this.residentId) {
        params.resident_id = [this.residentId];
      }

      this.$api
        .query("enforcements", { params })
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error loading enforcement list", error);
        })
        .finally(() => {
          this.isLoading = false;
          if (this.firstLoad === null) {
            this.firstLoad = true;
            setTimeout(() => {
              this.firstLoad = false;
            }, 300);
          }
        });
    },
    handleChange(selectedId) {
      if (!selectedId) {
        this.selectedItem = {};
        this.$emit("change:option", { id: null, item: null });
        return false;
      }
      let selectedItem;

      for (const index in this.list) {
        if (this.list[index].id === selectedId) {
          selectedItem = Object.assign(this.list[index]);
          this.selectedItem = selectedItem;
          break;
        }
      }

      this.$emit("change:option", {
        id: selectedId,
        item: selectedItem,
      });
      this.$emit("input", selectedId);
      this.$emit("change", selectedId);

      this.selectedName = selectedItem.name;

      this.isLoading = true;
      this.$nextTick(() => {
        this.isLoading = false;
      });

      return false;
    },
    /**
     * Adds a new option to result list.
     * @param  {Object} item User Object
     * @return {Boolean}      True if added, false if already exists
     */
    pushOption(item) {
      for (const index in this.list) {
        if (this.list[index].id === item.id) {
          return false;
        }
      }
      this.list.push(item);

      return true;
    },
  },
};
</script>
