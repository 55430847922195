var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('v-card-title',[_c('h3',{staticClass:"card-label"},[(_vm.originalData.name)?_c('div',[_c('small',[_vm._v("Ürün")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.originalData.name))])]):_c('span',[_vm._v("Ürün Ekle")])]),_c('v-col',{staticClass:"text-right p-0"}),(!_vm.hideBackButton)?_c('div',{staticClass:"d-block w-100 mb-2"},[_c('rs-return-to')],1):_vm._e()],1),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab',{attrs:{"to":"#info","replace":""}},[_vm._v("Genel")])],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',{staticClass:"pa-4",attrs:{"value":"info"}},[_c('v-form',{ref:"form",attrs:{"readonly":_vm.disabled,"disabled":_vm.isLoading || _vm.disabled},on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":_vm.$t('labels.name'),"rules":[_vm.rules.required, _vm.rules.maxLength(_vm.formData.name, 50)],"hide-details":"auto"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-text-field',{attrs:{"label":"Fatura Kalemi Adı","rules":[
                  _vm.rules.required,
                  _vm.rules.maxLength(_vm.formData.invoice_description, 75),
                ],"hide-details":"auto"},model:{value:(_vm.formData.invoice_description),callback:function ($$v) {_vm.$set(_vm.formData, "invoice_description", $$v)},expression:"formData.invoice_description"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"label":"Aylık Tutar / BB","rules":[
                  _vm.rules.required,
                  _vm.rules.min(_vm.formData.monthly_price_per_house, 0.01),
                  _vm.rules.max(_vm.formData.monthly_price_per_house, 999999.99),
                ],"disabled":_vm.isLoading},model:{value:(_vm.formData.monthly_price_per_house),callback:function ($$v) {_vm.$set(_vm.formData, "monthly_price_per_house", $$v)},expression:"formData.monthly_price_per_house"}})],1),_c('v-col',{attrs:{"sm":"6"}},[_c('rs-money-field',{attrs:{"label":"Yıllık Tutar / BB","rules":[
                  _vm.rules.required,
                  _vm.rules.min(_vm.formData.yearly_price_per_house, 0.01),
                  _vm.rules.max(_vm.formData.yearly_price_per_house, 999999.99),
                ],"disabled":_vm.isLoading},model:{value:(_vm.formData.yearly_price_per_house),callback:function ($$v) {_vm.$set(_vm.formData, "yearly_price_per_house", $$v)},expression:"formData.yearly_price_per_house"}})],1),_c('v-col',{attrs:{"sm":"4"}},[_c('v-switch',{attrs:{"label":"Satılabilir","hide-details":"auto","persistent-hint":""},model:{value:(_vm.formData.is_available),callback:function ($$v) {_vm.$set(_vm.formData, "is_available", $$v)},expression:"formData.is_available"}})],1)],1),(!_vm.disabled)?_c('rs-form-buttons',{attrs:{"isLoading":_vm.isLoading,"hide-submit":_vm.hideSave},on:{"cancel":_vm.handleCancelClick,"submit":_vm.handleFormSubmit}}):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }