<script>
import { VTextField } from "vuetify/lib";

export default {
  extends: VTextField,
  mixins: [
    {
      props: {
        hideDetails: {
          default: "auto",
        },
      },
      mounted() {
        this.$nextTick(() => {
          if (this.$props.type === "tel") {
            this.$props.placeholder = "+902122002020";
          }
        });
      },
    },
  ],
};
</script>
