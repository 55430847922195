export default [
  {
    path: "/my-customer/billing",
    name: "my-customer.billing.list",
    component: () => import("@/view/pages/my-customer/billing/BillingList.vue"),
  },
  {
    path: "/my-customer/invoices/:id",
    name: "my-customer.invoices.show",
    props: (route) => ({ id: parseInt(route.params.id) }),
    component: () =>
      import("@/view/pages/my-customer/billing/CustomerInvoiceDetail.vue"),
  },
  // {
  //   path: "/admin/users/:id/edit",
  //   name: "admin.users.edit",
  //   props: (route) => ({ id: parseInt(route.params.id) }),
  //   component: () => import("@/view/pages/admin/users/UserForm.vue"),
  // },
];
