import { buildSearchParams, numberToLocaleFormat } from "@/core/helpers";
import { debounce } from "@/core/plugins/debounce";

export default {
  computed: {
    headersShown() {
      const headers = [];

      for (const index in this.headers) {
        if (
          this.headers[index].hide === undefined ||
          !this.headers[index].hide()
        ) {
          headers.push(this.headers[index]);
        }
      }

      return headers;
    },
  },
  mounted() {
    // TODO: Remove this after this class is applied to all components
    setTimeout(() => {
      for (const index in this.headers) {
        if (this.headers[index].value === "edit") {
          this.headers[index].cellClass = "p-0";
        }
      }
    }, 100);
  },
  data() {
    return {
      search: {
        query: this.$route.query.q ?? null,
        trigger: null,
        per_page:
          parseInt(this.$route.query.per_page) ||
          parseInt(window.localStorage.getItem("perPage")) ||
          20,
      },
      total: null,
      options: {
        page: this.$route.query.page ?? 1,
        sortBy: ["name"],
        sortDesc: [false],
      },
      list: [],
      isLoading: false,
      itemsPerPage:
        parseInt(this.$route.query.per_page) ||
        parseInt(window.localStorage.getItem("perPage")) ||
        20,
      selectedItems: [],
      footTotals: [],
      headers: [],
      dataTableAttrs: {
        fixedHeader: true,
        height: "auto",
        loadingText: "Yükleniyor",
        footerProps: {
          itemsPerPageOptions: [10, 20, 30, 50, 100, 200],
          disableItemsPerPage: false,
          showFirstLastPage: true,
        },
        showSelect: true,
        itemKey: "id",
        dense: true,
      },
    };
  },
  methods: {
    buildSearchParams,
    debounce,
    numberToLocaleFormat,
    loadListFromResponse(response) {
      this.list = response.data.data;

      if (response.data && response.data.meta) {
        this.total = response.data.meta.total;
        this.options.page = response.data.meta.current_page;
        this.itemsPerPage = response.data.meta.per_page;
        this.options.itemsPerPage = this.itemsPerPage;
        this.footTotals = response.data.meta.totals;
      }
    },
    handleError(error) {
      if (!error || !error.response || !error.response.status) {
        throw error;
      }

      if (error.response.status === 401) {
        this.$toast.error("Kullanıcı girişi yapmalısınız.");
      } else if (error.response.status === 403) {
        this.$toast.error("Bunu yapmaya yetkili değilsiniz.");
      } else if (error.response.status === 422) {
        for (const itemKey in error.response.data.errors) {
          const messageList = error.response.data.errors[itemKey];
          if (messageList.constructor.name === "String") {
            this.$toast.error(messageList);
          } else if (messageList.constructor.name === "Array") {
            for (const index in messageList) {
              this.$toast.error(messageList[index]);
            }
          }
        }
      } else if (error.response.status === 500) {
        this.$toast.error("Sunucu hatası. İşleminiz kaydedilmedi.");
      } else if (error.response.status === 503) {
        this.$toast.warning(
          "Sunucu bakım modunda. Lütfen daha sonra tekrar deneyin."
        );
      } else if (error.response.status === 404) {
        this.$toast.error("Öğe bulunamadı.");
      }
    },
    handleWarnings(response) {
      if (
        !response ||
        !response.data.data ||
        !response.data.data.meta ||
        !response.data.data.meta.warnings
      ) {
        return;
      }

      for (const i in response.data.data.meta.warnings) {
        this.$toast.warning(response.data.data.meta.warnings[i], {
          timeout: 10000,
        });
      }
    },
    resetTable() {
      // this.list = [];
      this.selectedItems = [];
      this.footTotals = [];
    },
    handleItemsPerPageUpdated(perPage) {
      window.localStorage.setItem("perPage", perPage);
    },
  },
};
