import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["permissionList", "roles"]),
  },
  methods: {
    can(permissionName) {
      if (this.hasRole("superadministrator")) {
        return true;
      }

      return this.permissionList.indexOf(permissionName) !== -1;
    },
    canOr(...permissionNames) {
      for (const index in permissionNames) {
        if (this.can(permissionNames[index])) {
          return true;
        }
      }

      return false;
    },
    hasRole(roleName) {
      return this.roles.indexOf(roleName) !== -1;
    },
  },
};
