import ApiService from "./api.service";
import {
  UPDATE_BANK_LIST,
  UPDATE_BANK_ACCOUNT_TYPE_LIST,
  UPDATE_BANK_ACTIVITY_STATUS_LIST,
  UPDATE_CITY_LIST,
  UPDATE_COLLECTING_TYPE_LIST,
  UPDATE_COUNTRY_LIST,
  UPDATE_PROVIDER_TYPE_LIST,
  UPDATE_BANK_INTEGRATION_TYPE_LIST,
  UPDATE_AUTO_ASSESSMENT_TIMING_TYPE_LIST,
  UPDATE_HOUSE_USER_TYPE_LIST,
  UPDATE_USER_COMMERCIAL_TYPE_LIST,
  UPDATE_NOTE_TYPE_LIST,
  UPDATE_NOTIFICATION_CHANNEL_LIST,
  UPDATE_NOTIFICATION_PROVIDER_LIST,
  UPDATE_NOTIFICATION_STATUS_LIST,
  UPDATE_ROLES,
  UPDATE_PAYBACK_REASONS_LIST,
  UPDATE_REMINDER_PERIOD_LIST,
  UPDATE_FIXTURE_STATUS_LIST,
  UPDATE_CARE_TARGET_LIST,
  UPDATE_CARE_STATUS_LIST,
  UPDATE_CARE_TYPE_LIST,
  UPDATE_PAYMENT_PROVIDER_LIST,
  UPDATE_BUDGET_DISTRIBUTION_TYPE_LIST,
} from "@/core/services/store/options.module";
import store from "@/core/services/store";

const OptionsService = {
  init() {
    this.getGenericList();

    this.getCityList();
    this.getCountryList();
  },

  getGenericList() {
    ApiService.get("types/generic")
      .then((response) => {
        if (response.data.data.notification_channel_list) {
          store.dispatch(
            UPDATE_NOTIFICATION_CHANNEL_LIST,
            response.data.data.notification_channel_list
          );
        }

        if (response.data.data.notification_provider_list) {
          store.dispatch(
            UPDATE_NOTIFICATION_PROVIDER_LIST,
            response.data.data.notification_provider_list
          );
        }

        if (response.data.data.notification_status_list) {
          store.dispatch(
            UPDATE_NOTIFICATION_STATUS_LIST,
            response.data.data.notification_status_list
          );
        }

        if (response.data.data.note_type_list) {
          store.dispatch(
            UPDATE_NOTE_TYPE_LIST,
            response.data.data.note_type_list
          );
        }

        if (response.data.data.fixture_status_list) {
          store.dispatch(
            UPDATE_FIXTURE_STATUS_LIST,
            response.data.data.fixture_status_list
          );
        }

        if (response.data.data.bank_list) {
          store.dispatch(UPDATE_BANK_LIST, response.data.data.bank_list);
        }

        if (response.data.data.budget_distribution_type_list) {
          store.dispatch(
            UPDATE_BUDGET_DISTRIBUTION_TYPE_LIST,
            response.data.data.budget_distribution_type_list
          );
        }

        if (response.data.data.bank_activity_status_list) {
          store.dispatch(
            UPDATE_BANK_ACTIVITY_STATUS_LIST,
            response.data.data.bank_activity_status_list
          );
        }

        if (response.data.data.collecting_type_list) {
          store.dispatch(
            UPDATE_COLLECTING_TYPE_LIST,
            response.data.data.collecting_type_list
          );
        }

        if (response.data.data.auto_assessment_timing_type_list) {
          store.dispatch(
            UPDATE_AUTO_ASSESSMENT_TIMING_TYPE_LIST,
            response.data.data.auto_assessment_timing_type_list
          );
        }

        if (response.data.data.bank_account_type_list) {
          store.dispatch(
            UPDATE_BANK_ACCOUNT_TYPE_LIST,
            response.data.data.bank_account_type_list
          );
        }

        if (response.data.data.provider_type_list) {
          store.dispatch(
            UPDATE_PROVIDER_TYPE_LIST,
            response.data.data.provider_type_list
          );
        }

        if (response.data.data.payback_reasons_list) {
          store.dispatch(
            UPDATE_PAYBACK_REASONS_LIST,
            response.data.data.payback_reasons_list
          );
        }

        if (response.data.data.reminder_period_list) {
          store.dispatch(
            UPDATE_REMINDER_PERIOD_LIST,
            response.data.data.reminder_period_list
          );
        }

        if (response.data.data.bank_integration_type_list) {
          store.dispatch(
            UPDATE_BANK_INTEGRATION_TYPE_LIST,
            response.data.data.bank_integration_type_list
          );
        }

        if (response.data.data.house_user_type_list) {
          store.dispatch(
            UPDATE_HOUSE_USER_TYPE_LIST,
            response.data.data.house_user_type_list
          );
        }

        if (response.data.data.user_commercial_type_list) {
          store.dispatch(
            UPDATE_USER_COMMERCIAL_TYPE_LIST,
            response.data.data.user_commercial_type_list
          );
        }

        if (response.data.data.care_target_list) {
          store.dispatch(
            UPDATE_CARE_TARGET_LIST,
            response.data.data.care_target_list
          );
        }

        if (response.data.data.care_status_list) {
          store.dispatch(
            UPDATE_CARE_STATUS_LIST,
            response.data.data.care_status_list
          );
        }

        if (response.data.data.care_type_list) {
          store.dispatch(
            UPDATE_CARE_TYPE_LIST,
            response.data.data.care_type_list
          );
        }

        if (response.data.data.payment_provider_list) {
          store.dispatch(
            UPDATE_PAYMENT_PROVIDER_LIST,
            response.data.data.payment_provider_list
          );
        }

        if (response.data.data.roles) {
          store.dispatch(UPDATE_ROLES, response.data.data.roles);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error("Could not retrieve generic list", error);
      });
  },

  getCityList() {
    const params = {
      location_type_id: 2,
      parent_id: 230,
    };

    ApiService.query("locations", { params })
      .then((response) => {
        store.dispatch(UPDATE_CITY_LIST, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error("Could not retrieve city list", error);
      });
  },

  getCountryList() {
    const params = {
      location_type_id: 1,
    };

    ApiService.query("locations", { params })
      .then((response) => {
        store.dispatch(UPDATE_COUNTRY_LIST, response.data.data);
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error("Could not retrieve country list", error);
      });
  },

  getDistrictList(cityId) {
    const params = {
      location_type_id: 3,
      parent_id: cityId,
    };

    return ApiService.query("locations", { params })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
  },
};

export default OptionsService;
