<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Arıza Türü</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit">
        <v-row dense>
          <v-col sm="12">
            <rs-text-field
              label="Adı"
              :rules="[rules.required, rules.maxLength(formData.name, 100)]"
              v-model="formData.name"
              :readonly="disabled || (id && !originalData.cluster_id)"
              :filled="disabled || (id && !originalData.cluster_id)"
            />
          </v-col>

          <v-col cols="12">
            <v-switch
              v-model="formData.is_active"
              :label="formData.is_active ? $t('active') : $t('passive')"
              hide-details="auto"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          hide-submit
          :is-loading="isLoading"
          @cancel="hide()"
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ClusterService from "@/core/services/cluster.service";
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  name: "BreakdownTypeBasicForm",
  mixins: [hasForm, hasPermissions],
  props: {
    house: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
  },
  data() {
    return {
      formData: {
        cluster_id: null,
        name: null,
        is_active: true,
      },
      id: null,
      disabled: false,
      extraParams: {},
    };
  },
  methods: {
    ...mapActions(["updateClusterId"]),
    show(id, disabled, extraParams) {
      this.formData = Object.assign(
        {
          cluster_id: null,
          name: null,
          is_active: true,
        },
        extraParams || {}
      );

      this.originalData = {};
      this.extraParams = {};

      if (id) {
        this.id = id;
        this.disabled = true;
        this.load();
      } else {
        this.formData.cluster_id = this.clusterId;
      }

      if (disabled) {
        this.disabled = true;
      }

      if (extraParams) {
        this.extraParams = extraParams;
      }

      this.$refs.sidePanel.show();
    },
    load() {
      if (this.isLoading || !this.id) return false;

      this.isLoading = true;

      return this.$api
        .query(`breakdown-types/${this.id}`)
        .then((response) => {
          this.loadData(response);

          if (this.cluster.id !== response.data.data.cluster_id) {
            this.updateClusterId(response.data.data.cluster_id);
          }
        })
        .catch((error) => {
          this.handleError(error);
          this.hide();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign(
        { cluster_id: this.clusterId },
        this.formData
      );
      const params = Object.assign({}, this.extraParams, formData);

      this.isLoading = true;

      this.$api
        .post(`clusters/${this.clusterId}/breakdown-types`, params)
        .then((response) => {
          this.$toast.success(this.$t("saved"));
          this.$emit("created", response.data.data);
          ClusterService.loadExpenseTypeList();
          this.$nextTick(() => this.hide());
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
