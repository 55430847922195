<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    @change="handleChange"
    custom-item
    :loading="isLoading"
  >
    <template v-slot:item="data">
      <v-list-item-content v-if="!admin">
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <!-- {{ getExpenseName(data.item) }} -->
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-content v-else>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          <template v-if="data.item.customer_name">
            <strong>{{ data.item.customer_name }}</strong> |
          </template>
          <template v-else><em>Müşteri yok</em> | </template>
          {{ data.item.is_active ? "Aktif" : "Pasif" }} |
          {{ data.item.house_count }} BB | VKN/TCKN: {{ data.item.tax_number }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </rs-select>
</template>

<script>
import { mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";

export default {
  name: "SelectCluster",
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "TYA Seçin",
    },
    searchParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    admin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mixins: [hasPermissions],
  computed: {
    ...mapGetters(["clusterId"]),
  },
  watch: {
    companyId() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      const params = Object.assign(
        { per_page: 1000, order_by: "name", order: "asc" },
        this.searchParams
      );
      if (this.companyId) {
        params.company_id = this.companyId;
      }
      this.options = [];
      this.isLoading = true;

      let url = "clusters";

      if (this.admin) {
        url = "api-super-admin/clusters";
      }

      this.$api
        .query(url, { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Clusters not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
      this.$emit("change:item", null);
    },
  },
};
</script>
