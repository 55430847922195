import Vue from "vue";

import ActionButton from "./ActionButton";
import ConfirmDialog from "./ConfirmDialog";
import DeleteButton from "./DeleteButton";
import ReturnTo from "./ReturnTo";
import SidePanel from "./SidePanel";

Vue.component("rs-action", ActionButton);
Vue.component("rs-action-delete", DeleteButton);
Vue.component("rs-confirm", ConfirmDialog);
Vue.component("rs-return-to", ReturnTo);
Vue.component("rs-side-panel", SidePanel);
