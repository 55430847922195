export default [
  {
    path: "/assessments/all",
    name: "assessments.all.list",
    component: () => import("@/view/pages/assessments/all/AssessmentList.vue"),
  },
  {
    path: "/assessments/general",
    name: "assessments.general.list",
    component: () =>
      import("@/view/pages/assessments/general/AssessmentList.vue"),
  },
  {
    path: "/assessments/general/create",
    name: "assessments.general.create",
    component: () =>
      import("@/view/pages/assessments/general/AssessmentForm.vue"),
  },
  {
    path: "/assessments/general/:id",
    name: "assessments.general.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/assessments/general/AssessmentForm.vue"),
  },
  {
    path: "/assessments/general/:id/edit",
    name: "assessments.general.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/assessments/general/AssessmentForm.vue"),
  },
  {
    path: "/assessments/free",
    name: "assessments.free.list",
    component: () => import("@/view/pages/assessments/free/AssessmentList.vue"),
  },
  {
    path: "/assessments/free/create",
    name: "assessments.free.create",
    component: () => import("@/view/pages/assessments/free/AssessmentForm.vue"),
  },
  {
    path: "/assessments/free/:id",
    name: "assessments.free.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () => import("@/view/pages/assessments/free/AssessmentForm.vue"),
  },
  {
    path: "/assessments/free/:id/edit",
    name: "assessments.free.edit",
    props: (route) => ({ id: route.params.id }),
    component: () => import("@/view/pages/assessments/free/AssessmentForm.vue"),
  },
  {
    path: "/assessments/house-type",
    name: "assessments.house-type.list",
    component: () =>
      import("@/view/pages/assessments/house-type/AssessmentList.vue"),
  },
  {
    path: "/assessments/house-type/create",
    name: "assessments.house-type.create",
    component: () =>
      import("@/view/pages/assessments/house-type/AssessmentForm.vue"),
  },
  {
    path: "/assessments/house-type/:id",
    name: "assessments.house-type.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/assessments/house-type/AssessmentForm.vue"),
  },
  {
    path: "/assessments/house-type/:id/edit",
    name: "assessments.house-type.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/assessments/house-type/AssessmentForm.vue"),
  },
  {
    path: "/assessments/transfer-due",
    name: "assessments.transfer-due",
    component: () => import("@/view/pages/assessments/TransferDueForm.vue"),
  },
  {
    path: "/assessments/parametric",
    name: "assessments.parametric.list",
    component: () =>
      import("@/view/pages/assessments/parametric/AssessmentList.vue"),
  },
  {
    path: "/assessments/parametric/create",
    name: "assessments.parametric.create",
    component: () =>
      import("@/view/pages/assessments/parametric/AssessmentForm.vue"),
  },
  {
    path: "/assessments/parametric/:id",
    name: "assessments.parametric.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/assessments/parametric/AssessmentForm.vue"),
  },
  {
    path: "/assessments/parametric/:id/edit",
    name: "assessments.parametric.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/assessments/parametric/AssessmentForm.vue"),
  },
];
