/* eslint-disable */

import Vue from "vue";
import Router from "vue-router";

import otherDefinitions from "./router-other-definitions";
import assessments from "./router-assessments";
import income from "./router-income";
import expense from "./router-expense";
import activities from "./router-activities";
import enforcements from "./router-enforcement";
import company from "./router-company";
import communication from "./router-communication";
import customers from "./router-customers";
import analysis from "./router-analysis";
import reports from "./router-reports";
import actionLogs from "./router-action-logs";
import budget from "./router-budget";
import admin from "./router-admin";


Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [{
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [{
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/view/pages/Dashboard.vue"),
    },
    {
      path: "/definitions/companies",
      name: "definitions.companies.list",
      component: () =>
        import("@/view/pages/companies/CompanyList.vue"),
    },
    {
      path: "/definitions/companies/create",
      name: "definitions.companies.create",
      component: () =>
        import("@/view/pages/companies/CompanyForm.vue"),
    },
    {
      path: "/definitions/companies/:id/edit",
      name: "definitions.companies.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import("@/view/pages/companies/CompanyForm.vue"),
    },
    {
      path: "/definitions/companies/:id",
      name: "definitions.companies.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import("@/view/pages/companies/CompanyForm.vue"),
    },
    {
      path: "/definitions/clusters",
      name: "definitions.clusters.list",
      component: () =>
        import(
          "@/view/pages/definitions/cluster/ClusterList.vue"
        ),
    },
    {
      path: "/definitions/clusters/create",
      name: "definitions.clusters.create",
      component: () =>
        import(
          "@/view/pages/definitions/cluster/ClusterForm.vue"
        ),
    },
    {
      path: "/definitions/clusters/:id/edit",
      name: "definitions.clusters.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import(
          "@/view/pages/definitions/cluster/ClusterForm.vue"
        ),
    },
    {
      path: "/definitions/clusters/:id",
      name: "definitions.clusters.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import(
          "@/view/pages/definitions/cluster/ClusterForm.vue"
        ),
    },
    {
      path: "/definitions/houses",
      name: "definitions.houses.list",
      component: () =>
        import("@/view/pages/definitions/housing/HouseList.vue"),
    },
    {
      path: "/definitions/houses/create",
      name: "definitions.houses.create",
      component: () =>
        import("@/view/pages/definitions/housing/HouseForm.vue"),
    },
    {
      path: "/definitions/houses/:id/edit",
      name: "definitions.houses.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import("@/view/pages/definitions/housing/HouseForm.vue"),
    },
    {
      path: "/definitions/houses/:id",
      name: "definitions.houses.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import("@/view/pages/definitions/housing/HouseForm.vue"),
    },
    {
      path: "/definitions/bank-accounts",
      name: "definitions.bank-accounts.list",
      component: () =>
        import(
          "@/view/pages/definitions/bank-accounts/BankAccountList.vue"
        ),
    },
    {
      path: "/definitions/bank-accounts/create",
      name: "definitions.bank-accounts.create",
      component: () =>
        import(
          "@/view/pages/definitions/bank-accounts/BankAccountForm.vue"
        ),
    },
    {
      path: "/definitions/bank-accounts/:id",
      name: "definitions.bank-accounts.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import(
          "@/view/pages/definitions/bank-accounts/BankAccountForm.vue"
        ),
    },
    {
      path: "/definitions/bank-accounts/:id/edit",
      name: "definitions.bank-accounts.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import(
          "@/view/pages/definitions/bank-accounts/BankAccountForm.vue"
        ),
    },
    {
      path: "/definitions/cluster-providers",
      name: "definitions.cluster-providers.list",
      component: () =>
        import(
          "@/view/pages/definitions/cluster-providers/ClusterProviderList.vue"
        ),
    },
    {
      path: "/definitions/cluster-providers/create",
      name: "definitions.cluster-providers.create",
      component: () =>
        import(
          "@/view/pages/definitions/cluster-providers/ClusterProviderForm.vue"
        ),
    },
    {
      path: "/definitions/cluster-providers/:id",
      name: "definitions.cluster-providers.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import(
          "@/view/pages/definitions/cluster-providers/ClusterProviderForm.vue"
        ),
    },
    {
      path: "/definitions/cluster-providers/:id/edit",
      name: "definitions.cluster-providers.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import(
          "@/view/pages/definitions/cluster-providers/ClusterProviderForm.vue"
        ),
    },
    {
      path: "/definitions/residents",
      name: "definitions.residents.list",
      component: () =>
        import("@/view/pages/definitions/users/UserList.vue"),
    },
    {
      path: "/definitions/residents/create",
      name: "definitions.residents.create",
      component: () =>
        import("@/view/pages/definitions/users/UserForm.vue"),
    },
    {
      path: "/definitions/residents/:id",
      name: "definitions.residents.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import("@/view/pages/definitions/users/UserForm.vue"),
    },
    {
      path: "/definitions/residents/:id/edit",
      name: "definitions.residents.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import("@/view/pages/definitions/users/UserForm.vue"),
    },
    {
      path: "/definitions/periodical-assessments/create/:budgetId?",
      name: "definitions.periodical-assessments.create",
      props: (route) => ({ budgetId: route.params.budgetId }),
      component: () =>
        import("@/view/pages/definitions/periodic/PeriodicForm.vue"),
    },
    {
      path: "/definitions/periodical-assessments",
      name: "definitions.periodical-assessments.list",
      component: () =>
        import("@/view/pages/definitions/periodic/PeriodicList.vue"),
    },
    {
      path: "/definitions/periodical-assessments/:id",
      name: "definitions.periodical-assessments.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import("@/view/pages/definitions/periodic/PeriodicForm.vue"),
    },
    {
      path: "/definitions/periodical-assessments/:id/edit",
      name: "definitions.periodical-assessments.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import("@/view/pages/definitions/periodic/PeriodicForm.vue"),
    },
    {
      path: "/definitions/safes",
      name: "definitions.safes.list",
      component: () =>
        import("@/view/pages/definitions/safes/SafeList.vue"),
    },
    {
      path: "/definitions/safes/create",
      name: "definitions.safes.create",
      component: () =>
        import("@/view/pages/definitions/safes/SafeForm.vue"),
    },
    {
      path: "/definitions/safes/:id",
      name: "definitions.safes.show",
      props: (route) => ({ id: route.params.id, disabled: true }),
      component: () =>
        import("@/view/pages/definitions/safes/SafeForm.vue"),
    },
    {
      path: "/definitions/safes/:id/edit",
      name: "definitions.safes.edit",
      props: (route) => ({ id: route.params.id }),
      component: () =>
        import("@/view/pages/definitions/safes/SafeForm.vue"),
    },

    ...otherDefinitions,
    ...assessments,
    ...income,
    ...expense,
    ...activities,
    ...enforcements,
    ...company,
    ...communication,
    ...customers,
    ...analysis,
    ...reports,
    ...actionLogs,
    ...budget,
    ...admin,
      // {
      //   path: "/profile",
      //   name: "profile",
      //   component: () =>
      //     import("@/view/pages/profile/Profile.vue"),
      //   children: [{
      //       path: "profile-1",
      //       name: "profile-1",
      //       component: () =>
      //         import("@/view/pages/profile/Profile-1.vue"),
      //     },
      //     {
      //       path: "profile-2",
      //       name: "profile-2",
      //       component: () =>
      //         import("@/view/pages/profile/Profile-2.vue"),
      //     },
      //     {
      //       path: "profile-3",
      //       name: "profile-3",
      //       component: () =>
      //         import("@/view/pages/profile/Profile-3.vue"),
      //     },
      //     {
      //       path: "profile-4",
      //       name: "profile-4",
      //       component: () =>
      //         import("@/view/pages/profile/Profile-4.vue"),
      //     },
      //   ],
      // },
    ],
  },
  // {
  //   path: "/custom-error",
  //   name: "error",
  //   component: () =>
  //     import("@/view/pages/error/Error.vue"),
  //   children: [{
  //       path: "error-1",
  //       name: "error-1",
  //       component: () =>
  //         import("@/view/pages/error/Error-1.vue"),
  //     },
  //     {
  //       path: "error-2",
  //       name: "error-2",
  //       component: () =>
  //         import("@/view/pages/error/Error-2.vue"),
  //     },
  //     {
  //       path: "error-3",
  //       name: "error-3",
  //       component: () =>
  //         import("@/view/pages/error/Error-3.vue"),
  //     },
  //     {
  //       path: "error-4",
  //       name: "error-4",
  //       component: () =>
  //         import("@/view/pages/error/Error-4.vue"),
  //     },
  //     {
  //       path: "error-5",
  //       name: "error-5",
  //       component: () =>
  //         import("@/view/pages/error/Error-5.vue"),
  //     },
  //     {
  //       path: "error-6",
  //       name: "error-6",
  //       component: () =>
  //         import("@/view/pages/error/Error-6.vue"),
  //     },
  //   ],
  // },
  {
    path: "/",
    component: () =>
      import("@/view/pages/auth/login_pages/Login-1"),
    children: [{
      name: "login",
      path: "/login",
      component: () =>
        import("@/view/pages/auth/login_pages/Login-1"),
    },
    {
      name: "register",
      path: "/register",
      component: () =>
        import("@/view/pages/auth/login_pages/Login-1"),
    },
    ],
  },
  {
    name: "consent",
    path: "/consent",
    component: () => import("@/view/pages/Consent"),
  },
  {
    name: "mobile-app",
    path: "/mobile-app",
    component: () => import("@/view/pages/MobileAppLink"),
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () =>
      import("@/view/pages/error/Error-1.vue"),
  },
  ],
});
