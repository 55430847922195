<template>
  <v-autocomplete
    :items="list"
    :loading="isLoading"
    :search-input.sync="search"
    :filter="() => true"
    item-text="name"
    item-value="id"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    @change="handleChange"
    @keydown="handleKeyPress"
  ></v-autocomplete>
</template>

<script>
export default {
  /**
   * @deprecated Not in use anywhere 2023-10-09 Ege
   */
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "TYA Seçin",
    },
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  watch: {
    companyId() {
      this.loadList();
    },
  },
  mounted() {
    this.loadList();
  },
  data() {
    return {
      list: [],
      isLoading: false,
      search: null,
    };
  },
  methods: {
    handleKeyPress() {
      setTimeout(() => {
        this.loadList();
      }, 250);
    },
    loadList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const params = Object.assign({}, this.extraParams);
      if (this.companyId !== null) {
        params.company_id = this.companyId;
      }

      if (this.search) {
        params.query = this.search;
      }

      this.$api
        .query("clusters", { params })
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error loading housing cluster list", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleChange(selectedId) {
      if (!selectedId) {
        this.$emit("change:option", { id: null, item: null });
        return false;
      }
      let selectedItem;

      for (const index in this.list) {
        if (this.list[index].id === selectedId) {
          selectedItem = Object.assign(this.list[index]);
          break;
        }
      }

      this.$emit("change:option", {
        id: selectedId,
        item: selectedItem,
      });

      this.isLoading = true;
      this.$nextTick(() => {
        this.isLoading = false;
      });

      return false;
    },
    /**
     * Adds a new option to result list.
     * @param  {Object} item Cluster Object
     * @return {Boolean}      True if added, false if already exists
     */
    pushOption(item) {
      for (const index in this.list) {
        if (this.list[index].id === item.id) {
          return false;
        }
      }
      this.list.push(item);

      return true;
    },
  },
};
</script>
