<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Tahsilat Eşleştir</template>
    <template slot="body">
      <v-form ref="form" @submit.prevent="handleFormSubmit" v-if="bankActivity">
        <v-row dense>
          <v-col sm="6">
            <rs-text-field
              label="İşlem Tarihi"
              :value="new Date(bankActivity.activity_on).toLocaleDateString()"
              readonly
              filled
            />
          </v-col>

          <v-col sm="6">
            <rs-money-field
              label="İşlem Tutar"
              :value="bankActivity.amount"
              :disabled="true"
            />
          </v-col>

          <v-col sm="6">
            <rs-select-customer
              v-model="formData.customer_id"
              :rules="[rules.required]"
              :readonly="disabled"
              :filled="disabled"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col v-if="bankActivity && bankActivity.identity_number">
            <rs-text-field
              label="TC Kimlik No"
              :value="bankActivity.identity_number"
              readonly
              filled
            />
          </v-col>
          <v-col v-if="bankActivity && bankActivity.tax_number">
            <rs-text-field
              label="Vergi Kimlik No"
              :value="bankActivity.tax_number"
              readonly
              filled
            />
          </v-col>
          <v-col v-if="bankActivity && bankActivity.iban">
            <rs-text-field
              label="IBAN"
              :value="bankActivity.iban"
              readonly
              filled
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          :is-loading="isLoading"
          @cancel="hide()"
          hide-save-close
          save-label="Eşleştir"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm } from "@/view/mixins";

export default {
  name: "BankTransactionToPaymentModal",
  mixins: [hasForm],
  data() {
    return {
      bankActivity: null,
      formData: {
        customer_id: null,
        description: null,
      },
    };
  },
  methods: {
    show(bankActivity) {
      this.bankActivity = bankActivity;

      this.formData = {
        customer_id: null,
        description: bankActivity.description,
      };

      this.$refs.sidePanel.show();
    },
    hide() {
      this.bankActivity = null;
      this.$refs.sidePanel.hide();
    },
    handleCancelClick() {
      this.hide();
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const formData = Object.assign({}, this.formData);
      this.isLoading = true;

      this.$api
        .put(
          `api-super-admin/bank-activities/${this.bankActivity.reference_no}`,
          formData
        )
        .then(() => {
          this.$toast.success("Kaydedildi.");
          this.$emit("saved");
          this.hide();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
