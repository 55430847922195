<template>
  <v-autocomplete
    :items="list"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="name"
    :item-value="itemValue"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    @change="handleChange"
  >
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content>{{ data.item }}</v-list-item-content>
      </template>
      <template v-else>
        <template v-if="data.item.roles">
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ data.item.roles }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>{{ data.item.name }}</v-list-item-content>
        </template>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { buildSearchParams } from "@/core/helpers";
import { debounce } from "@/core/plugins/debounce";

export default {
  props: {
    extraParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    itemValue: {
      type: String,
      required: false,
      default: "id",
    },
    label: {
      type: String,
      required: false,
      default: "Kullanıcı seçin",
    },
  },
  watch: {
    search: debounce(function () {
      this.loadList();
    }, 250),
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  data() {
    return {
      list: [],
      isLoading: false,
      search: null,
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      const params = buildSearchParams(Object.assign({}, this.extraParams));
      params.order_by = "name";
      params.order = "asc";

      if (this.search) {
        params.query = this.search;
      }

      this.$api
        .query("api-super-admin/users", { params })
        .then((response) => {
          this.list = response.data.data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error("Error loading user list", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleChange(selectedId) {
      if (!selectedId) {
        this.$emit("change:option", { id: null, item: null });
        this.search = null;
        return false;
      }
      let selectedItem;

      for (const index in this.list) {
        if (this.list[index][this.itemValue] === selectedId) {
          selectedItem = Object.assign(this.list[index]);
          break;
        }
      }

      this.$emit("change:option", {
        id: selectedId,
        item: selectedItem,
      });

      this.isLoading = true;
      this.$nextTick(() => {
        this.isLoading = false;
      });

      return false;
    },
    /**
     * Adds a new option to result list.
     * @param  {Object} item User Object
     * @return {Boolean}      True if added, false if already exists
     */
    pushOption(item) {
      for (const index in this.list) {
        if (this.list[index][this.itemValue] === item[this.itemValue]) {
          return false;
        }
      }
      this.list.push(item);

      return true;
    },
  },
};
</script>
