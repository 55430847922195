<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    custom-item
    @change="handleChange"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.title }} | {{ data.item.tax_number }} |
          {{ data.item.cluster_count }} TYA
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </rs-select>
</template>

<script>
export default {
  name: "SelectCustomer",
  props: {
    includePassive: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "Müşteri Seçin",
    },
  },
  watch: {
    includePassive() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
  methods: {
    loadList() {
      const params = {
        is_active: this.includePassive ? undefined : true,
        per_page: 1000,
        order_by: "name",
      };
      this.options = [];

      this.$api
        .query("api-super-admin/customers", { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Customers not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
      this.$emit("change:item", null);
    },
  },
};
</script>
