<template>
  <rs-select
    :label="label"
    :items="bankSafeOptions"
    v-model="selectedCollectingPlace"
    hide-details="auto"
    @input="handleChange"
    v-bind="$attrs"
    v-on="$listeners"
    item-value="uniqueId"
    @click:clear.prevent="handleClickClear"
    custom-item
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.type === "safe" ? "Kasa" : "Banka Hesabı" }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </rs-select>
</template>

<script>
// import { VSelect } from "vuetify/lib";
import { mapGetters } from "vuex";

export default {
  props: {
    clusterId: {
      type: Number,
      required: false,
      default: null,
    },
    bankAccountId: {
      type: Number,
      required: false,
      default: null,
    },
    safeId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Tahsilat Yeri",
    },
    canCollect: {
      type: Boolean,
      required: false,
      default: false,
    },
    canPay: {
      type: Boolean,
      required: false,
      default: false,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    // VSelect,
  },
  computed: {
    ...mapGetters(["cluster"]),
  },
  watch: {
    cluster() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
    bankAccountId(newValue) {
      this.selectedCollectingPlace = "bank-account-" + newValue;
    },
    safeId(newValue) {
      this.selectedCollectingPlace = "safe-" + newValue;
    },
    canCollect() {
      this.loadList();
    },
    canPay() {
      this.loadList();
    },
  },
  data() {
    return {
      bankSafeOptions: [],
      selectedCollectingPlace: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.cluster) {
        this.loadList();
      }

      if (this.bankAccountId) {
        this.selectedCollectingPlace = "bank-account-" + this.bankAccountId;
      } else if (this.safeId) {
        this.selectedCollectingPlace = "safe-" + this.safeId;
      }
    });
  },
  methods: {
    loadList() {
      const clusterId = this.clusterId || this.cluster.id;
      const params = { cluster_id: clusterId };
      this.bankSafeOptions = [];

      if (this.canCollect) {
        params.can_collect_money = 1;
      }

      if (this.canPay) {
        params.can_receive_bills = 1;
      }

      if (this.isActive) {
        params.is_active = 1;
      }

      this.$api
        .query("bank-accounts", { params })
        .then((response) => {
          if (response.data.data) {
            const list = response.data.data.map((item) => {
              item.type = "bank-account";
              item.uniqueId = item.type + "-" + item.id;
              return item;
            });

            this.bankSafeOptions = this.bankSafeOptions.concat(list);
          } else {
            throw new Error("Bank accounts not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });

      this.$api
        .query("safes", { params })
        .then((response) => {
          if (response.data.data) {
            const list = response.data.data.map((item) => {
              item.type = "safe";
              item.uniqueId = item.type + "-" + item.id;
              return item;
            });

            this.bankSafeOptions = this.bankSafeOptions.concat(list);
          } else {
            throw new Error("Safes not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    handleChange(selectedUniqueId) {
      let selectedItem;

      this.bankSafeOptions.forEach((item) => {
        if (item.uniqueId === selectedUniqueId) {
          selectedItem = item;
          return;
        }
      });

      if (selectedItem) {
        if (selectedItem.type === "bank-account") {
          this.$emit("update:bank-account-id", selectedItem.id);
          this.$emit("update:safe-id", null);
        } else {
          this.$emit("update:safe-id", selectedItem.id);
          this.$emit("update:bank-account-id", null);
        }
      } else if (this.safeId) {
        this.$emit("update:safe-id", null);
      } else if (this.bankAccountId) {
        this.$emit("update:bank-account-id", null);
      }
    },
    handleClickClear() {
      this.$emit("update:safe-id", null);
      this.$emit("update:bank-account-id", null);
    },
  },
};
</script>
