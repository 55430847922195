<template>
  <rs-select
    :label="label"
    :items="options"
    v-bind="$attrs"
    v-on="$listeners"
    hide-details="auto"
    @change="handleChange"
    custom-item
    :loading="isLoading"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ getExpenseName(data.item) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </rs-select>
</template>

<script>
import { mapGetters } from "vuex";

import { hasPermissions } from "@/view/mixins";

export default {
  props: {
    companyId: {
      type: Number,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: "Şirket Tedarikçisi",
    },
    searchParams: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  mixins: [hasPermissions],
  computed: {
    ...mapGetters(["cluster", "expenseTypeList"]),
  },
  watch: {
    cluster() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      options: [],
      isLoading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.cluster) {
        this.loadList();
      }
    });
  },
  methods: {
    loadList() {
      const companyId = this.companyId || this.company.id;
      if (!companyId) {
        return false;
      }

      const params = Object.assign(
        { per_page: 1000, order_by: "name", order: "asc" },
        this.searchParams
      );
      this.options = [];
      this.isLoading = true;
      params.provides_id = companyId;

      this.$api
        .query("company-providers", { params })
        .then((response) => {
          if (response.data.data) {
            this.options = response.data.data;
          } else {
            throw new Error("Providers not retrieved");
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        })
        .finally(() => (this.isLoading = false));
    },
    getExpenseName(provider) {
      for (const index in this.expenseTypeList) {
        if (this.expenseTypeList[index].id === provider.expense_type_id) {
          return this.expenseTypeList[index].name;
        }
      }

      return null;
    },
    handleChange(id) {
      for (const index in this.options) {
        if (id === this.options[index].id) {
          this.$emit("change:item", this.options[index]);
          return;
        }
      }
    },
  },
};
</script>
