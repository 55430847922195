import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import qs from "qs";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL =
      window.location.hostname === "localhost"
        ? "http://localhost:8000/"
        : `https://${window.location.hostname.replace("web", "api")}/`;

    Vue.axios.defaults.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: "brackets" });
    };
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    Vue.axios.defaults.headers.common["RS-Web-Page"] = window.location.href;
    Vue.axios.defaults.headers.common["RS-Cluster"] =
      window.localStorage.getItem("clusterId");
    return Vue.axios.get(resource, params);
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    Vue.axios.defaults.headers.common["RS-Web-Page"] = window.location.href;
    Vue.axios.defaults.headers.common["RS-Cluster"] =
      window.localStorage.getItem("clusterId");
    return Vue.axios.get(`${resource}/${slug}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {*}
   */
  post(resource, params, config) {
    Vue.axios.defaults.headers.common["RS-Web-Page"] = window.location.href;
    Vue.axios.defaults.headers.common["RS-Cluster"] =
      window.localStorage.getItem("clusterId");
    return Vue.axios.post(`${resource}`, params, config);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    Vue.axios.defaults.headers.common["RS-Web-Page"] = window.location.href;
    Vue.axios.defaults.headers.common["RS-Cluster"] =
      window.localStorage.getItem("clusterId");
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    Vue.axios.defaults.headers.common["RS-Web-Page"] = window.location.href;
    Vue.axios.defaults.headers.common["RS-Cluster"] =
      window.localStorage.getItem("clusterId");
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, data) {
    Vue.axios.defaults.headers.common["RS-Web-Page"] = window.location.href;
    Vue.axios.defaults.headers.common["RS-Cluster"] =
      window.localStorage.getItem("clusterId");
    return Vue.axios.delete(resource, { data });
  },
};

export default ApiService;
