<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <div v-if="originalData.name">
          <small>Ürün</small><br />
          <span>{{ originalData.name }}</span>
        </div>
        <span v-else>Ürün Ekle</span>
      </h3>
      <v-col class="text-right p-0">
        <!-- <rs-action
          @click="showActionLogs"
          v-if="id"
          icon
          title="Düzenleme geçmişi"
        >
          <v-icon>mdi-information-variant</v-icon>
        </rs-action> -->
      </v-col>
      <div class="d-block w-100 mb-2" v-if="!hideBackButton">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="selectedTab">
        <v-tab to="#info" replace>Genel</v-tab>
        <!-- <v-tab to="#clusters" replace :disabled="!id">
          <v-badge
            color="deep-purple lighten-3"
            :content="totals.cluster_count || '0'"
          >
            Toplu Yaşam Alanları
          </v-badge>
        </v-tab> -->
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="info" class="pa-4">
          <v-form
            :readonly="disabled"
            @submit.prevent.stop="handleFormSubmit"
            ref="form"
            :disabled="isLoading || disabled"
          >
            <v-row dense>
              <v-col sm="6">
                <rs-text-field
                  :label="$t('labels.name')"
                  :rules="[rules.required, rules.maxLength(formData.name, 50)]"
                  v-model="formData.name"
                  hide-details="auto"
                />
              </v-col>

              <v-col sm="6">
                <rs-text-field
                  label="Fatura Kalemi Adı"
                  :rules="[
                    rules.required,
                    rules.maxLength(formData.invoice_description, 75),
                  ]"
                  v-model="formData.invoice_description"
                  hide-details="auto"
                />
              </v-col>

              <v-col sm="6">
                <rs-money-field
                  label="Aylık Tutar / BB"
                  v-model="formData.monthly_price_per_house"
                  :rules="[
                    rules.required,
                    rules.min(formData.monthly_price_per_house, 0.01),
                    rules.max(formData.monthly_price_per_house, 999999.99),
                  ]"
                  :disabled="isLoading"
                />
              </v-col>

              <v-col sm="6">
                <rs-money-field
                  label="Yıllık Tutar / BB"
                  v-model="formData.yearly_price_per_house"
                  :rules="[
                    rules.required,
                    rules.min(formData.yearly_price_per_house, 0.01),
                    rules.max(formData.yearly_price_per_house, 999999.99),
                  ]"
                  :disabled="isLoading"
                />
              </v-col>

              <v-col sm="4">
                <v-switch
                  v-model="formData.is_available"
                  label="Satılabilir"
                  hide-details="auto"
                  persistent-hint
                ></v-switch>
              </v-col>
            </v-row>

            <rs-form-buttons
              :isLoading="isLoading"
              :hide-submit="hideSave"
              @cancel="handleCancelClick"
              @submit="handleFormSubmit"
              v-if="!disabled"
            />
          </v-form>
        </v-tab-item>
        <!-- <v-tab-item value="clusters" class="pa-4">
          <CustomerClusterList :customer-id="id" />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { canBeSideViewed, hasForm } from "@/view/mixins";

export default {
  mixins: [canBeSideViewed, hasForm],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  data() {
    return {
      selectedTab: "info",
      formData: {
        name: null,
        invoice_description: null,
        monthly_price_per_house: null,
        yearly_price_per_house: null,
        is_available: true,
      },
      totals: {},
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/products/${this.id}`)
        .then((response) => {
          this.loadData(response);

          this.totals = response.data.data.totals || {};
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (formData.phone) {
        formData.phone = this.formData.phone.replace(/[^0-9+]/g, "");
      }

      if (this.id) {
        this.$api
          .put(`api-super-admin/products/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);

            if (this.sideView) {
              return;
            }

            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.loadData(response);
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`api-super-admin/products`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved", response.data.data);

            if (this.sideView) {
              return;
            }

            if (event && event.closeOnSave) {
              this.$router.back();
              return;
            }

            this.$router.replace({
              name: "admin.products.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      if (!this.sideView) {
        this.$router.back();
      } else {
        this.$emit("cancelled");
      }
    },
    showActionLogs() {
      this.$refs.actionLogList.show("App\\Models\\Resident", this.id);
    },
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => {
        this.load();
      });
    }
  },
};
</script>
