<template>
  <span v-if="number !== null" class="text-nowrap" :class="cssClass">
    {{ stringValue }}
  </span>
</template>

<script>
import { getBalanceClass } from "@/core/helpers";

export default {
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    colored: {
      type: Boolean,
      required: false,
      default: false,
    },
    reverse: {
      type: Boolean,
      required: false,
      default: false,
    },
    normalized: {
      type: Boolean,
      required: false,
      default: false,
    },
    mutedZeros: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    cssClass() {
      if (this.mutedZeros && (this.number === 0 || this.number === 0.0)) {
        return "text-muted";
      }

      if (this.colored) {
        if (this.reverse) {
          return getBalanceClass(this.value, this.normalized);
        } else {
          return getBalanceClass(this.value * -1, this.normalized);
        }
      }

      return null;
    },
    number() {
      if (this.value === undefined || this.value === null) {
        return null;
      }

      if (this.value.constructor.name !== "Number") {
        return new Number(this.value);
      }

      return this.value;
    },
    stringValue() {
      let str =
        Math.abs(this.number).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + "₺";

      if (this.number > 0) {
        if (this.reverse) {
          str += "(A)";
        } else {
          str += "(B)";
        }
      } else if (this.number < 0) {
        if (this.reverse) {
          str += "(B)";
        } else {
          str += "(A)";
        }
      }

      return str;
    },
  },
};
</script>
