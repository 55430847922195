export default [
  {
    path: "/activities/houses",
    name: "activities.houses.list",
    component: () => import("@/view/pages/activities/houses/HouseList.vue"),
  },
  {
    path: "/activities/residents",
    name: "activities.residents.list",
    component: () =>
      import("@/view/pages/activities/residents/ResidentList.vue"),
  },
  {
    path: "/activities/providers",
    name: "activities.providers.list",
    component: () =>
      import("@/view/pages/activities/providers/ProviderList.vue"),
  },
  {
    path: "/activities/safes",
    name: "activities.safes.list",
    component: () => import("@/view/pages/activities/safes/SafeList.vue"),
  },
  {
    path: "/activities/banks",
    name: "activities.banks.list",
    component: () => import("@/view/pages/activities/banks/BankList.vue"),
  },
  {
    path: "/activities/credit-card-payments",
    name: "activities.cc-payments.list",
    component: () =>
      import("@/view/pages/activities/cc-payments/CCPaymentList.vue"),
  },
  {
    path: "/activities/credit-card-payments/:id",
    name: "activities.cc-payments.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/activities/cc-payments/CCPaymentDetail.vue"),
  },
];
