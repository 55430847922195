<template>
  <rs-action
    @click.prevent="handleDeleteClick"
    :icon="!$slots.default"
    title="Sil"
    v-bind="$attrs"
    v-on="$on"
  >
    <slot>
      <v-icon>mdi-delete-outline</v-icon>
    </slot>

    <rs-confirm
      ref="confirmDelete"
      :loading="isLoading"
      @confirmed="handleDeleteConfirmed"
      @cancelled="$refs.confirmDelete.hide()"
    />
  </rs-action>
</template>

<script>
export default {
  name: "RsActionDelete",
  props: {
    confirmed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleDeleteClick(event) {
      if (this.confirmed) {
        this.$refs.confirmDelete.show("Silmek istediğinizden emin misiniz?");
      } else {
        this.$emit("click", event);
      }
    },
    handleDeleteConfirmed(event) {
      this.$refs.confirmDelete.hide();
      this.$emit("click", event);
    },
  },
};
</script>
