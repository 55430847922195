export default [
  {
    path: "/communication/notification-templates",
    name: "communication.notification-templates.list",
    component: () =>
      import(
        "@/view/pages/communication/notification-templates/TemplateList.vue"
      ),
  },
  {
    path: "/communication/notification-templates/create",
    name: "communication.notification-templates.create",
    component: () =>
      import(
        "@/view/pages/communication/notification-templates/TemplateForm.vue"
      ),
  },
  {
    path: "/communication/notification-templates/:id/edit",
    name: "communication.notification-templates.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import(
        "@/view/pages/communication/notification-templates/TemplateForm.vue"
      ),
  },
  {
    path: "/communication/notification-templates/:id/show",
    name: "communication.notification-templates.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import(
        "@/view/pages/communication/notification-templates/TemplateForm.vue"
      ),
  },
  {
    path: "/communication/system-notification-templates",
    name: "communication.system-notification-templates.list",
    component: () =>
      import(
        "@/view/pages/communication/system-notification-templates/TemplateList.vue"
      ),
  },
  {
    path: "/communication/system-notification-templates/:id/edit",
    name: "communication.system-notification-templates.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import(
        "@/view/pages/communication/system-notification-templates/TemplateForm.vue"
      ),
  },
  {
    path: "/communication/sended-notifications",
    name: "communication.sended-notifications.list",
    component: () =>
      import(
        "@/view/pages/communication/sended-notifications/SendedNotificationList.vue"
      ),
  },
  {
    path: "/communication/sended-notifications/:id/edit",
    name: "communication.sended-notifications.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import(
        "@/view/pages/communication/sended-notifications/SendedNotificationDetail.vue"
      ),
  },
  {
    path: "/communication/batch-notifications",
    name: "communication.batch-notifications.list",
    component: () =>
      import(
        "@/view/pages/communication/batch-notifications/BatchNotificationList.vue"
      ),
  },
  {
    path: "/communication/batch-notifications/create",
    name: "communication.batch-notifications.create",
    component: () =>
      import(
        "@/view/pages/communication/batch-notifications/BatchNotificationForm.vue"
      ),
  },
  {
    path: "/communication/batch-notifications/:id/edit",
    name: "communication.batch-notifications.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import(
        "@/view/pages/communication/batch-notifications/BatchNotificationForm.vue"
      ),
  },
  {
    path: "/communication/batch-notifications/:id/show",
    name: "communication.batch-notifications.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import(
        "@/view/pages/communication/batch-notifications/BatchNotificationForm.vue"
      ),
  },
  {
    path: "/communication/reminders",
    name: "communication.reminders.list",
    component: () =>
      import("@/view/pages/communication/reminders/ReminderList.vue"),
  },
  {
    path: "/communication/reminders/:id",
    name: "communication.reminders.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/communication/reminders/ReminderForm.vue"),
  },
  {
    path: "/communication/reminders/:id/edit",
    name: "communication.reminders.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/communication/reminders/ReminderForm.vue"),
  },
  {
    path: "/communication/breakdowns",
    name: "communication.breakdowns.list",
    component: () =>
      import("@/view/pages/communication/breakdowns/BreakdownList.vue"),
  },
  {
    path: "/communication/breakdowns/create",
    name: "communication.breakdowns.create",
    component: () =>
      import("@/view/pages/communication/breakdowns/BreakdownForm.vue"),
  },
  {
    path: "/communication/breakdowns/:id",
    name: "communication.breakdowns.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/communication/breakdowns/BreakdownForm.vue"),
  },
  {
    path: "/communication/breakdowns/:id/edit",
    name: "communication.breakdowns.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/communication/breakdowns/BreakdownForm.vue"),
  },
  {
    path: "/communication/suggestions",
    name: "communication.suggestions.list",
    component: () =>
      import("@/view/pages/communication/suggestions/SuggestionList.vue"),
  },
  {
    path: "/communication/suggestions/create",
    name: "communication.suggestions.create",
    component: () =>
      import("@/view/pages/communication/suggestions/SuggestionForm.vue"),
  },
  {
    path: "/communication/suggestions/:id",
    name: "communication.suggestions.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/communication/suggestions/SuggestionForm.vue"),
  },
  {
    path: "/communication/suggestions/:id/edit",
    name: "communication.suggestions.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/communication/suggestions/SuggestionForm.vue"),
  },
  {
    path: "/communication/complaints",
    name: "communication.complaints.list",
    component: () =>
      import("@/view/pages/communication/complaints/ComplaintList.vue"),
  },
  {
    path: "/communication/complaints/create",
    name: "communication.complaints.create",
    component: () =>
      import("@/view/pages/communication/complaints/ComplaintForm.vue"),
  },
  {
    path: "/communication/complaints/:id",
    name: "communication.complaints.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/communication/complaints/ComplaintForm.vue"),
  },
  {
    path: "/communication/complaints/:id/edit",
    name: "communication.complaints.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/communication/complaints/ComplaintForm.vue"),
  },
  {
    path: "/communication/maintenances",
    name: "communication.maintenances.list",
    component: () =>
      import("@/view/pages/communication/maintenances/MaintenanceList.vue"),
  },
  {
    path: "/communication/maintenances/create",
    name: "communication.maintenances.create",
    component: () =>
      import("@/view/pages/communication/maintenances/MaintenanceForm.vue"),
  },
  {
    path: "/communication/maintenances/:id",
    name: "communication.maintenances.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/communication/maintenances/MaintenanceForm.vue"),
  },
  {
    path: "/communication/maintenances/:id/edit",
    name: "communication.maintenances.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/communication/maintenances/MaintenanceForm.vue"),
  },
  {
    path: "/communication/user-notifications",
    name: "communication.user-notifications.list",
    component: () =>
      import(
        "@/view/pages/communication/user-notifications/UserNotificationList.vue"
      ),
  },
  {
    path: "/communication/user-notifications/:id/edit",
    name: "communication.user-notifications.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import(
        "@/view/pages/communication/user-notifications/UserNotificationDetail.vue"
      ),
  },
  {
    path: "/communication/visitor-logs",
    name: "communication.visitor-logs.list",
    component: () =>
      import("@/view/pages/communication/visitor-logs/VisitorLogList.vue"),
  },
  {
    path: "/communication/visitor-logs/create",
    name: "communication.visitor-logs.create",
    component: () =>
      import("@/view/pages/communication/visitor-logs/VisitorLogForm.vue"),
  },
  {
    path: "/communication/visitor-logs/:id",
    name: "communication.visitor-logs.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () =>
      import("@/view/pages/communication/visitor-logs/VisitorLogForm.vue"),
  },
  {
    path: "/communication/visitor-logs/:id/edit",
    name: "communication.visitor-logs.edit",
    props: (route) => ({ id: route.params.id }),
    component: () =>
      import("@/view/pages/communication/visitor-logs/VisitorLogForm.vue"),
  },

  {
    path: "/communication/house-users",
    name: "communication.house-users.list",
    component: () =>
      import("@/view/pages/communication/house-users/HouseUserList.vue"),
  },

  {
    path: "/communication/meeting-attendants",
    name: "communication.meeting-attendants.list",
    component: () =>
      import(
        "@/view/pages/communication/meeting-attendants/MeetingAttendantsList.vue"
      ),
  },
];
